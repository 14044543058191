<template lang="html">
  <div class="upload-first-step__result-modal__content">
    <h2 class="display-xl">Информация об ошибке</h2>
    <div class="element">
      <span class="element__type display-small">Тип ошибки:</span>
      <span>{{ log.type ? log.type : 'Нет данных' }}</span>
    </div>
    <div class="element">
      <span class="element__type display-small">Версия:</span>
      <span>{{ log.version ? log.version : 'Нет данных' }}</span>
    </div>
    <div class="element">
      <span class="element__type display-small">API:</span>
      <span>{{ log.api ? log.api : 'Нет данных' }}</span>
    </div>
    <div class="element">
      <span class="element__type display-small">Дата:</span>
      <span>{{ logDate ? logDate : 'Нет данных' }}</span>
    </div>
    <div class="element">
      <span class="element__type display-small">Запрос:</span>
      <span>{{ log.request ? log.request : 'Нет данных' }}</span>
    </div>
  </div>
  <div class="log__response">
    <span class="response__type display-small">Ответ:</span>
    <div class="response__content-container">
      <p class="content-container__text">{{ log.response ? log.response : 'Нет данных' }}</p>
    </div>
  </div>
  <div class="log__response">
    <span class="response__type display-small">Тело запроса:</span>
    <div class="response__content-container">
      <p class="content-container__text">{{ log.data ? log.data : 'Нет данных' }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, onMounted, computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import type EventBus from '@/models/EventBus';
export default defineComponent({
  name: 'AdminSystemLog',
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const bus: EventBus | undefined = inject('bus');
    const log: ComputedRef<any> = computed(() => store.state.admin.systemLog);
    const logDate: ComputedRef<Date> = computed(() => store.getters['admin/getLogDate']);
    // Слушатель события клика на кнопке закрытия правого сайдбара
    if (bus) bus.on('close-right-sidebar', () => router.push({ name: 'admin-system-logs' }));
    onMounted(async () => {
      await store.dispatch('admin/getSystemLog', route.params.id);
      //.then((logData) => (log.value = logData));
    });

    return { log, logDate };
  },
});
</script>

<style lang="scss" scoped>
.gtin-number__element {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e6eef8;

  .element__gtin {
    padding-bottom: var(--padding-x3);
  }
}

.element {
  padding: 16px 0;
  display: flex;
  border-bottom: 1px solid #e6eef8;

  .element__type {
    width: 190px;
    margin-right: var(--margin-x4);
  }
}
.gtin-size__element {
  padding: var(--padding-x3) 0;
  display: flex;
  border-bottom: 1px solid #e6eef8;

  .element__size {
    width: 190px;
    margin-right: var(--margin-x4);
  }
}
.log__response {
  display: flex;
  flex-direction: column;
  .response__content-container {
    padding: var(--padding-x4);
    background-color: #fbfdff;
    text-align: left;
    .content-container__text {
      margin: 0;
    }
  }
}
.response__type {
  padding: var(--padding-x3) 0;
}
</style>

<template>
  <div id="admin-user-form">
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <UserTabs />

        <Card :loading="loading['users']">
          <template #default>
            <div class="form-group row mb-2 align-items-center">
              <div class="col-sm-12">
                <div class="form-check">
                  <input id="user-pro" v-model="user.pro" type="checkbox" class="form-check-input" />
                  <label class="form-check-label" for="user-pro"> Статус PRO </label>
                </div>
              </div>
            </div>
          </template>

          <template #footer>
            <button class="btn btn-primary" @click="save">Сохранить</button>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import UserTabs from '../components/UserTabs.vue';
import Toast from '@/models/Toast';

export default defineComponent({
  name: 'AdminSettingsBulkSearch',
  components: {
    Card,
    UserTabs,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Список пользователей
     */
    const user: ComputedRef<any> = computed(() => store.state.admin.user);

    const save = () => {
      store.dispatch('admin/saveUser', user).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('Пользователь', 'Пользователь успешно сохранен.', 'success'));
        }
      });
    };

    onMounted(() => {
      store.dispatch('admin/getUser', route.params.userId).then(() => {
        store.commit('setH1', 'Пользователь ' + user.value.login);
      });
    });

    return {
      loading,
      user,
      save,
    };
  },
});
</script>

<style lang="scss"></style>

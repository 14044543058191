<template>
  <div id="api-page">
    <div class="row justify-content-center">
      <div class="col-xl-4">
        <Card :loading="loading.api">
          <template v-if="!loading.api" #default>
            <template v-if="canApprove"> Разрешить пользователю {{ user.login }} доступ к API? </template>

            <template v-if="!canApprove"> Неверная ссылка </template>
          </template>

          <template v-if="footer" #footer>
            <a href="#" class="btn btn-sm btn-success mr-2" @click.prevent="approve"> Подтвердить </a>

            <router-link class="btn btn-sm btn-danger" :to="{ name: 'admin-users' }"> Отмена </router-link>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import Toast from '@/models/Toast';
import User from '@/modules/auth/models/User';

export default defineComponent({
  name: 'ApiApprove',
  components: {
    Card,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /**
     * Возможность разрешения доступа
     */
    const canApprove: Ref<boolean> = ref(false);

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => {
      return typeof store.state.vbgApi.user.gs1ApiAccess !== 'undefined' ? store.state.vbgApi.user : new User();
    });

    const footer = computed<boolean>(() => {
      return (
        (user.value.gs1ApiAccess(route.params.api as string) == 2 ||
          user.value.gs1ApiAccess(route.params.api as string) == 5 ||
          user.value.gs1ApiAccess(route.params.api as string) == 4) &&
        canApprove.value
      );
    });

    /**
     * Подтверждение разрешения
     */
    const approve = () => {
      store.dispatch('vbgApi/approve', route.params).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('Доступ к API', 'Доступ был успешно разрешён.', 'success'));
          router.push({ name: 'admin-users' });
        }
      });
    };

    onMounted(() => {
      store
        .dispatch('vbgApi/checkLinkHash', {
          params: route.params,
          hash: route.query.hash,
        })
        .then((success: boolean) => {
          canApprove.value = success;

          store.dispatch('vbgApi/getUser', route.params.id);
        });
    });

    return {
      canApprove,
      loading,
      user,
      route,
      footer,
      approve,
    };
  },
});
</script>

<template>
  <ul class="admin-user-tabs nav nav-tabs">
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{
          name: 'admin-user-edit',
          params: { userId: $route.params.userId },
        }"
        class="nav-link"
      >
        Свойства
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{
          name: 'admin-user-settings',
          params: { userId: $route.params.userId },
        }"
        class="nav-link"
      >
        Настройки
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{
          name: 'admin-user-api',
          params: { userId: $route.params.userId },
        }"
        class="nav-link"
      >
        Доступ к API
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserTabs',
});
</script>

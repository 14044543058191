<template>
  <div class="admin-api">
    <div class="admin-api__container input-container">
      <GS1Btn :active="toggleMenu.api" transparent @click="onToggleMenu('api')"> Описание API </GS1Btn>
      <GS1Btn :active="toggleMenu.requests" transparent @click="onToggleMenu('requests')"> Запросы </GS1Btn>
    </div>
    <div v-if="toggleMenu.api" class="api-description">
      <h3 class="admin-api__big-margin-bottom">Описание API</h3>
      <div class="input-container">
        <GS1Input v-model="api.name" no-margin v-bind="{ big: true, label: 'Название', id: 'name', name: 'name' }" />
        <GS1Input v-model="api.description" no-margin v-bind="{ big: true, label: 'Описание', id: 'description', name: 'description' }" />
      </div>
      <GS1Btn class="api-description__btn" no-margin primary @click="save()">Сохранить</GS1Btn>
    </div>
    <div v-if="toggleMenu.requests && !showRequest" class="api-request__container">
      <GS1TableFlat
        v-bind="{
          fields: tableFields,
          data: apiRequests,
          linkable: false,
          showRange: false,
          showHeader: false,
          showFooter: false,
          infinityScroll: false,
        }"
      >
        <template #request="{ item }">
          <div class="admin-api__edit-btn__container">
            <GS1BtnIcon :icon="['pen']" no-margin class="admin-api-request__edit-btn" @click="openRequest(item.api, item.id)" />
            <span class="admin-api-request__api-name">{{ item.request }}</span>
          </div>
        </template>
        <template #request_api="{ item }">
          <span class="admin-api__api-name">{{ item.request_api.version }}</span>
        </template>
      </GS1TableFlat>
    </div>
    <AdminApiRequest v-if="showRequest"></AdminApiRequest>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, inject } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import type { GS1TableField } from '@gs1ru/web-components';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Toast from '@/models/Toast';
import AdminApiRequest from './AdminApiRequest.vue';
import type EventBus from '@/models/EventBus';

export default defineComponent({
  name: 'AdminApi',
  components: {
    AdminApiRequest,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);
    /**
     * Объект пользователя
     */
    const api: Ref<any> = ref({});

    const toggleMenu: Ref<Record<string, boolean>> = ref({
      api: true,
      requests: false,
    });

    const apiRequests: Ref<any> = ref([]);

    const tableFields: Array<GS1TableField> = [
      {
        label: 'Запрос',
        field: 'request',
        sort: false,
        width: '500px',
        lineClamp: 1,
      },
      {
        label: 'Метод',
        field: 'method',
        sort: false,
        width: '500px',
        lineClamp: 1,
      },
      {
        label: 'Версия',
        field: 'api_version',
        sort: false,
        width: '300px',
        lineClamp: 1,
      },
    ];

    const bus: EventBus | undefined = inject('bus');

    const newApi = computed(() => {
      return store.state.vbgApi.apiList.find((api: any) => {
        return api.id === Number(route.params.api);
      });
    });
    const showRequest: Ref<boolean> = ref(false);

    const openRequest = async (api: string, id: number) => {
      await router.push({ name: 'admin-api-request', params: { api: api, requestId: id } });
      showRequest.value = true;
    };

    const save = () => {
      store.dispatch('vbgApi/saveApi', api.value).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('API', 'Информация о API сохранена.', 'success'));
        }
      });
    };
    const onSave = () => {
      store.dispatch('vbgApi/saveApiRequest', api.value).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('API', 'Информация о API сохранена.', 'success'));
        }
      });
    };

    const onToggleMenu = (name: string) => {
      for (const i in toggleMenu.value) toggleMenu.value[i] = false;
      toggleMenu.value[name] = true;
      showRequest.value = false;
    };

    // Слушатель события клика на кнопке закрытия правого сайдбара
    if (bus) bus.on('close-right-sidebar', () => router.push({ name: 'admin-api-list' }));

    onMounted(() => {
      store.dispatch('vbgApi/getApi', route.params.api).then((r) => {
        api.value = r;
        store.commit('setH1', api.value.name);
      });
      store.dispatch('vbgApi/getApiRequestList', { api: route.params.api, all: true }).then((r) => (apiRequests.value = r));
    });

    return {
      loading,
      api,
      newApi,
      toggleMenu,
      tableFields,
      apiRequests,
      showRequest,
      save,
      onToggleMenu,
      onSave,
      openRequest,
    };
  },
});
</script>

<style lang="scss">
.admin-api {
  display: flex;
  flex-direction: column;
  height: 100%;
  .admin-api__container {
    display: flex;
  }
  .api-description {
    display: flex;
    flex-direction: column;
    height: 100%;
    .gs1-element.gs1-btn {
      margin: auto auto 0 0;
      width: 200px;
    }
  }
  .api-request__container {
    .admin-api__edit-btn__container {
      display: flex;
      align-items: center;
      margin-left: var(--margin-x1);
      .admin-api-request__edit-btn {
        border-radius: var(--border-radius-x2);
      }
      .admin-api-request__api-name {
        margin-left: var(--margin-x2);
      }
    }
  }
  .admin-api__big-margin-bottom {
    margin-bottom: var(--margin-x4);
  }
}
</style>

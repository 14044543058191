<template>
  <div id="api-page">
    <div class="row justify-content-center">
      <div class="col-xl-4">
        <Form
          v-slot="{ errors, validate }"
          as="div"
          :validation-schema="validationSchema"
        >
          <Card :loading="loading.api">
            <template v-if="!loading.api" #default>
              <template v-if="canApprove">
                Укажите причину отклонения
                <Field v-slot="{ field }" v-model="comment" name="comment">
                  <textarea
                    class="form-control mt-3"
                    :class="{ 'is-invalid': errors.comment }"
                    v-bind="field"
                  />
                </Field>

                <ErrorMessage name="comment" class="error invalid-feedback" />
              </template>

              <template v-if="!canApprove"> Неверная ссылка </template>
            </template>

            <template
              v-if="
                (user.gs1ApiAccess(route.params.api) == 2 ||
                  user.gs1ApiAccess(route.params.api) == 3) &&
                canApprove
              "
              #footer
            >
              <a
                v-if="
                  (user.gs1ApiAccess(route.params.api) == 2 ||
                    user.gs1ApiAccess(route.params.api) == 3) &&
                  canApprove
                "
                href="#"
                class="btn btn-sm btn-primary mr-2"
                @click.prevent="decline(validate)"
              >
                Отклонить
              </a>

              <router-link
                class="btn btn-sm btn-danger"
                :to="{ name: 'admin-users' }"
              >
                Отмена
              </router-link>
            </template>
          </Card>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import Toast from '@/models/Toast';
import User from '@/modules/auth/models/User';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: 'ApiDecline',
  components: {
    Card,
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /**
     * Возможность разрешения доступа
     */
    const canApprove: Ref<boolean> = ref(false);

    /**
     * Причина отклонения заявки
     */
    const comment: Ref<string> = ref('');

    /**
     * Правила валидации
     */
    const validationSchema: Ref<any> = ref({
      comment: yup.string().required('Необходимо указать причину'),
    });

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => {
      return typeof store.state.vbgApi.user.gs1ApiAccess !== 'undefined'
        ? store.state.vbgApi.user
        : new User();
    });

    /**
     * Подтверждение разрешения
     */
    const decline = (validate: Function) => {
      validate().then((valid: boolean) => {
        if (valid) {
          store
            .dispatch('vbgApi/decline', {
              id: route.params.id,
              api: route.params.api,
              comment: comment,
            })
            .then((success: boolean) => {
              if (success) {
                store.dispatch(
                  'showToast',
                  new Toast('Доступ к API', 'Доступ был отклонён.', 'success')
                );
                router.push({ name: 'admin-users' });
              }
            });
        }
      });
    };

    onMounted(() => {
      store
        .dispatch('vbgApi/checkLinkHash', {
          params: route.params,
          hash: route.query.hash,
        })
        .then((success: boolean) => {
          canApprove.value = success;

          store.dispatch('vbgApi/getUser', route.params.id);
        });
    });

    return {
      canApprove,
      validationSchema,
      comment,
      loading,
      user,
      decline,
    };
  },
});
</script>

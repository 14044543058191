import { Store } from 'vuex';
import store from './store';
import Search from './views/Search.vue';

export default class GtinSearchModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.store.registerModule('search', store);
  }

  addRoutes(): void {
    this.router.addRoute({ path: '/search', component: Search, name: 'search', meta: { checkAccess: ['search'] } });
  }
}
<template>
  <div class="page__settings__tab-content">
    <div class="settings__container">
      <div class="settings__container__side">
        <h2 class="display-medium header__margin-bottom">Лимиты API</h2>
        <div v-for="(apiLimit, index) in apiLimits" :key="apiLimit.id" class="input-container">
          <GS1Select v-model="apiLimit.interval_type" v-bind="{ label: 'Единица измерения' }">
            <GS1Option v-for="(intervalType, type) in intervalTypes" :key="type" :value="type">{{ intervalType }}</GS1Option>
          </GS1Select>
          <GS1Input v-model="apiLimit.interval" required small type="number" class="interval" v-bind="{ label: 'Интервал' }" />
          <GS1Input v-model="apiLimit.limit" required small type="number" class="limit" v-bind="{ label: 'Лимит' }" />
          <GS1BtnIcon no-margin small :icon="['trash']" @click="deleteLimit(index)"></GS1BtnIcon>
        </div>
        <GS1Btn class="add" no-margin primary @click="add">Добавить</GS1Btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import Toast from '@/models/Toast';

export default defineComponent({
  name: 'ApiLimits',
  setup() {
    const store = useStore();
    const route = useRoute();

    const intervalTypes = {
      1: 'секунда',
      2: 'минута',
      3: 'час',
      4: 'день',
    };

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Настройки
     */
    const apiLimits: Ref = ref([]);

    const save = () => {
      const data: Record<string, string | string[] | null> = {
        limits: apiLimits.value.filter((apiLimit: Record<string, any>) => {
          return apiLimit.interval_type && apiLimit.interval && apiLimit.limit;
        }),
        user_id: null,
        company_id: null,
      };
      if (typeof route.params.userId !== 'undefined') {
        data['user_id'] = route.params.userId;
      }
      if (typeof route.params.companyId !== 'undefined') {
        data['company_id'] = route.params.companyId;
      }
      store.dispatch('admin/saveApiLimits', data).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('Настройки', 'Настройки ограничений успешно сохранены.', 'success'));
        }
      });
    };
    const add = () => {
      apiLimits.value.push({
        interval: null,
        interval_type: null,
        limit: null,
      });
    };
    const deleteLimit = (index: number) => {
      apiLimits.value.splice(index, 1);
    };

    onMounted(() => {
      store.dispatch('admin/getApiLimits', route.params).then((response) => {
        apiLimits.value = response;
      });
    });

    return {
      loading,
      apiLimits,
      save,
      add,
      intervalTypes,
      deleteLimit,
    };
  },
});
</script>
<style lang="scss" scoped>
.page__settings__tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .page__settings__tab-content__footer {
    margin: auto auto 0 0;
  }
  .settings__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .gs1-element.gs1-input {
      margin: 0 var(--gs1-margin-x25) var(--gs1-margin-x4) var(--gs1-margin-x25);
    }
    .settings__container__side {
      display: flex;
      width: calc(50% - var(--margin-x25));
      flex-direction: column;
      .input-container:first-child {
        margin-top: var(--margin-x2);
      }
      .input-container {
        display: flex;
        .gs1-select {
          width: 50%;
        }
        .interval,
        .limit {
          width: 25%;
        }
      }
      .add {
        margin-bottom: var(--margin-x4);
      }
    }
  }
  .header__margin-bottom {
    margin-bottom: var(--margin-x4);
  }
}
</style>

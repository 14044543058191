<template>
  <div id="admin-companies" class="admin-companies__container">
    <h1 class="display-medium">Предприятия</h1>
    <GS1TableFlat
      v-model:search="searchString"
      v-bind="{
        fields: tableFields,
        data: companies.data,
        linkable: true,
        showRange: false,
        showHeader: true,
        showFooter: false,
        infinityScroll: true,
        displayedCount: companies.to,
        totalCount: companies.total,
        loading: !!loading['system-log'],
      }"
      @search-reset="onSearchReset"
      @search="onFilter"
      @next-page="onNextPage"
      @record-click="openCompany"
    >
      <template #pro="{ item }">
        <GS1Icon v-if="item.pro" class="description__active-icon" title="Доступ разрешён" color="#7AC143" icon="success"></GS1Icon>
        <GS1Icon v-if="!item.pro" class="description__active-icon" title="Доступ запрещен" color="#ff3607" icon="close"></GS1Icon>
      </template>
      <template #actions="{ item }">
        <GS1BtnIcon v-gs1-tooltip.left="'Обновить данные предприятия'" :icon="['reset']" min @click.stop="refreshCompanyInfo(item.id)"></GS1BtnIcon>
      </template>
    </GS1TableFlat>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import type { GS1TableField } from '@gs1ru/web-components';
import router from '@/router';

export default defineComponent({
  name: 'AdminCompanies',
  setup() {
    const store = useStore();

    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);

    /**
     * Кол-во элементов на страницу
     */
    const perPage: Ref<number> = ref(10);

    /**
     * Поисковой запрос
     */
    const searchString: Ref<string> = ref('');

    const tableFields: Array<GS1TableField> = [
      { label: 'GLN', field: 'gln', sort: false, class: [] },
      { label: 'Предприятие', field: 'name', sort: false, class: [] },
      { label: 'ИНН', field: 'inn', sort: false, class: [] },
      {
        label: 'PRO',
        field: 'pro',
        sort: false,
        width: '200px',
        lineClamp: 1,
      },
      {
        label: '',
        field: 'actions',
        sort: false,
        width: '80px',
        lineClamp: 1,
        drag: false,
        fixed: true,
      },
    ];

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Список предприятий
     */
    const companies: ComputedRef<any> = computed(() => {
      const companies = store.state.admin.companies;

      return companies;
    });

    const openCompany = (e: Event, company: any) => {
      if (!company?.id) return;
      router.push({ name: 'admin-company-edit', params: { companyId: company.id } });
    };

    /**
     * Получение списка предприятий
     */
    const getCompanies = (reset = false): void => {
      store.dispatch('admin/getCompanies', {
        page: page.value,
        perPage: perPage.value,
        success: 0,
        search: searchString.value,
        reset: reset,
      });
    };

    const onNextPage = () => {
      if (loading.value['companies'] || ++companies.value.current_page > companies.value.last_page) return;
      page.value++;
      getCompanies();
    };

    const onSearchReset = () => {
      searchString.value = '';
      getCompanies(true);
    };

    const onFilter = () => {
      page.value = 1;
      companies.value.data = [];
      getCompanies(true);
    };

    /**
     * Обновление информации о предприятии
     * @param companyId ID предприятия
     */
    const refreshCompanyInfo = (companyId: number) => {
      store.dispatch('admin/refreshCompanyInfo', companyId);
    };

    onMounted(() => {
      getCompanies(true);

      store.commit('setH1', 'Предприятия');
      store.commit('setNoPadding', true);
    });

    return {
      page,
      perPage,
      searchString,
      companies,
      loading,
      tableFields,
      onFilter,
      onSearchReset,
      getCompanies,
      openCompany,
      onNextPage,
      refreshCompanyInfo,
    };
  },
});
</script>

<style lang="scss">
.admin-companies__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .gs1-element.gs1-table-flat {
    .gs1-table-flat__table-tr {
      cursor: pointer;
    }
  }
}
</style>

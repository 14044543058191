import { Store } from 'vuex';
import store from './store';
import pusher from './pusher';
import CompanySearch from './views/CompanySearch.vue';
import CompanySearchByName from './views/CompanySearchByName.vue';
import CompanyResult from './views/CompanyResult.vue';
import CompanyResultHeader from './views/CompanyResultHeader.vue';
import MenuLink from '@/models/MenuLink';

export default class GtinSearchModule {
  router: Record<string, any>;
  store: Store<any>;
  name: string;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
    this.name = 'companySearch';
  }

  init() {
    this.addRoutes();
    this.addLinks();
    this.store.registerModule(this.name, store);
    pusher.subscribe();
  }

  addRoutes(): void {
    this.router.addRoute({
      path: '/company-search/search/by-gln',
      component: CompanySearch,
      name: 'company-search-by-gln',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-gln'] },
    });
    this.router.addRoute({
      path: '/company-search/search/by-gln/:keyValue',
      components: { default: CompanySearch, rightPanel: CompanyResult, rightPanelHeader: CompanyResultHeader },
      name: 'company-search-by-gln-result',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-gln'] },
    });
    this.router.addRoute({
      path: '/company-search/search/by-inn',
      component: CompanySearch,
      name: 'company-search-by-inn',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-inn'] },
    });
    this.router.addRoute({
      path: '/company-search/search/by-inn/:index/:keyValue',
      components: { default: CompanySearch, rightPanel: CompanyResult, rightPanelHeader: CompanyResultHeader },
      name: 'company-search-by-inn-result',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-inn'] },
    });
    this.router.addRoute({
      path: '/company-search/search/by-key',
      component: CompanySearch,
      name: 'company-search-by-key',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-key'] },
    });
    this.router.addRoute({
      path: '/company-search/search/by-key/:key/:keyValue',
      components: { default: CompanySearch, rightPanel: CompanyResult, rightPanelHeader: CompanyResultHeader },
      name: 'company-search-by-key-result',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-key'] },
    });
    this.router.addRoute({
      path: '/company-search/search/by-name',
      component: CompanySearchByName,
      name: 'company-search-by-name',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-name'] },
    });
    this.router.addRoute({
      path: '/company-search/search/by-name/:keyValue',
      components: { default: CompanySearchByName, rightPanel: CompanyResult },
      name: 'company-search-by-name-result',
      meta: { checkAccess: ['search-companies'], activeMenuRoute: ['company-search-by-name'] },
    });
  }

  addLinks(): void {
    this.store.dispatch(
      'addLink',
      new MenuLink('Поиск данных по GLN', { default: 'company-search-gln', light: 'company-search-gln', dark: 'company-search-gln' }, 2, {
        name: 'company-search-by-gln',
      })
    );
    this.store.dispatch(
      'addLink',
      new MenuLink('Поиск данных по другим ключам', { default: 'company-search-key', light: 'company-search-key', dark: 'company-search-key' }, 3, {
        name: 'company-search-by-key',
      })
    );
    this.store.dispatch(
      'addLink',
      new MenuLink(
        'Поиск организаций по названию',
        { default: 'company-search-name', light: 'company-search-name', dark: 'company-search-name' },
        4,
        {
          name: 'company-search-by-name',
        }
      )
    );
    this.store.dispatch(
      'addLink',
      new MenuLink('Поиск данных по ИНН', { default: 'company-search-inn', light: 'company-search-inn', dark: 'company-search-inn' }, 5, {
        name: 'company-search-by-inn',
      })
    );
  }
}

<template>
  <div class="notification-popup" :class="typeClass">
    <a class="close" href="#" @click.prevent="close">
      <i class="fa fa-times" />
    </a>

    <router-link
      v-if="
        typeof notification.url !== 'undefined' && notification.url.length > 0
      "
      class="open"
      :to="notification.url"
    >
      <div
        v-if="
          typeof notification.title !== 'undefined' &&
          notification.title.length > 0
        "
        class="title"
      >
        {{ notification.title }}
      </div>

      <div class="body">
        {{ notification.message }}
      </div>

      <span>
        <i class="fas fa-arrow-right" />
      </span>
    </router-link>

    <template v-if="notification.url.length == 0">
      <div
        v-if="
          typeof notification.title !== 'undefined' &&
          notification.title.length > 0
        "
        class="title"
      >
        {{ notification.title }}
      </div>

      <div class="body">
        {{ notification.message }}
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef } from 'vue';

export default defineComponent({
  name: 'NotificationPopup',
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const typeClass: ComputedRef<string> = computed(() => {
      return typeof props.notification.type == 'string' &&
        props.notification.type.length > 0
        ? 'type-' + props.notification.type
        : '';
    });

    /**
     * Клик по кнопке "Закрыть"
     */
    const close = () => {
      emit('close', props.notification.id);
    };

    return {
      typeClass,
      close,
    };
  },
});
</script>

<style lang="scss">
.notification-popup {
  width: 360px;
  background: #ececec;
  border-radius: 4px;
  padding: 28px 32px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  position: relative;
  &.type-success {
    background: #e1ffea;
  }
  &.type-warning {
    background: #fdffe1;
  }
  &.type-danger {
    background: #ffeded;
  }
  + .notification-popup {
    margin-top: 10px;
  }
  .open {
    display: block;
    height: 100%;
    &:hover {
      text-decoration: none;
    }
  }
  .title {
    color: #002c6c;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .body {
    font-size: 11px;
    color: #888b8d;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .close,
  .open span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid;
    color: #888b8d;
    font-size: 8px;
  }
  .close {
    top: 8px;
    &:hover {
      color: #f23434;
    }
  }
  .open {
    span {
      bottom: 8px;
    }
    &:hover span {
      color: #002c6c;
    }
  }
}
</style>

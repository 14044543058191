<template>
  <div id="gtin-logs-users">
    <Card :loading="loading['gtin-logs']" no-padding no-shadow no-border>
      <template #default>
        <Filter
          v-model:search-string="searchString"
          v-model:per-page="perPage"
          v-model:page="page"
          v-model:date-from="dateFrom"
          v-model:date-to="dateTo"
          :total="pagination.total"
          show-date-filter
          @search="getGtinLogUsers"
          @change-per-page="getGtinLogUsers"
          @change-page="getGtinLogUsers"
          @change-date="getGtinLogUsers"
        />

        <table class="table table-striped">
          <thead>
            <tr>
              <td>Пользователь</td>
              <td>ИНН</td>
              <td>GLN</td>
              <td>Кол-во запросов</td>
            </tr>
          </thead>

          <tbody>
            <tr v-for="user in users.data" :key="user.user_id">
              <td v-expand>
                {{ user.user ? user.user.login : '' }}
              </td>
              <td>
                {{ user.user && user.user.company ? user.user.company.inn : '' }}
              </td>
              <td>
                {{ user.user && user.user.company ? user.user.company.gln : '' }}
              </td>
              <td>
                {{ user.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import Filter from '@/components/Filter.vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'GtinLogUsers',
  components: {
    Card,
    Filter,
  },
  setup() {
    const store = useStore();

    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);

    /**
     * Кол-во элементов на страницу
     */
    const perPage: Ref<number> = ref(10);

    /**
     * Поисковой запрос
     */
    const searchString: Ref<string> = ref('');

    /**
     * Дата (от) для фильтра
     */
    const dateFrom: Ref<Date> = ref(dayjs().subtract(1, 'month').toDate());

    /**
     * Дата (до) для фильтра
     */
    const dateTo: Ref<Date> = ref(new Date());

    /**
     * Список пользователей
     */
    const loading: ComputedRef<Record<string, boolean>> = computed(() => store.state.loading);

    /**
     * Список логов
     */
    const users: ComputedRef<any> = computed(() => store.state.statistics.gtinLogUsers);

    /**
     * Данные о постраничной навигации логов
     */
    const pagination: ComputedRef<any> = computed(() => store.state.statistics.gtinLogUserPagination);

    const getGtinLogUsers = () => {
      if (!loading.value['gtin-logs']) {
        store.dispatch('statistics/getGtinLogUsers', {
          page: page.value,
          perPage: perPage.value,
          search: searchString.value,
          dateFrom: dateFrom.value ? dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00') : dateFrom.value,
          dateTo: dateTo.value ? dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59') : dateTo.value,
        });
        store.dispatch('statistics/getGtinLogUserPagination', {
          perPage: perPage.value,
          search: searchString.value,
          dateFrom: dateFrom.value ? dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00') : dateFrom.value,
          dateTo: dateTo.value ? dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59') : dateTo.value,
        });
      }
    };

    onMounted(() => {
      getGtinLogUsers();
      store.commit('setH1', 'Журнал запросов GTIN | Активные пользователи');
      store.commit('setNoPadding', true);
    });

    return {
      page,
      perPage,
      searchString,
      dateFrom,
      dateTo,
      loading,
      users,
      getGtinLogUsers,
      pagination,
    };
  },
});
</script>

<template>
  <div class="page__admin-company">
    <h2 class="display-xl">Предприятие {{ company.name }}</h2>
    <div class="page__admin-company__header">
      <GS1Tabs>
        <GS1TabsItem :active="toggleMenu.properties" @click="onToggleMenu('properties')">Свойства</GS1TabsItem>
        <GS1TabsItem :active="toggleMenu.settings" @click="onToggleMenu('settings')">Настройки</GS1TabsItem>
        <GS1TabsItem :active="toggleMenu.users" @click="onToggleMenu('users'), getCompanyUserSettings()">Пользователи</GS1TabsItem>
      </GS1Tabs>
    </div>

    <div v-if="toggleMenu.properties" class="page__admin-company__tab-content">
      <GS1InputCheck v-model="company.pro">Статус PRO</GS1InputCheck>
      <div class="page__admin-company__tab-content__footer">
        <GS1Btn style="width: 200px" no-margin primary @click="save">Сохранить</GS1Btn>
      </div>
    </div>

    <CommonSettings v-if="toggleMenu.settings" />

    <div v-if="toggleMenu.users" class="page__admin-user__tab-content">
      <GS1TableFlat
        v-bind="{
          fields: tableFields,
          data: companyUsers.data,
          linkable: false,
          showRange: false,
          showHeader: false,
          showFooter: false,
          infinityScroll: false,
        }"
      >
        <template #pro="{ item }">
          <GS1Icon v-if="item.pro" class="description__active-icon" title="Доступ разрешён" color="#7AC143" icon="success"></GS1Icon>
          <GS1Icon v-if="!item.pro" class="description__active-icon" title="Доступ запрещен" color="#ff3607" icon="close"></GS1Icon>
        </template>
      </GS1TableFlat>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import type EventBus from '@/models/EventBus';
import Toast from '@/models/Toast';
import type { GS1TableField } from '@gs1ru/web-components';
import CommonSettings from '../components/CommonSettings.vue';

export default defineComponent({
  name: 'AdminCompanyForm',
  components: {
    CommonSettings,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    const bus: EventBus | undefined = inject('bus');

    /**
     * Объект предприятия
     */
    const company: ComputedRef<any> = computed(() => store.state.admin.company);

    /**
     * Настройки
     */
    const settings = computed(() => store.state.admin.settings);

    /**
     * Поля таблицы
     */
    const tableFields: Array<GS1TableField> = [
      {
        label: 'Логин',
        field: 'login',
        sort: false,
        width: '420px',
        lineClamp: 1,
      },
      {
        label: 'PRO',
        field: 'pro',
        sort: false,
        width: '420px',
        lineClamp: 1,
      },
    ];

    const toggleMenu: Ref<Record<string, boolean>> = ref({
      properties: true,
      settings: false,
      users: false,
    });

    /**
     * Список пользователей
     */
    const companyUsers: ComputedRef<any> = computed(() => store.state.admin.companyUsers);

    const save = () => {
      store.dispatch('admin/saveCompany', company.value).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('Предприятие', 'Предприятие успешно сохранено.', 'success'));
        }
      });
    };

    const saveSettings = () => {
      store
        .dispatch('admin/saveSettings', {
          settings: settings.value,
          params: route.params,
        })
        .then((success: boolean) => {
          if (success) {
            store.dispatch('showToast', new Toast('Настройки', 'Настройки успешно сохранены.', 'success'));
          }
        });
    };

    const getCompany = () => {
      store.dispatch('admin/getCompany', route.params.companyId).then(() => {
        if (company.value.name) store.commit('setH1', 'Предприятие ' + company.value.name);
      });
      store.dispatch('admin/getCompanyUsers', {
        companyId: route.params.companyId,
      });
    };

    const onToggleMenu = (name: string) => {
      for (const i in toggleMenu.value) toggleMenu.value[i] = false;
      toggleMenu.value[name] = true;
      if (toggleMenu.value.settings) store.dispatch('admin/getSettings', { companyId: route.params.companyId });
    };

    const getCompanyUserSettings = () => {
      store.dispatch('admin/getCompany', route.params.companyId).then(() => {
        if (company.value.name) store.commit('setH1', 'Предприятие ' + company.value.name);
      });
    };

    // Слушатель события клика на кнопке закрытия правого сайдбара
    if (bus) bus.on('close-right-sidebar', () => router.push({ name: 'admin-companies' }));

    onMounted(() => {
      store.commit('setH1', 'Предприятие');
      getCompany();
    });

    return {
      company,
      loading,
      toggleMenu,
      settings,
      tableFields,
      companyUsers,
      onToggleMenu,
      getCompany,
      getCompanyUserSettings,
      saveSettings,
      save,
    };
  },
});
</script>

<style lang="scss">
.page__admin-company {
  display: flex;
  flex-direction: column;
  height: 100%;
  .gs1-element.gs1-btn-icon-edit {
    margin: auto 0 auto auto;
  }
  .page__admin-company__header {
    margin-bottom: var(--margin-x4);
  }
  .page__admin-company__tab-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .page__admin-company__tab-content__footer {
      margin: auto auto 0 0;
    }
    .settings__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      .settings__container__side {
        display: flex;
        width: calc(50% - var(--margin-x25));
        flex-direction: column;
      }
    }
  }
}
</style>

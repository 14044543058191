<template>
  <div id="company-search-form-by-name" :class="classes">
    <div class="page-header">
      <h1 class="display-large display-bold font-sf-display">Поиск организаций по названию</h1>
      <GS1Select v-model="searchParams.country">
        <GS1Option v-for="(countryName, countryCode) in countries" :key="countryCode" :value="countryCode">{{ countryName }}</GS1Option>
      </GS1Select>
      <GS1Search v-model="searchParams.name" label="Введите название организации" :error="searchError" @reset="onReset" />
      <GS1DropDownMenu ref="extraFilter" :icon="['filter']">
        <template #label>
          Доп. параметры <span v-if="filterCount > 0">{{ filterCount }}</span>
        </template>
        <template #default>
          <GS1Input v-model="searchParams.postal" label="Индекс" label-fixed placeholder="Введите почтовый индекс"></GS1Input>
          <GS1Input v-model="searchParams.city" label="Город" label-fixed placeholder="Введите город"></GS1Input>
          <GS1Input v-model="searchParams.address" label="Адрес" label-fixed placeholder="Введите улицу"></GS1Input>
          <div class="buttons">
            <GS1Btn transparent @click="hideExtraFilter(false)">Отменить</GS1Btn>
            <GS1Btn primary @click="hideExtraFilter(true)">Применть</GS1Btn>
          </div>
        </template>
      </GS1DropDownMenu>
      <GS1Btn primary no-margin @click="search">Искать</GS1Btn>
    </div>
    <div v-if="filterCount > 0" class="company-search__filters">
      <div v-if="searchParams.postal.length > 0" class="company-search__filter">
        <div class="font-sf-display display-small art">
          Индекс: <strong class="font-sf-display display-small display-bold">{{ searchParams.postal }}</strong>
        </div>
        <GS1BtnIcon :icon="['close']" min no-margin @click="resetFilter('postal')"></GS1BtnIcon>
      </div>
      <div v-if="searchParams.city.length > 0" class="company-search__filter">
        <span class="font-sf-display display-small">
          Город: <strong class="font-sf-display display-small display-bold">{{ searchParams.city }}</strong>
        </span>
        <GS1BtnIcon :icon="['close']" min no-margin @click="resetFilter('city')"></GS1BtnIcon>
      </div>
      <div v-if="searchParams.address.length > 0" class="company-search__filter">
        <span class="font-sf-display display-small">
          Адрес:<strong class="font-sf-display display-small display-bold">{{ searchParams.address }}</strong>
        </span>
        <GS1BtnIcon :icon="['close']" min no-margin @click="resetFilter('address')"></GS1BtnIcon>
      </div>
    </div>
    <div v-if="allCompanies.length == 0 && !loader && currentSearchName.length == 0" class="no-content-template">
      <div v-if="showGsTemplate" class="no-content-template__modal gray">
        <GS1BtnIcon
          class="modal__close-button"
          color="#A7ACB1"
          transparent
          size="default"
          :icon="['close', 'default']"
          @click="onCloseGsTemplate"
        ></GS1BtnIcon>
        <div class="modal__icon-container">
          <GS1Icon small class="description__active-icon" icon="question-rounded"></GS1Icon>
          <h2 class="display-large gs-no-margin no-content-template__modal__header-text">Подсказка</h2>
        </div>

        <span class="display-secondary font-gray no-content-template__modal__main-text">
          Пример запроса: <a href="#" @click.prevent="setExample('Coca')">Coca</a>
        </span>
        <span class="active-icon__text font-gray display-secondary no-content-template__modal__footer-text">
          Для поиска используйте ключевые части названия, без символов.
        </span>
      </div>
      <div class="modal__text-container">
        <h2 class="main-text__no-content display-medium display-bold modal__text-container__header-text font-sf-display font-w-b">
          Еще нет результатов поиска
        </h2>
        <span class="font-gray display-small modal__text-container__main-text font-sf-display">
          Здесь будут результаты поиска по введенному значению
        </span>
      </div>
    </div>
    <GS1Loader v-if="loader" note-value="Загружаем результаты поиска по введенному значению"></GS1Loader>
    <div
      v-if="allCompanies.length == 0 && !loader && currentSearchName.length > 0"
      class="no-content-template__modal company-search__result-count red"
    >
      <span class="modal__icon-container display-large"> По запросу “{{ currentSearchName }}” ничего не найдено</span>
      <span v-if="filterCount <= 0" class="display-secondary no-content-template__modal__footer-text"> Попробуйте изменить название </span>
      <span v-else class="display-secondary no-content-template__modal__footer-text"> Попробуйте изменить название или другие параметры </span>
    </div>
    <div v-if="allCompanies.length > 0 && !loader && currentSearchName.length > 0" class="no-content-template__modal company-search__result-count">
      <span class="modal__icon-container display-large modal__icon-container-success">
        По запросу “{{ currentSearchName }}” найдено {{ searchResultCount }} {{ searchResultCountLabel }}
      </span>
      <span v-if="searchResultCount > 100" class="display-secondary no-content-template__modal__footer-text">
        В результате выведены первые 100 совпадений, для уточнения запроса измените название или внесите дополнительные параметры поиска
      </span>
    </div>
    <div v-if="allCompanies.length > 0 && !loader" class="info-search-form__table-container">
      <GS1TableFlat
        v-bind="{
          fields: tableFields,
          data: allCompanies,
          linkable: true,
          showRange: false,
          showHeader: false,
          showFooter: false,
          rowActiveField: 'index',
          rowActive: [currentItemIndex],
        }"
        @record-click="openCompany"
      >
        <template #prefix="item">{{ item.item?.licenceKey }}</template>
        <template #gln="item">{{ item.item?.licenseeGLN }}</template>
        <template #name="item">{{ item.item?.licenseeName }}</template>
        <template #city="item">{{ item.item?.address?.addressLocality?.value }}</template>
        <template #postal="item">{{ item.item?.address?.postalCode }}</template>
        <template #country="item">{{ countries[item.item?.address?.countryCode] }}</template>
      </GS1TableFlat>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted, type Ref, type ComputedRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import type { GS1TableField } from '@gs1ru/web-components';

export default defineComponent({
  name: 'CompanySearchByName',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    /**
     * Дополнительные параметры фильтра
     */
    const extraFilter: Ref<any> = ref(null);

    /**
     * Ошибка поиска
     */
    const searchError: Ref<string> = ref('');

    const currentItemIndex = computed(() => route.params.keyValue);

    /**
     * Параметры поиска
     */
    const searchParams: Ref<Record<string, string>> = ref({
      name: '',
      country: '',
      postal: '',
      city: '',
      address: '',
    });

    /**
     * Кол-во активных параметров фильтра
     */
    const filterCount: Ref<number> = ref(0);

    /**
     * Текущая поисковая строка
     */
    const currentSearchName: Ref<string> = ref('');

    /**
     * Список компаний для поиска
     */
    const countries: ComputedRef<Record<string, string>[]> = computed(() => {
      return Object.assign({ '': 'Все страны' }, store.state.companySearch.countries);
    });

    /**
     * Массив найденных компаний
     */
    const allCompanies: ComputedRef<Record<string, any>> = computed(() => {
      return store.state.companySearch.companies;
    });

    /**
     * Кол-во резултатов поиска
     */
    const searchResultCount: ComputedRef<number> = computed(() => {
      return store.state.companySearch.companyCount;
    });

    /**
     * Подпись для кол-ва результатов поиска
     */
    const searchResultCountLabel: ComputedRef<string> = computed(() => {
      if (searchResultCount.value >= 11 && searchResultCount.value <= 14) {
        return 'совпадений';
      }

      const lastDigit = searchResultCount.value % 10;

      switch (lastDigit) {
        case 1:
          return 'совпадение';
        case 2:
        case 3:
        case 4:
          return 'совпадения';
        default:
          return 'совпадений';
      }
    });

    /**
     * Состояние индикатора загрузки
     */
    const loader: Ref<boolean> = ref(false);

    /**
     * Показать шаблон GS1 для правого меню
     */
    const showGsTemplate: Ref<boolean> = ref(true);

    /**
     * Колонки табоицы резальтатов
     */
    const tableFields: ComputedRef<Array<GS1TableField>> = computed(() => [
      {
        label: 'Префикс',
        field: 'prefix',
        sort: false,
        width: '120px',
        lineClamp: 1,
      },
      {
        label: 'GLN',
        field: 'gln',
        sort: false,
        width: '130px',
        lineClamp: 1,
        fixed: true,
      },
      {
        label: 'Название',
        field: 'name',
        sort: false,
        lineClamp: 1,
        fixed: true,
      },
      {
        label: 'Город',
        field: 'city',
        sort: false,
        lineClamp: 1,
        fixed: true,
      },
      {
        label: 'Страна',
        field: 'country',
        sort: false,
        lineClamp: 1,
        fixed: true,
      },
      {
        label: 'Индекс',
        field: 'postal',
        sort: false,
        lineClamp: 1,
        width: '100px',
        fixed: true,
      },
    ]);

    /**
     * Скрытие плашки GS1
     */
    const onCloseGsTemplate = (): void => {
      showGsTemplate.value = false;
    };

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        ['mode-' + ViewMode.value]: true,
      };
    });

    /**
     * Пример запроса
     *
     * @param example Пример запроса
     */
    const setExample = (example: string): void => {
      searchParams.value.name = example;
    };

    const onReset = () => {
      store.commit('companySearch/setCompanies', []);
    };

    /**
     * Поиск компаний
     */
    const search = (): void => {
      searchError.value = '';

      if (searchParams.value.name.length == 0) {
        searchError.value = 'Укажите название';
        return;
      }
      currentSearchName.value = searchParams.value.name;

      router.push({ name: 'company-search-by-name' });

      loader.value = true;

      store.dispatch('companySearch/searchCompaniesByName', searchParams.value).then(() => {
        loader.value = false;
      });
    };

    /**
     * Отображение подробного представления
     *
     * @param e
     * @param company
     * @param index
     */
    const openCompany = (e: Event, company: Record<string, any>, index: number): void => {
      router.push({
        name: 'company-search-by-name-result',
        params: { keyValue: index },
      });
    };

    /**
     * Скрытие фильтра по доп. параметрам
     *
     * @param saveFilter Сбросить все значения
     */
    const hideExtraFilter = (saveFilter: boolean) => {
      if (!saveFilter) {
        filterCount.value = 0;
        searchParams.value.postal = '';
        searchParams.value.city = '';
        searchParams.value.address = '';
      } else {
        filterCount.value = 0;
        ['postal', 'city', 'address'].forEach((code: string) => {
          if (searchParams.value[code].length > 0) {
            filterCount.value++;
          }
        });
      }

      if (extraFilter.value) {
        extraFilter.value.active = false;
      }
    };

    /**
     * Сброс значения поля фильтра
     *
     * @param code Код поля
     */
    const resetFilter = (code: string) => {
      searchParams.value[code] = '';
      hideExtraFilter(true);
    };

    onMounted(() => {
      store.dispatch('companySearch/getCountries');
    });

    onUnmounted(() => {
      store.commit('companySearch/setCompanies', []);
    });

    return {
      classes,
      searchError,
      searchParams,
      currentSearchName,
      allCompanies,
      loader,
      showGsTemplate,
      currentItemIndex,
      onCloseGsTemplate,
      countries,
      search,
      setExample,
      tableFields,
      openCompany,
      searchResultCount,
      onReset,
      extraFilter,
      hideExtraFilter,
      filterCount,
      resetFilter,
      searchResultCountLabel,
    };
  },
});
</script>

<style lang="scss">
#company-search-form-by-name {
  display: flex;
  flex-direction: column;
  height: 100%;

  .gs1-table-flat {
    margin-top: var(--margin-x3);
    .gs1-table-flat__body__container {
      overflow: auto;
    }
    .gs1-table-flat__body.no-header.no-footer {
      max-height: 100%;
    }
    .gs1-table-flat__body:before {
      right: 0;
    }
  }
  .info-search-form__table-container {
    height: 1%;
    flex-grow: 1;
    margin-bottom: var(--margin-x25);
  }
  .page-header {
    display: flex;
    align-items: center;
    margin-bottom: var(--margin-x3);
    h1 {
      font-size: 14px;
      font-weight: 500;
      flex: 0 0 auto;
    }
    .gs1-element,
    h1 {
      margin-bottom: 0;
    }
    .gs1-select {
      width: 200px;
      flex: 0 0 200px;
      margin: 0 0 0 var(--margin-x3);
    }
    .gs1-search {
      margin-left: var(--margin-x3);
      margin-right: var(--margin-x3);
    }
    .gs1-search,
    .gs1-search__input {
      width: 240px;
      flex: 0 0 240px;
    }
    .gs1-element.gs1-search.has-error .gs1-search__input {
      border-color: var(--color-main-5);
    }
    .gs1-search__error {
      display: none;
    }
    > .gs1-btn {
      margin-left: var(--margin-x3);
    }
  }
  .company-search__result-count {
    height: auto;
    position: relative;
    .no-content-template__modal__footer-text {
      margin-top: var(--margin-x2);
    }
  }
  .gs1-dropdown-menu {
    .gs1-btn-icon-edit__value span {
      color: var(--color-main-8);
    }
    .gs1-modal__window {
      padding: var(--margin-x3) var(--margin-x2);
      .gs1-input {
        width: 290px;
        + .gs1-input {
          margin-top: var(--margin-x3);
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .company-search__filters {
    display: flex;
    margin-bottom: var(--margin-x3);
    .company-search__filter {
      display: flex;
      align-items: center;
      font-size: 13px;
      border: 1px solid var(--color-main-9);
      padding: var(--margin-x2);
      height: 32px;
      border-radius: var(--border-radius-x2);

      strong {
        font-weight: 500;
        margin-left: var(--margin-x2);
      }
      .gs1-btn-icon {
        margin-left: var(--margin-x2);
      }
      .gs1-icon,
      .gs1-btn-icon {
        width: 16px;
        height: 16px;
      }
      &:not(:first-child) {
        margin-left: var(--margin-x3);
      }
    }
  }
  &.mode-dark {
    .company-search__filter {
      color: var(--color-main-f);
      background-color: var(--color-main-27);
      border: 1px solid var(--color-main-22);
    }
    .gs1-element.gs1-search {
      .gs1-search__input.has-value {
        box-shadow: var(--gs1-shadow-input-gray);
      }
    }
  }
}
</style>

import axios from 'axios';
import User from '@/modules/auth/models/User';
import dayjs from 'dayjs';
import type { Module } from 'vuex';

const admin: Module<any, Record<string, unknown>> = {
  namespaced: true,
  state() {
    return {
      /**
       * Список логов ошибок
       */
      systemLogs: {
        total: 0,
        per_page: 0,
        current_page: 0,
        data: [],
      },
      /**
       * Лог ошибок
       */
      systemLog: {},

      /**
       * Список пользователей
       */
      users: {
        total: 0,
        per_page: 0,
        current_page: 0,
        data: [],
      },

      /**
       * Список пользователей предприятия
       */
      companyUsers: {
        total: 0,
        per_page: 0,
        current_page: 0,
        data: [],
      },

      /**
       * Пользователь
       */
      user: {},

      /**
       * Список предприятий
       */
      companies: {
        total: 0,
        per_page: 0,
        current_page: 0,
        to: 0,
        data: [],
      },

      /**
       * Предприятие
       */
      company: {},

      /**
       * Настройки
       */
      settings: {
        vbgApi: {
          requestCooldown: 3,
          requestsPerDay: 10000,
          maxGtins: 50,
        },
        gtinSearch: {
          requestCooldown: 3,
          maxGtins: 50,
          bulkSearchActive: false,
        },
        mobileApi: {
          freeScans: 30,
        },
      },

      /**
       * Настройки ограничений запросов к API
       */
      apiLimits: [],
    };
  },
  getters: {
    getLogDate(state: any) {
      return dayjs(state.systemLog.created_at).format('YYYY.MM.DD 23:59');
    },
  },
  mutations: {
    /**
     * Устанавливает загруженные логи
     *
     * @param state
     * @param logs Список логов
     */
    setSystemLogs(state: Record<string, any>, { logs, reset }: { logs: Record<string, any>; reset: boolean }): void {
      if (reset) state.systemLogs = Object.assign({}, { ...logs, data: [].concat([], logs.data) });
      else state.systemLogs = Object.assign({}, { ...logs, data: [].concat(state.systemLogs.data, logs.data) });
    },

    /**
     * Устанавливает загруженных пользователей
     *
     * @param state
     * @param users Список пользователей
     */
    setUsers(state: Record<string, any>, users: any): void {
      state.users = Object.assign({}, { ...users, data: [].concat(state.users.data, users.data) });
    },

    /**
     * Устанавливает загруженных пользователей предприятия
     *
     * @param state
     * @param users Список пользователей
     */
    setCompanyUsers(state: Record<string, any>, users: any): void {
      state.companyUsers = users;
    },

    /**
     * Устанавливает загруженные предприятия
     *
     * @param state
     * @param companies Список предприятий
     */
    setCompanies(state: Record<string, any>, { companies, reset }: { companies: any; reset: boolean }): void {
      if (reset) state.companies = companies;
      else {
        state.companies.data = state.companies.data.concat(companies.data);
        state.companies.to = companies.to;
      }
    },

    /**
     * Устанавливает загруженное предприятие
     *
     * @param state
     * @param company Объект предприятия
     */
    setCompany(state: Record<string, any>, company: Record<string, string | number>): void {
      state.company = company;
      state.company.pro = company.pro == 1;
    },

    /**
     * Устанавливает загруженный объект пользователя
     *
     * @param state
     * @param user Объект пользователя
     */
    setUser(state: Record<string, any>, user: Record<string, any>): void {
      state.user = new User();

      state.user.id = user.id;
      state.user.company_id = user.company_id;
      state.user.login = user.login;
      state.user.gln = user.gln;
      state.user.email = user.email;
      state.user.role = user.role;
      state.user.tokens = user.tokens_by_api;
      state.user.declineReasons = user.decline_reasons_by_api;
      state.user.pro = user.pro == 1;
      state.user.company = user.company;
      state.user.company_id = user.company_id;
    },

    /**
     * Установка настроек
     * @param state
     */
    setSettings(state: Record<string, any>, settings: Record<string, any>): void {
      for (const module in settings) {
        const parameters = settings[module];
        parameters.forEach((parameter: Record<string, any>) => {
          if (typeof state.settings[module][parameter.name] == 'boolean') {
            state.settings[module][parameter.name] = parameter.value == '1';
          } else {
            state.settings[module][parameter.name] = parameter.value;
          }
        });
      }
    },

    /**
     * Обновление данных предприятия
     * @param state
     */
    refreshCompany(state: Record<string, any>, company: Record<string, string | number>): void {
      // Текущее подробное представление, если открыто обновляемое предприятия
      if (state.company.id == company.id) {
        state.company = company;
        state.company.pro = company.pro == 1;
      }

      // Обновление в общем списке
      state.companies.data.forEach((stateCompany: Record<string, string | number>, i: number) => {
        if (stateCompany.id == company.id) {
          state.companies.data.splice(i, 1, company);
        }
      });
    },

    /**
     * Установка настроек ограничений запросов к API
     * @param state
     */
    setApiLimits(state: Record<string, any>, limits: Array<Record<string, any>>): void {
      state.apiLimits = limits;
    },
  },
  actions: {
    async getSystemLog({ state }: any, log_id: number) {
      const r = await axios.get(`system-logs/${log_id}`);
      if (!r?.data) return;
      state.systemLog = Object.assign({}, r.data);
      return r?.data;
    },
    /**
     * Получение системных логов
     *
     * @param context
     * @param data Параметры запроса
     */
    async getSystemLogs({ commit }, data): Promise<void> {
      commit('setLoading', { flagName: 'system-logs', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams(data);
        const response: any = await axios.get('system-logs?' + query);
        commit('setSystemLogs', { logs: response.data, reset: data.reset });
        //state.systemLogs.pages = response.total;
      } catch (error) {
        commit('setSystemLogs', { logs: { data: [] }, reset: true });
      } finally {
        commit('setLoading', { flagName: 'system-logs', flagState: false }, { root: true });
      }
    },

    /**
     * Получение списка пользователей
     *
     * @param context
     * @param data Параметры запроса
     */
    async getUsers({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'users', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('users?' + query);
        commit('setUsers', response.data);
      } catch (error) {
        commit('setUsers', []);
      } finally {
        commit('setLoading', { flagName: 'users', flagState: false }, { root: true });
      }
    },

    /**
     * Получение списка пользователей предприятия
     *
     * @param context
     * @param data Параметры запроса
     */
    async getCompanyUsers({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'users', flagState: true }, { root: true });

      try {
        const response = await axios.get('users?companyId=' + data.companyId);
        commit('setCompanyUsers', response.data);
      } catch (error) {
        commit('setCompanyUsers', []);
      } finally {
        commit('setLoading', { flagName: 'users', flagState: false }, { root: true });
      }
    },

    /**
     * Получение настроек
     *
     * @param context
     * @param userId ID пользователя
     */
    async getSettings({ commit }: { commit: any }, params: Record<string, string>): Promise<void> {
      commit('setLoading', { flagName: 'settings', flagState: true }, { root: true });

      try {
        let query = '';
        if (typeof params !== 'undefined' && typeof params.userId !== 'undefined') query = new URLSearchParams({ user_id: params.userId }).toString();
        if (typeof params !== 'undefined' && typeof params.companyId !== 'undefined')
          query = new URLSearchParams({
            company_id: params.companyId,
          }).toString();

        const response = await axios.get('settings?' + query);
        commit('setSettings', response.data);
      } catch (error) {
        commit('setSettings', []);
      } finally {
        commit('setLoading', { flagName: 'settings', flagState: false }, { root: true });
      }
    },

    /**
     * Сохранение настроек
     *
     * @param context
     * @param data Параметры запроса
     */
    async saveSettings({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'settings', flagState: true }, { root: true });

      try {
        await axios.post('settings', data);

        return true;
      } catch (error) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'settings', flagState: false }, { root: true });
      }
    },

    /**
     * Получение списка предприятий
     *
     * @param context
     * @param data Параметры запроса
     */
    async getCompanies({ commit }, data): Promise<void> {
      commit('setLoading', { flagName: 'companies', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('users/companies?' + query);
        commit('setCompanies', { companies: response.data, reset: data.reset });
      } catch (error) {
        commit('setCompanies', { companies: { data: [] }, reset: true });
      } finally {
        commit('setLoading', { flagName: 'companies', flagState: false }, { root: true });
      }
    },

    /**
     * Получение объекта предприятия
     *
     * @param context
     * @param userId ID предприятия
     */
    async getCompany({ commit }: { commit: any }, companyId: string): Promise<void> {
      commit('setLoading', { flagName: 'companies', flagState: true }, { root: true });

      try {
        const response = await axios.get('users/companies/' + companyId);
        commit('setCompany', response.data);
      } catch (error) {
        commit('setCompany', {});
      } finally {
        commit('setLoading', { flagName: 'companies', flagState: false }, { root: true });
      }
    },

    /**
     * Сохранение предприятия
     *
     * @param context
     * @param data Параметры предприятия
     */
    async saveCompany({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'companies', flagState: true }, { root: true });

      try {
        await axios.post('users/companies/' + data.id, data);

        return true;
      } catch (error) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'companies', flagState: false }, { root: true });
      }
    },

    /**
     * Получение объекта пользователя
     *
     * @param context
     * @param userId ID пользователя
     */
    async getUser({ commit }: { commit: any }, userId: string): Promise<void> {
      commit('setLoading', { flagName: 'users', flagState: true }, { root: true });

      try {
        const response = await axios.get('users/' + userId);
        commit('setUser', response.data);
      } catch (error) {
        commit('setUser', {});
      } finally {
        commit('setLoading', { flagName: 'users', flagState: false }, { root: true });
      }
    },

    /**
     * Сохранение пользователя
     *
     * @param context
     * @param data Параметры пользователя
     */
    async saveUser({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'users', flagState: true }, { root: true });

      try {
        await axios.post('users/' + data.id, data);

        return true;
      } catch (error) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'users', flagState: false }, { root: true });
      }
    },

    /**
     * Обновление информации о предприятии
     *
     * @param context
     * @param companyId ID предприятия
     */
    async refreshCompanyInfo({ commit }: { commit: any }, companyId: string): Promise<boolean> {
      try {
        const response = await axios.post('users/companies/' + companyId + '/refresh');
        commit('refreshCompany', (response.data as Record<string, any>).company);

        return true;
      } catch (error) {
        return false;
      }
    },

    /**
     * Получение настроек ограничений API
     *
     * @param context
     * @param userId ID пользователя
     */
    async getApiLimits({ commit }: { commit: any }, params: Record<string, string>): Promise<Array<Record<string, any>>> {
      commit('setLoading', { flagName: 'api-limits', flagState: true }, { root: true });

      try {
        let query = '';
        if (typeof params !== 'undefined' && typeof params.userId !== 'undefined') query = new URLSearchParams({ user_id: params.userId }).toString();
        if (typeof params !== 'undefined' && typeof params.companyId !== 'undefined')
          query = new URLSearchParams({
            company_id: params.companyId,
          }).toString();

        const response = await axios.get('vbg-api/limits?' + query);
        commit('setApiLimits', response.data);

        return response.data;
      } catch (error) {
        commit('setApiLimits', []);

        return [];
      } finally {
        commit('setLoading', { flagName: 'api-limits', flagState: false }, { root: true });
      }
    },

    /**
     * Сохранение настроек ограничений API
     *
     * @param context
     * @param data Параметры запроса
     */
    async saveApiLimits({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api-limits', flagState: true }, { root: true });

      try {
        await axios.post('vbg-api/limits', data);

        return true;
      } catch (error) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api-limits', flagState: false }, { root: true });
      }
    },
  },
  modules: {},
};
export default admin;

<template>
  <div id="admin-gtin-logs" class="admin-gtin-logs__container">
    <h1 class="display-medium">Журнал ошибок</h1>
    <GS1TableFlat
      v-model:search="searchString"
      v-bind="{
        fields: tableFields,
        data: logs.data,
        linkable: true,
        showRange: false,
        showHeader: true,
        showFooter: false,
        infinityScroll: true,
        displayedCount: logs.to,
        totalCount: logs.total,
        loading: lodaData || !!loading['system-log'],
      }"
      @search-reset="onSearchReset"
      @search="onFilter"
      @record-click="openLog"
      @next-page="onNextPage"
    >
      <template #header>
        <div class="page-header">
          <GS1TableFilter class="history__filter">
            <div class="history__table-filter">
              <p class="history__table-filter__type-title">
                <b>Тип ошибки</b>
              </p>
              <GS1Select v-model="filterType" label="Тип">
                <GS1Option disabled :value="null">Не выбрано</GS1Option>
                <GS1Option value="api">API GS1</GS1Option>
                <GS1Option value="vbg_api">API VBG</GS1Option>
              </GS1Select>
              <p class="history__table-filter__type-title">
                <b> Выбрать интервал:</b>
              </p>
              <GS1InputDateRange v-model:from="dateFrom" v-model:to="dateTo" display-format="DD.MM.YYYY" />
            </div>
            <template #actions>
              <GS1Btn link min no-margin no-padding @click="onReset">Сбросить</GS1Btn>
              <GS1Btn link min no-margin no-padding @click="onFilter">Применить</GS1Btn>
            </template>
          </GS1TableFilter>
          <div class="upload-first-step__header-filter__container">
            <GS1Btn :active="filterDayRange == 'day'" transparent @click="setFilterDay('day')"> День </GS1Btn>
            <GS1Btn :active="filterDayRange == 'week'" transparent @click="setFilterDay('week')"> Неделя </GS1Btn>
            <GS1Btn :active="filterDayRange == 'month'" transparent @click="setFilterDay('month')"> Месяц </GS1Btn>
            <GS1Btn :active="filterDayRange == 'year'" transparent @click="setFilterDay('year')"> Год </GS1Btn>
          </div>
        </div>
      </template>
      <template #after-header>
        <div class="history__under-header__container">
          <GS1TableFilterSelect v-if="filterType" v-model="filterType" class="history__under-header__no-margin" no-margin label="Тип ошибки">
            <GS1Option value="api" @click="onFilter">API GS1</GS1Option>
            <GS1Option value="vbg_api" @click="onFilter">API VBG</GS1Option>
          </GS1TableFilterSelect>
          <GS1TableFilterInputDateRange
            v-model:from="dateFrom"
            v-model:to="dateTo"
            class="history__under-header__no-margin"
            label="Дата"
            no-margin
            display-format="DD.MM.YYYY"
            @change="onFilter"
          >
          </GS1TableFilterInputDateRange>
        </div>
      </template>
    </GS1TableFlat>
    <SidePanel ref="sidepanel">
      <div id="detailed-log">
        <div class="field inline">
          <div class="label">Тип ошибки</div>
          <div class="value">
            {{ detailedLog.type }}
          </div>
        </div>

        <div class="field inline">
          <div class="label">Версия</div>
          <div class="value">
            {{ detailedLog.version }}
          </div>
        </div>

        <div class="field inline">
          <div class="label">API</div>
          <div class="value">
            {{ detailedLog.api }}
          </div>
        </div>

        <div class="field inline">
          <div class="label">Дата</div>
          <div class="value">
            {{ detailedLog.date }}
          </div>
        </div>

        <div class="field">
          <div class="label">Запрос</div>
          <div class="value">
            {{ detailedLog.request }}
          </div>
        </div>

        <div class="field">
          <div class="label">Ответ</div>
          <div class="value">
            {{ detailedLog.response }}
          </div>
        </div>

        <div class="field">
          <div class="label">Тело запроса</div>
          <div class="value">
            {{ detailedLog.data }}
          </div>
        </div>
      </div>
    </SidePanel>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, Ref, ComputedRef } from 'vue';
import type { Component } from 'vue';
import { useStore } from 'vuex';
import SidePanel from '@/components/SidePanel.vue';
import dayjs from 'dayjs';
import router from '@/router';
import type { GS1TableField } from '@gs1ru/web-components';

export default defineComponent({
  name: 'AdminSystemLogs',
  components: {
    SidePanel,
  },
  setup() {
    const store = useStore();
    const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    const sidepanel: Ref<Component | null> = ref(null);
    const page: Ref<number> = ref(1);
    const perPage: Ref<number> = ref(10);
    const searchString: Ref<string> = ref('');
    const filterType: Ref<string> = ref('');
    const filterApi: Ref<string> = ref('');
    const filterVersion: Ref<string> = ref('');
    const detailedLog: Ref<any> = ref({ id: 0 });
    const dateFrom: Ref<string> = ref(dayjs('2021-01-01').format(dateFormat));
    const dateTo: Ref<string> = ref(dayjs().format(dateFormat));
    const totalTablePages = computed(() => store.state?.admin?.systemLogs?.pages || 0);
    const tableFields: Array<GS1TableField> = [
      { label: 'Тип ошибки', field: 'type_name', sort: false, class: [] },
      { label: 'Версия', field: 'version', sort: false, class: [] },
      { label: 'API', field: 'api', sort: false, class: [] },
      {
        label: 'Запрос',
        field: 'request',
        sort: false,
        width: '200px',
        lineClamp: 1,
      },
      {
        label: 'Ответ',
        field: 'response',
        sort: false,
        width: '200px',
        lineClamp: 1,
      },
      {
        label: 'Тело запроса',
        field: 'data',
        sort: false,
        width: '200px',
        lineClamp: 1,
      },
      { label: 'Дата', field: 'date', sort: false, class: [] },
    ];
    const lodaData: Ref<boolean> = ref(false);
    const loading: ComputedRef<any> = computed(() => store.state.loading);
    const logs: ComputedRef<any> = computed(() => {
      const result = store.state.admin?.systemLogs || { data: [], total: 0, per_page: 0, current_page: 0 };
      result.data = result.data.map((log: any) => {
        if (typeof log == 'undefined') return;

        log.date = dayjs(log.created_at).format('DD.MM.YYYY HH:mm');
        return log;
      });
      return result;
    });
    const apis: ComputedRef<Array<Record<string, any>>> = computed(() => store.state.vbgApi.apiList);
    const api: ComputedRef<Record<string, any>> = computed(() => {
      if (filterType.value == 'vbg_api' && filterApi.value !== '') {
        return apis.value.find((api: Record<string, any>) => api.code == filterApi.value) || {};
      }
      return {};
    });
    const apiVersions: ComputedRef<Array<string>> = computed(() => {
      if (api.value) return api.value.versions;
      return [];
    });
    const filterDayRange: Ref<string> = ref('year');

    const openLog = (e: Event, log: any) => {
      if (!log?.id) return;
      router.push({ name: 'admin-system-log', params: { id: log.id } });
    };

    const getSystemLogs = (reset = false): void => {
      if (lodaData.value || loading.value['system-log']) return;
      lodaData.value = true;
      store
        .dispatch('admin/getSystemLogs', {
          page: page.value,
          perPage: perPage.value,
          success: 0,
          search: searchString.value,
          type: filterType.value,
          api: filterApi.value,
          version: filterVersion.value,
          dateFrom: dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00'),
          dateTo: dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59'),
          reset: reset,
        })
        .finally(() => (lodaData.value = false));
    };
    const onNextPage = () => {
      if (lodaData.value || loading.value['system-log'] || ++logs.value.current_page > logs.value.last_page) return;
      page.value++;
      getSystemLogs();
    };
    const onFilter = () => {
      page.value = 1;
      logs.value.data = [];
      getSystemLogs(true);
    };
    const onReset = () => {
      page.value = 1;
      (filterType.value = ''), (dateFrom.value = dayjs('2021-01-01').format(dateFormat));
      dateTo.value = dayjs().format(dateFormat);
    };

    const onSearchReset = () => {
      page.value = 1;
      searchString.value = '';
      getSystemLogs(true);
    };

    const setFilterDay = (range = 'week') => {
      page.value = 1;
      logs.value.data = [];
      filterDayRange.value = range;
      if (range == 'day') dateFrom.value = dayjs().format(dateFormat);
      else if (range == 'week') dateFrom.value = dayjs().subtract(1, 'week').format(dateFormat);
      else if (range == 'month') dateFrom.value = dayjs().subtract(1, 'month').format(dateFormat);
      else if (range == 'year') dateFrom.value = dayjs().subtract(1, 'year').format(dateFormat);

      dateTo.value = dayjs().format(dateFormat);
      getSystemLogs();
    };
    const showDetails = (log: any): void => {
      detailedLog.value = log;
      // @ts-expect-error window не может быть null в данном контексте
      if (sidepanel.value?.show) sidepanel.value.show();
    };

    watch(filterApi, () => {
      filterVersion.value = '';
      page.value = 1;
      getSystemLogs();
    });

    watch(filterType, () => {
      if (filterType.value === null) filterType.value = '';
      onFilter();
    });

    getSystemLogs();
    store.dispatch('vbgApi/getApiList');
    store.commit('setH1', 'Журнал ошибок');
    store.commit('setNoPadding', true);
    return {
      sidepanel,
      apiVersions,
      detailedLog,
      loading,
      lodaData,
      logs,
      filterDayRange,
      searchString,
      perPage,
      page,
      dateFrom,
      dateTo,
      filterType,
      filterApi,
      filterVersion,
      totalTablePages,
      tableFields,
      getSystemLogs,
      onFilter,
      showDetails,
      onSearchReset,
      onNextPage,
      openLog,
      onReset,
      setFilterDay,
    };
  },
});
</script>

<style lang="scss" scoped>
.expanded-block,
.page-header {
  display: flex;

  .history__filter {
    margin-right: var(--margin-x3);
  }

  .history__table-filter {
    margin: 0 calc(var(--gs1-margin-x25) * -1);
    .history__table-filter__type-title {
      margin: 0 var(--gs1-margin-x25) var(--gs1-margin-x25) var(--gs1-margin-x25);
    }
  }
}
.history__under-header__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 calc(var(--gs1-input-horisontal-margin) * -1) var(--gs1-margin-x25) calc(var(--gs1-input-horisontal-margin) * -1);
}
.upload-first-step__header-filter__container {
  display: flex;

  .gs1-element.gs1-btn {
    margin: 0 var(--margin-x2);
  }
}
.upload-first-step__result-modal__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: var(--margin-x3);

  .close-button {
    position: absolute;
    border-radius: var(--border-radius-x2) 0 0 var(--border-radius-x2);
    left: -62px;
  }

  .gtin-number__element {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #e6eef8;

    .element__gtin {
      padding-bottom: var(--padding-x3);
    }
  }

  .element {
    padding: 16px 0;
    display: flex;
    border-bottom: 1px solid #e6eef8;

    .element__type {
      width: 190px;
      margin-right: var(--margin-x4);
    }
  }
  .gtin-size__element {
    padding: var(--padding-x3) 0;
    display: flex;
    border-bottom: 1px solid #e6eef8;

    .element__size {
      width: 190px;
      margin-right: var(--margin-x4);
    }
  }
}
</style>
<style lang="scss">
.admin-gtin-logs__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .gs1-element.gs1-table-flat {
    .gs1-table-flat__table-tr {
      cursor: pointer;
    }
  }
  .gs1-element.gs1-select .gs1-select__items {
    width: 280px;
  }
}
</style>

import { createApp } from 'vue';
import { configure } from 'vee-validate';
import axios from 'axios';
import Maska from 'maska';
import VueEasyLightbox from 'vue-easy-lightbox';
import App from './App.vue';
import router from './router';
import store from './store';
import config from '@/config';
import layouts from './layouts';

import Toast from './models/Toast';

import VueHighlightJS from 'vue3-highlightjs';
import 'highlight.js/styles/github.css';

import ClickOutside from '@/directives/ClickOutside';
import TextareaAutoresize from '@/directives/TextareaAutoresize';
import Expand from '@/directives/Expand';
import ResizableColumns from '@/directives/ResizableColumns';
import Tooltip from '@/directives/Tooltip';

import GtinSearchModule from './modules/gtin-search';
import CompanySearchModule from './modules/company-search';
import AuthModule from './modules/auth';
import AdminModule from './modules/admin';
import StatisticsModule from './modules/statistics';
import VbgApiModule from './modules/vbg-api';
import NotificatonsModule from './modules/notifications';
import SearchModule from './modules/search';
import UserModule from './modules/user';
import ChangelogModule from './modules/changelog';
import { default as GS1Components, GS1Directives } from '@gs1ru/web-components';
import VueYandexMetrika from 'vue3-yandex-metrika';

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: true,
  validateOnModelUpdate: false,
});

/**
 * Глобальная обработка ошибок HTTP запросов
 */
axios.interceptors.response.use(
  (response: any): any => {
    return response;
  },
  (error: any) => {
    if (typeof error.response !== 'undefined') {
      if ([405, 500].includes(error.response.status)) {
        store.dispatch('showToast', new Toast('Ошибка', 'При получении данных возникла ошибка. Попробуйте позже.', 'danger'), {
          root: true,
        });
      }
      const isTestApi = error.response.config.url.includes('-api/v');

      if ([403].includes(error.response.status) && !isTestApi) {
        location.href = '/';
      }

      if ([404].includes(error.response.status) && !isTestApi) {
        return;
      }

      if ([401].includes(error.response.status) && !isTestApi) {
        if (typeof error.response.data.code !== 'undefined' && error.response.data.code == 1) {
          location.href = config.appInactiveUserUrl;
        }
      }
    }

    return Promise.reject(error);
  }
);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.endpoint;

const authModule = new AuthModule(router, store);
const adminModule = new AdminModule(router, store);
const gtinSearchModule = new GtinSearchModule(router, store);
const companySearchModule = new CompanySearchModule(router, store);
const statisticsModule = new StatisticsModule(router, store);
const gs1ApiModule = new VbgApiModule(router, store);
const notificatonsModule = new NotificatonsModule(router, store);
const searchModule = new SearchModule(router, store);
const userModule = new UserModule(router, store);
const changelogModule = new ChangelogModule(router, store);

authModule.init();
adminModule.init();
gtinSearchModule.init();
companySearchModule.init();
statisticsModule.init();
gs1ApiModule.init();
notificatonsModule.init();
searchModule.init();
userModule.init();
changelogModule.init();
store.dispatch('init');

const app = createApp(App)
  .directive('click-outside', ClickOutside)
  .directive('autoresize', TextareaAutoresize)
  .directive('expand', Expand)
  .directive('tooltip', Tooltip)
  .directive('resizable-columns', ResizableColumns)
  .use(store)
  .use(router)
  .use(VueHighlightJS)
  .use(Maska)
  .use(GS1Components, { router })
  .use(VueEasyLightbox)
  .use(GS1Directives)
  .use(layouts)
  .use(VueYandexMetrika, {
    id: process.env.VUE_APP_YANDEX_ID,
    env: process.env.VUE_APP_NODE_ENV,
    router: router,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  })
  .mount('#app');

export default app;

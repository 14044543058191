<template>
  <div id="notifications-panel" v-click-outside="hideLastNotifications">
    <a
      v-if="user.loggedIn"
      class="toggle-last-notifications navbar-button"
      href="#"
      @click.prevent="toggleLastNotifications"
    >
      <i class="icon-bell" />

      <span v-if="newNotifications" class="new-notifications">
        {{ newNotifications }}
      </span>
    </a>

    <div v-if="showLastNotifications" id="last-notifications">
      <div class="status-filter">
        <a
          :class="{ active: filter.showType == 'all' }"
          href="#"
          @click.prevent="filterLastNotifications('all')"
        >
          Все
        </a>
        |
        <a
          :class="{ active: filter.showType == 'unread' }"
          href="#"
          @click.prevent="filterLastNotifications('unread')"
        >
          Не прочитанные
        </a>
        |
        <a
          :class="{ active: filter.showType == 'read' }"
          href="#"
          @click.prevent="filterLastNotifications('read')"
        >
          Прочитанные
        </a>
      </div>

      <div class="list">
        <div
          v-for="notification in lastNotifications"
          :key="notification.id"
          :class="{ read: notification.read, hiding: notification.hiding }"
          class="notification"
        >
          <div class="heading">
            <div class="date">
              {{ notification.date }}
            </div>

            <div class="buttons">
              <a
                class="toggle-read"
                href="#"
                @click.prevent="toggleRead(notification, true)"
              >
                <i class="fas fa-check" />
              </a>

              <a class="remove" href="#" @click.prevent="remove(notification)">
                <i class="icon-trash" />
              </a>

              <router-link class="open" :to="notification.url">
                <i class="fas fa-arrow-right" />
              </router-link>
            </div>
          </div>

          <div class="info">
            <ShortText height="62" @expanded="toggleRead(notification, true)">
              <div class="title">
                {{ notification.title }}
              </div>

              <div class="message">
                {{ notification.message }}
              </div>
            </ShortText>
          </div>
        </div>
      </div>

      <div class="open-all">
        <router-link :to="{ name: 'notifications' }">
          Все сообщения
        </router-link>
      </div>
    </div>

    <div id="active-notifications">
      <NotificationPopup
        v-for="notification in activeNotifications"
        :key="notification.id"
        :notification="notification"
        @close="closeNotification"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import NotificationPopup from './NotificationPopup.vue';
import User from '@/modules/auth/models/User';
import ShortText from '@/components/ShortText.vue';

export default defineComponent({
  name: 'Notifications',
  components: {
    NotificationPopup,
    ShortText,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const showLastNotifications: Ref<boolean> = ref(false);
    const filter: ComputedRef<Record<string, string>> = computed(
      () => store.state.notifications.lastNotificationsFilter
    );
    const user: ComputedRef<User> = computed(() => store.state.auth.user);
    const activeNotifications: ComputedRef<any> = computed(
      () => store.state.notifications.activeNotifications
    );
    const lastNotifications: ComputedRef<any> = computed(
      () => store.state.notifications.lastNotifications
    );
    const newNotifications: ComputedRef<number> = computed(() => {
      let newNotifications = store.state.notifications.newNotifications;
      if (newNotifications > 9999) newNotifications = '9999+';

      return newNotifications;
    });

    /**
     * Скрытие уведомления
     */
    const closeNotification = (id: string): void => {
      store.commit('notifications/closeNotification', id);
    };

    /**
     * Переключение списка последних уведомлений
     */
    const toggleLastNotifications = (): void => {
      showLastNotifications.value = !showLastNotifications.value;
    };

    /**
     * Скрытие списка последних уведомлений
     */
    const hideLastNotifications = (): void => {
      showLastNotifications.value = false;
    };

    /**
     * Изменение фильтра последних уведомлений
     */
    const filterLastNotifications = (type: string): void => {
      filter.value.showType = type;

      store.dispatch('notifications/getLastNotifications');
    };

    /**
     * Установка статуса "прочитано"
     */
    const toggleRead = (
      notification: Record<string, string | number | boolean>,
      state: boolean
    ): void => {
      if (typeof state == 'undefined') state = !notification.read;

      notification.read = state;

      if (
        (filter.value.showType == 'unread' && state) ||
        (filter.value.showType == 'read' && !state)
      ) {
        notification.hiding = true;
        store
          .dispatch('notifications/toggleRead', {
            state: state,
            notificationId: notification.id,
          })
          .then(() => {
            store.dispatch('notifications/getLastNotifications');
          });
      } else {
        store.dispatch('notifications/toggleRead', {
          state: state,
          notificationId: notification.id,
        });
      }
    };

    /**
     * Удаление уведомления
     */
    const remove = (
      notification: Record<string, string | number | boolean>
    ): void => {
      notification.hiding = true;

      store.dispatch('notifications/remove', notification.id).then(() => {
        store.dispatch('notifications/getLastNotifications');
      });
    };

    onMounted(() => {
      store.dispatch('auth/getUser').then(() => {
        if (user.value.loggedIn) {
          store.dispatch('notifications/getLastNotifications');
          store.dispatch('notifications/getNewNotifications');
        }
      });
    });
    watch(
      () => route.name,
      () => (showLastNotifications.value = false)
    );

    return {
      filter,
      user,
      activeNotifications,
      lastNotifications,
      newNotifications,
      showLastNotifications,
      closeNotification,
      toggleLastNotifications,
      hideLastNotifications,
      filterLastNotifications,
      toggleRead,
      remove,
    };
  },
});
</script>

<style lang="scss">
@keyframes hideNotification {
  0% {
    right: 0;
    max-height: 100px;
    border-width: 1px;
    margin-bottom: 16px;
    opacity: 1;
  }
  50% {
    right: -100%;
    max-height: 100px;
    border-width: 1px;
    margin-bottom: 16px;
    opacity: 0;
  }
  100% {
    right: -100%;
    max-height: 0px;
    border-width: 0;
    margin-bottom: 0px;
    opacity: 0;
  }
}

#notifications-panel {
  position: relative;
}
#active-notifications {
  position: fixed;
  top: 70px;
  right: 25px;
}
.toggle-last-notifications {
  .new-notifications {
    position: absolute;
    height: 20px;
    min-width: 20px;
    padding: 4px;
    top: -10px;
    right: -10px;
    background: #fff;
    color: #f23434;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 10px;
  }
}

#last-notifications {
  position: absolute;
  top: calc(72px - 0.75rem);
  right: 0;
  width: 360px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 36px 28px 25px;
  .status-filter {
    text-align: center;
    font-weight: bold;
    font-size: 11px;
    color: #888b8d;
    margin-bottom: 30px;
    a {
      color: #888b8d;
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: #f26334;
      }
    }
  }
  .list {
    overflow: hidden;
    .notification {
      margin-bottom: 16px;
      border-bottom: 1px solid #d8d8d8;
      position: relative;
      overflow: hidden;
      max-height: 999px;
      &.hiding {
        animation-name: hideNotification;
        animation-duration: 0.6s;
        max-height: 0px;
        border-width: 0px;
        margin-bottom: 0px;
      }
      &.read {
        .info {
          .title {
            color: #71b790;
          }
          .message {
            color: #71b790;
          }
        }
        .buttons .toggle-read {
          color: #71b790;
        }
      }
      .heading {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .date {
          font-size: 12px;
          margin-right: 22px;
          color: #888b8d;
          font-style: italic;
        }
        .buttons {
          display: flex;
          align-items: center;
          a {
            color: #454545;
            &:hover {
              text-decoration: none;
            }
            + a {
              margin-left: 12px;
            }
          }
          .toggle-read,
          .open {
            height: 16px;
            width: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid;
            font-size: 10px;
            border-radius: 50%;
          }
          .remove {
            display: flex;
            align-items: center;
            font-size: 17px;
            height: 16px;
            &:hover {
              color: #f23434;
            }
          }
        }
      }
      .info {
        margin-bottom: 8px;
      }
      .title {
        font-size: 16px;
        color: #002c6c;
      }
      .message {
        font-size: 11px;
        margin-top: 6px;
        color: #002c6c;
      }
    }
  }
  .open-all {
    display: flex;
    justify-content: center;
    a {
      color: #002c6c;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>

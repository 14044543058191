<template>
  <div class="page-container page__history">
    <h1 class="display-medium">API</h1>
    <GS1TableFlat
      v-bind="{
        fields: tableFields,
        data: apiList,
        linkable: true,
        showRange: false,
        showHeader: false,
        showFooter: false,
        infinityScroll: true,
      }"
    >
      <template #name="{ item }">
        <div class="admin-api__edit-btn__container">
          <GS1BtnIcon :icon="['pen']" no-margin class="admin-api__edit-btn" @click="openApi(item.code, item.id)" />
          <span class="admin-api__api-name">{{ item.name }}</span>
        </div>
      </template>
    </GS1TableFlat>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import type { GS1TableField } from '@gs1ru/web-components';

export default defineComponent({
  name: 'AdminApiList',
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Список API
     */
    const apiList: ComputedRef<Array<Record<string, any>>> = computed(() => store.state.vbgApi.apiList);

    const tableFields: Array<GS1TableField> = [
      {
        label: 'API',
        field: 'name',
        sort: false,
        lineClamp: 1,
      },
    ];

    const openApi = (api_code: string, api_id: number) => {
      if (!api_id) return;
      router.push({ name: 'admin-api', params: { api: api_code, id: api_id } });
    };

    onMounted(() => {
      store.commit('setH1', 'Список API');
      store.commit('setNoPadding', true);
      store.dispatch('vbgApi/getApiList');
    });

    return {
      loading,
      apiList,
      tableFields,
      openApi,
    };
  },
});
</script>
<style lang="scss" scoped>
.page__history {
  .admin-api__edit-btn__container {
    display: flex;
    align-items: center;
    margin-left: var(--margin-x1);
  }
  .admin-api__api-name {
    margin-left: var(--margin-x2);
  }
  .gs1-element.gs1-table-flat {
    .gs1-table-flat__table-td.gs1-table-flat__body-actions {
      .gs1-table-flat__table-td-value {
        .gs1-element.gs1-input-check {
          margin-left: 0;
        }
      }
    }
  }
}
</style>

import { Store } from 'vuex';
import store from './store';
import MenuLink from '@/models/MenuLink';
import AdminHome from './views/AdminHome.vue';
import AdminUsers from './views/AdminUsers.vue';
import AdminUser from './views/AdminUser.vue';
import AdminCompanies from './views/AdminCompanies.vue';
import AdminSystemLogs from './views/AdminSystemLogs.vue';
import AdminSystemLog from './views/AdminSystemLog.vue';
import AdminSettings from './views/AdminSettings.vue';
import AdminSettingsMobileApi from './views/AdminSettingsMobileApi.vue';
import AdminUserSettings from './views/AdminUserSettings.vue';
import AdminUserForm from './views/AdminUserForm.vue';
import AdminUserApi from './views/AdminUserApi.vue';
import AdminCompanyForm from './views/AdminCompanyForm.vue';
import AdminApiList from './views/AdminApiList.vue';
import AdminApi from './views/AdminApi.vue';
import AdminCompanyUsers from './views/AdminCompanyUsers.vue';
import AdminCompanySettings from './views/AdminCompanySettings.vue';
import AdminSettingsBulkSearch from './views/AdminSettingsBulkSearch.vue';

export default class AdminModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.addLinks();
    this.store.registerModule('admin', store);
  }

  addRoutes(): void {
    this.router.addRoute({
      path: '/admin',
      component: AdminHome,
      name: 'admin',
      meta: { checkAccess: ['access-admin-panel'], redirectToAuth: true, activeMenuRoute: ['admin'] },
    });

    this.router.addRoute({
      path: '/admin/users',
      component: AdminUsers,
      name: 'admin-users',
      meta: {
        checkAccess: ['access-admin-panel'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-users'],
      },
    });
    this.router.addRoute({
      path: '/admin/users/:userId',
      components: { default: AdminUsers, right: AdminUser },
      name: 'admin-user',
      meta: {
        checkAccess: ['access-admin-panel', 'read-system-logs'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-users', 'admin-user'],
      },
    });
    this.router.addRoute({
      path: '/admin/users/:userId/settings',
      component: AdminUserSettings,
      name: 'admin-user-settings',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-user-settings'] },
    });
    this.router.addRoute({
      path: '/admin/users/:userId/edit',
      component: AdminUserForm,
      name: 'admin-user-edit',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-user-edit'] },
    });
    this.router.addRoute({
      path: '/admin/users/:userId/api',
      component: AdminUserApi,
      name: 'admin-user-api',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-user-api'] },
    });
    this.router.addRoute({
      path: '/admin/system-logs',
      component: AdminSystemLogs,
      name: 'admin-system-logs',
      meta: {
        checkAccess: ['access-admin-panel', 'read-system-logs'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-system-logs'],
      },
    });
    this.router.addRoute({
      path: '/admin/system-logs/:id',
      components: { default: AdminSystemLogs, right: AdminSystemLog },
      name: 'admin-system-log',
      meta: {
        checkAccess: ['access-admin-panel', 'read-system-logs'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-system-logs', 'admin-system-log'],
      },
    });
    this.router.addRoute({
      path: '/admin/settings',
      component: AdminSettings,
      name: 'admin-settings',
      meta: {
        checkAccess: ['access-admin-panel'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-settings'],
      },
    });
    this.router.addRoute({
      path: '/admin/settings/mobile-api',
      component: AdminSettingsMobileApi,
      name: 'admin-settings-mobile-api',
      meta: {
        checkAccess: ['access-admin-panel'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-settings-mobile-api'],
      },
    });
    this.router.addRoute({
      path: '/admin/settings/bulk-search',
      component: AdminSettingsBulkSearch,
      name: 'admin-settings-bulk-search',
      meta: {
        checkAccess: ['access-admin-panel'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-settings-bulk-search'],
      },
    });
    this.router.addRoute({
      path: '/admin/companies',
      component: AdminCompanies,
      name: 'admin-companies',
      meta: {
        checkAccess: ['access-admin-panel'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-companies'],
      },
    });
    this.router.addRoute({
      path: '/admin/companies/:companyId/edit',
      components: { default: AdminCompanies, right: AdminCompanyForm },
      name: 'admin-company-edit',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-companies', 'admin-company-edit'] },
    });
    this.router.addRoute({
      path: '/admin/api',
      component: AdminApiList,
      name: 'admin-api-list',
      meta: {
        checkAccess: ['access-admin-panel'],
        parent: 'admin',
        nested: true,
        activeMenuRoute: ['admin', 'admin-api-list'],
      },
    });
    this.router.addRoute({
      path: '/admin/api/:api/:id',
      components: { default: AdminApiList, right: AdminApi },
      name: 'admin-api',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-api-list', 'admin-api'] },
    });
    this.router.addRoute({
      path: '/admin/api/:api/requests',
      components: { default: AdminApiList, right: AdminApi },
      name: 'admin-api-requests',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-api-requests'] },
    });
    this.router.addRoute({
      path: '/admin/api/:api/requests/:requestId',
      components: { default: AdminApiList, right: AdminApi },
      name: 'admin-api-request',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-api-request'] },
    });
    this.router.addRoute({
      path: '/admin/companies/:companyId/users',
      component: AdminCompanyUsers,
      name: 'admin-company-users',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-company-users'] },
    });
    this.router.addRoute({
      path: '/admin/companies/:companyId/settings',
      component: AdminCompanySettings,
      name: 'admin-company-settings',
      meta: { checkAccess: ['access-admin-panel'], parent: 'admin', activeMenuRoute: ['admin', 'admin-company-settings'] },
    });
  }

  addLinks(): void {
    this.store
      .dispatch('addLink', new MenuLink('Администрирование', { default: 'gear', light: 'gear-l-h', dark: 'gear-d-h' }, 50, { name: 'admin' }))
      .then(() => {
        this.store.dispatch('addLink', new MenuLink('Журнал ошибок', {}, 1, { name: 'admin-system-logs' }));
        this.store.dispatch('addLink', new MenuLink('Пользователи', {}, 2, { name: 'admin-users' }));
        this.store.dispatch('addLink', new MenuLink('Предприятия', {}, 3, { name: 'admin-companies' }));
        this.store.dispatch('addLink', new MenuLink('API', {}, 4, { name: 'admin-api-list' }));
        this.store.dispatch('addLink', new MenuLink('Настройки', {}, 5, { name: 'admin-settings' }));
      });
  }
}

<template>
  <div class="page__settings__tab-content">
    <div class="settings__container">
      <div class="settings__container__side">
        <h2 class="display-medium header__margin-bottom">Поиск по GTIN</h2>
        <div class="input-container">
          <GS1Input
            v-model="settings.gtinSearch.requestCooldown"
            v-bind="{ label: 'Время между запросами в секундах', id: 'request-time', name: 'request-time' }"
          />
          <GS1Input
            v-model="settings.gtinSearch.maxGtins"
            v-bind="{ label: 'Максимальное кол-во GTIN в одном запросе', id: 'gtin-max', name: 'gtin-max' }"
          />
        </div>
      </div>
      <div class="settings__container__side">
        <h2 class="display-medium header__margin-bottom">API</h2>
        <div class="input-container">
          <GS1Input v-model="settings.vbgApi.requestCooldown" :label="'Время между запросами в секундах'" />
          <GS1Input v-model="settings.vbgApi.requestsPerDay" :label="'Кол-во запросов в день'" />
          <GS1Input v-model="settings.vbgApi.maxGtins" :label="'Максимальное кол-во GTIN в одном запросе'" />
        </div>
      </div>
    </div>
    <ApiLimits ref="apiLimitsComponent" />
    <div class="page__settings__tab-content__footer">
      <GS1Btn style="width: 200px" no-margin primary @click="save">Сохранить</GS1Btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import Toast from '@/models/Toast';
import ApiLimits from '../components/ApiLimits.vue';
import app from '@/main';

export default defineComponent({
  name: 'CommonSettings',
  components: { ApiLimits },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Настройки
     */
    const settings = computed(() => store.state.admin.settings);

    const apiLimitsComponent = ref<InstanceType<typeof ApiLimits> | null>(null);

    const save = () => {
      store
        .dispatch('admin/saveSettings', {
          settings: settings.value,
          params: route.params,
        })
        .then((success: boolean) => {
          if (success) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            app.addAlert({ text: 'Изменения успешно применены', type: 'success', time: 5000 });
            store.dispatch('showToast', new Toast('Настройки', 'Настройки успешно сохранены.', 'success'));
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            app.addAlert({ text: 'Ничего не было не изменено', type: 'error', time: 5000 });
          }
          if (apiLimitsComponent.value) {
            apiLimitsComponent.value.save();
          }
        });
    };
    onMounted(() => {
      store.dispatch('admin/getSettings', route.params);
    });

    return {
      loading,
      settings,
      save,
      apiLimitsComponent,
    };
  },
});
</script>
<style lang="scss">
.page__settings__tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .page__settings__tab-content__footer {
    margin: auto auto 0 0;
  }
  .settings__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .gs1-element.gs1-input {
      margin: 0 var(--gs1-margin-x25) var(--gs1-margin-x4) var(--gs1-margin-x25);
    }
    .settings__container__side {
      display: flex;
      width: calc(50% - var(--margin-x25));
      flex-direction: column;
    }
  }
  .header__margin-bottom {
    margin-bottom: var(--margin-x4);
  }
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "admin-settings" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-sm-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyTabs = _resolveComponent("CompanyTabs")!
  const _component_CommonSettings = _resolveComponent("CommonSettings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CompanyTabs),
        _createVNode(_component_CommonSettings)
      ])
    ])
  ]))
}
<template>
  <div id="user-menu">
    <a v-if="!user.loggedIn" class="btn btn-primary" href="#" @click="login">
      <i class="fas fa-user mr-1" />
      Вход по Login
    </a>

    <div v-click-outside="hideUserMenu" class="user-menu-wrapper">
      <a
        v-if="user.loggedIn"
        class="user-menu-toggle navbar-button"
        href="#"
        @click.prevent="toggleUserMenu"
      >
        <i class="icon-user" />
      </a>

      <div class="user-menu" :class="{ active: userMenuActive }">
        <router-link :to="{ name: 'user-profile' }">
          <i class="icon-user" />
          Профиль
        </router-link>
        <!-- <router-link
          :to="{ name: 'user-settings' }"
        >
          <i class="icon-settings" />
          Настройки
        </router-link> -->
        <a href="#" @click.prevent="logout">
          <i class="icon-exit" />
          Выйти
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import User from '@/modules/auth/models/User';
import Toast from '@/models/Toast';

export default defineComponent({
  name: 'NavbarUserMenu',
  emits: ['login'],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /**
     * Отображение меню пользователя
     */
    const userMenuActive: Ref<boolean> = ref(false);

    /**
     * Объект пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Клик по кнопке "Вход по Login"
     */
    const login = () => {
      emit('login');
    };

    /**
     * Скрытие меню пользователя
     */
    const hideUserMenu = (): void => {
      userMenuActive.value = false;
    };

    /**
     * Показ меню пользователя
     */
    const toggleUserMenu = (): void => {
      userMenuActive.value = !userMenuActive.value;
    };

    /**
     * Выход из системы
     */
    const logout = (): void => {
      store.dispatch('auth/logout').then((success: boolean) => {
        if (success) {
          router.push({ name: 'home' });
          hideUserMenu();
          store.dispatch(
            'showToast',
            new Toast('', 'Вы успешно вышли из системы.'),
            { root: true }
          );
        }
      });
    };

    watch(
      () => route.name,
      () => (userMenuActive.value = false)
    );

    return {
      userMenuActive,
      user,
      login,
      logout,
      hideUserMenu,
      toggleUserMenu,
    };
  },
});
</script>

<style lang="scss">
#user-menu {
  .user-menu-toggle {
    margin-right: 0 !important;
  }
  .user-menu-wrapper {
    position: relative;
  }
  .user-menu {
    outline: none;
    position: absolute;
    top: calc(72px - 0.75rem);
    right: 0;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    min-width: 150px;
    display: none;
    a {
      font-size: 16px;
      color: #454545;
      transition: color 0.3s;
      display: flex;
      align-items: center;
      padding: 12px 10px;
      font-weight: 400;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      + a {
        border-top: 1px solid #dddddd;
      }
      i {
        font-size: 17px;
        color: #002c6c;
        transition: color 0.3s;
        margin-right: 20px;
        font-weight: bold;
      }
      &:hover {
        color: #f26334;
        i {
          color: #f26334;
        }
      }
    }
    &.active {
      display: block;
    }
  }
}
</style>

<template>
  <div id="login-form">
    <div class="row justify-content-center">
      <div class="col-xl-3 col-lg-5 col-md-8 col-sm-10">
        <Card :loading="loading.login">
          <template #default>
            <Form
              v-slot="{ errors }"
              :validation-schema="validationSchema"
              @submit="loginAttempt"
            >
              <div class="form-group">
                <div class="input-group mb-3">
                  <Field
                    v-model="login"
                    as="input"
                    name="login"
                    class="form-control"
                    placeholder="Логин"
                    autofocus
                    :class="{ 'is-invalid': errors.login }"
                  />

                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-user" />
                    </div>
                  </div>

                  <ErrorMessage name="login" class="error invalid-feedback" />
                </div>
              </div>

              <div class="input-group mb-3">
                <Field
                  v-model="password"
                  as="input"
                  name="password"
                  type="password"
                  class="form-control"
                  placeholder="Пароль"
                  :class="{ 'is-invalid': errors.password }"
                />

                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock" />
                  </div>
                </div>

                <ErrorMessage name="password" class="error invalid-feedback" />
              </div>

              <span
                v-if="authError"
                class="error invalid-feedback mb-3"
                style="display: block"
              >
                Неверный логин или пароль
              </span>

              <button
                type="submit"
                class="btn btn-primary btn-sm btn-block"
                @click="authError = false"
              >
                Войти
              </button>
            </Form>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: 'LoginForm',
  components: {
    Card,
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    /**
     * Логин
     */
    const login: Ref<string> = ref('');

    /**
     * Пароль
     */
    const password: Ref<string> = ref('');

    /**
     * Флаг ошибки авторизации
     */
    const authError: Ref<boolean> = ref(false);

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<Record<string, boolean>> = computed(
      () => store.state.loading
    );

    /**
     * Правила валидации
     */
    const validationSchema: Ref<any> = ref({
      login: yup.string().required('Необходимо указать логин'),
      password: yup.string().required('Необходимо указать пароль'),
    });

    /**
     * Попытка авторизации
     */
    const loginAttempt = async (): Promise<void> => {
      authError.value = false;
      const success = await store.dispatch('auth/loginAttempt', {
        login: login.value,
        password: password.value,
      });

      if (success) {
        await store.dispatch('auth/getUser', true);
        await store.dispatch('auth/refreshAccess');

        store.dispatch('notifications/getLastNotifications');
        store.dispatch('notifications/getNewNotifications');
        store.dispatch('refreshMenu');
        router.push({ name: 'statistics-dashboard' });
      } else {
        authError.value = true;
      }
    };

    onMounted(() => {
      store.commit('setH1', 'Вход в систему');
    });

    return {
      login,
      loading,
      password,
      authError,
      validationSchema,
      loginAttempt,
    };
  },
});
</script>

<template>
  <div class="page__api-access">
    <GS1TableFlat
      v-bind="{
        fields: tableFields,
        data: apiList,
        linkable: true,
        showRange: false,
        showHeader: false,
        showFooter: false,
        infinityScroll: true,
      }"
    >
      <template #code="slot">
        <GS1Icon
          v-if="user.gs1ApiAccess(slot.item.code) == 1"
          class="description__active-icon"
          title="Без доступа"
          color="#ff3607"
          icon="close"
        ></GS1Icon>

        <GS1Icon
          v-if="user.gs1ApiAccess(slot.item.code) == 2"
          class="description__active-icon"
          title="Требуется действие"
          color="#ff3607"
          icon="error"
        ></GS1Icon>

        <GS1Icon
          v-if="user.gs1ApiAccess(slot.item.code) == 3"
          class="description__active-icon"
          title="Доступ разрешён"
          color="#7AC143"
          icon="success"
        ></GS1Icon>

        <GS1Icon
          v-if="user.gs1ApiAccess(slot.item.code) == 4"
          class="description__active-icon"
          title="Доступ отклонён"
          color="#ff3607"
          icon="error"
        ></GS1Icon>

        <GS1Icon
          v-if="user.gs1ApiAccess(slot.item.code) == 5"
          class="description__active-icon"
          title="Доступ закрыт"
          color="#ff3607"
          icon="error"
        ></GS1Icon>
      </template>
      <template #actions="slot">
        <GS1Btn v-if="user.gs1ApiAccess(slot.item.code) == 3" accent class="close-access__button" @click="openDeclineModal(slot.item)">
          Закрыть доступ
        </GS1Btn>
        <GS1Btn
          v-if="[2, 4, 5].indexOf(user.gs1ApiAccess(slot.item.code)) + 1"
          success
          class="close-access__button"
          @click="openAccessModal(slot.item)"
        >
          Открыть доступ
        </GS1Btn>
      </template>
    </GS1TableFlat>
    <GS1Modal :close-outside="true" :active="showCloseAccessModal" @close="closeDeclineModal">
      <Form v-slot="form" as="div" :validation-schema="validationSchema">
        <div class="closeAccess__modal-container">
          <h4 class="closeAccess__text">Укажите причину отклонения</h4>
          <Field v-slot="{ errorMessage }" v-model="comment" name="comment">
            <GS1Textarea
              v-model="comment"
              :error="errorMessage"
              transparent
              placeholder="Укажите причину"
              class="barcode-uploader__gtin-input"
            ></GS1Textarea>
          </Field>
          <div class="closeAccess__buttons">
            <GS1Btn no-margin success @click="closeDeclineModal">Отмена</GS1Btn>
            <GS1Btn no-margin accent @click="decline(form.validate)">Отклонить</GS1Btn>
          </div>
        </div>
      </Form>
    </GS1Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import type { GS1TableField } from '@gs1ru/web-components';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import useBus from '@/utils/useBus';

export default defineComponent({
  name: 'UserApiAccess',
  components: {
    Form,
    Field,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const bus = useBus();

    /**
     * Список API
     */
    const apiList: ComputedRef<Array<Record<string, any>>> = computed(() => store.state.vbgApi.apiList);

    /**
     * Список пользователей
     */
    const user: ComputedRef<any> = computed(() => store.state.admin.user);

    const showCloseAccessModal: Ref<boolean> = ref(false);

    const showGetAccessModal: Ref<boolean> = ref(false);

    /**
     * Причина отклонения заявки
     */
    const comment: Ref<string> = ref('');

    const declineItem: Ref<any> = ref(null);

    /**
     * Правила валидации
     */
    const validationSchema: Ref<any> = ref({
      comment: yup.string().required('Необходимо указать причину'),
    });

    /**
     * Поля таблицы
     */
    const tableFields: Array<GS1TableField> = [
      {
        label: 'API',
        field: 'name',
        sort: true,
        width: '500px',
        lineClamp: 1,
      },
      {
        label: 'Статус',
        field: 'code',
        sort: false,
        width: '300px',
        lineClamp: 1,
      },
      {
        label: 'Закрытие доступа',
        field: 'actions',
        sort: false,
        width: '300px',
        lineClamp: 1,
      },
    ];

    const userHash: Ref<string | null> = ref(null);

    const getApiList = () => store.dispatch('vbgApi/getApiList');

    const canChangeApiStatus = (api: any) => store.dispatch('vbgApi/checkLinkHash', { params: { id: api.id, api: api.code }, hash: userHash.value });

    const closeDeclineModal = () => (showCloseAccessModal.value = false);
    const openDeclineModal = async (item: any) => {
      if (!(await canChangeApiStatus(item))) return bus?.emit('confirm', { text: `У вас нет доступа` });
      userHash.value = user.value.tokens[item.code].link_hash;
      showCloseAccessModal.value = true;
      declineItem.value = item;
    };

    /**
     * Подтверждение разрешения
     */
    const decline = (validate: Function) => {
      validate().then(({ valid }: { valid: boolean }) => {
        if (!valid) return;
        store.dispatch('vbgApi/decline', { id: route.params.userId, api: declineItem.value.code, comment: comment.value }).then(() => {
          emit('update');
          closeDeclineModal();
        });
      });
    };

    /**
     * Подтверждение разрешения
     */
    const approve = (item: any) => store.dispatch('vbgApi/approve', { id: route.params.userId, api: item.code }).then(() => emit('update'));

    const openAccessModal = async (item: any) => {
      if (!(await canChangeApiStatus(item))) return bus?.emit('confirm', { text: `У вас нет доступа` });
      userHash.value = user.value.tokens[item.code].link_hash;
      bus?.emit('confirm', { text: `Разрешить пользователю ${user.value?.login || ''} доступ к API?`, confirmText: 'Разрешить' }, () =>
        approve(item)
      );
    };

    getApiList();
    onMounted(async () => {
      userHash.value = String(route.query?.hash) || null;
    });

    return {
      apiList,
      tableFields,
      user,
      showCloseAccessModal,
      showGetAccessModal,
      validationSchema,
      comment,
      decline,
      closeDeclineModal,
      openDeclineModal,
      openAccessModal,
    };
  },
});
</script>
<style lang="scss">
.close-access__button {
  width: 100px;
}
.closeAccess__text {
  margin-bottom: var(--margin-x25);
}
.page__api-access {
  .gs1-element.gs1-modal .gs1-modal__window {
    padding: var(--padding-x55) !important;
  }
  .closeAccess__modal-container {
    .closeAccess__buttons {
      display: flex;
      justify-content: flex-end;
      .gs1-btn + .gs1-btn {
        margin-left: var(--margin-x25);
      }
    }
  }
}
</style>

<template>
  <div id="api-logs">
    <Card :loading="loading['api-logs']" no-padding no-shadow no-border>
      <template #default>
        <Filter
          v-model:search-string="searchString"
          v-model:per-page="perPage"
          v-model:page="page"
          v-model:date-from="dateFrom"
          v-model:date-to="dateTo"
          :total="pagination.total"
          show-date-filter
          @search="getApiLogs"
          @change-per-page="getApiLogs"
          @change-page="getApiLogs"
          @change-date="getApiLogs"
        />

        <table class="table table-striped">
          <thead>
            <tr>
              <td>Метод</td>
              <td>Запрос</td>
              <td>Версия API</td>
              <td>Заголовок</td>
              <td>Тело запроса</td>
              <td>Ответ</td>
              <td>Пользователь</td>
              <td>Результат</td>
              <td>Дата</td>
            </tr>
          </thead>

          <tbody>
            <tr v-for="log in logs.data" :key="log.id">
              <td v-expand>
                {{ log.method }}
              </td>
              <td v-expand>
                {{ log.request }}
              </td>
              <td v-expand>
                {{ log.version }}
              </td>
              <td v-expand>
                {{ log.headers }}
              </td>
              <td v-expand>
                {{ log.data }}
              </td>
              <td v-expand>
                {{ log.response }}
              </td>
              <td v-expand>
                {{ log.user ? log.user.login : '' }}
              </td>
              <td>
                <i v-if="log.success" class="fas fa-check text-success" />
                <i v-if="!log.success" class="fas fa-times text-danger" />
              </td>
              <td>
                {{ log.date }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import Filter from '@/components/Filter.vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'ApiLogs',
  components: {
    Card,
    Filter,
  },
  setup() {
    const store = useStore();

    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);

    /**
     * Кол-во элементов на страницу
     */
    const perPage: Ref<number> = ref(10);

    /**
     * Поисковой запрос
     */
    const searchString: Ref<string> = ref('');

    /**
     * Дата (от) для фильтра
     */
    const dateFrom: Ref<any> = ref(dayjs().subtract(1, 'month').toDate());

    /**
     * Дата (до) для фильтра
     */
    const dateTo: Ref<Date> = ref(new Date());

    /**
     * Флаги загрузки
     */
    const loading: Ref<any> = computed(() => store.state.loading);

    /**
     * Список логов
     */
    const logs: ComputedRef<any> = computed(() => {
      const logs = store.state.statistics.apiLogs;

      if (typeof logs.data !== 'undefined') {
        logs.data = logs.data.map((log: Record<string, any>) => {
          log.date = dayjs(log.created_at).format('DD.MM.YYYY HH:mm');

          return log;
        });
      }

      return logs;
    });

    /**
     * Данные о постраничной навигации логов
     */
    const pagination: ComputedRef<any> = computed(() => store.state.statistics.apiLogPagination);

    /**
     * Получение списка GTIN
     */
    const getApiLogs = (): void => {
      store.dispatch('statistics/getApiLogs', {
        page: page.value,
        perPage: perPage.value,
        type: 'vbg_api',
        search: searchString.value,
        dateFrom: dateFrom.value ? dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00') : dateFrom.value,
        dateTo: dateTo.value ? dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59') : dateTo.value,
      });
      store.dispatch('statistics/getApiLogPagination', {
        perPage: perPage.value,
        search: searchString.value,
        type: 'vbg_api',
        dateFrom: dateFrom.value ? dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00') : dateFrom.value,
        dateTo: dateTo.value ? dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59') : dateTo.value,
      });
    };

    onMounted(() => {
      getApiLogs();
      store.commit('setH1', 'Журнал запросов API | Пользователи API');
      store.commit('setNoPadding', true);
    });

    return {
      page,
      perPage,
      searchString,
      dateFrom,
      dateTo,
      loading,
      logs,
      getApiLogs,
      pagination,
    };
  },
});
</script>

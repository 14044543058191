import { Store } from 'vuex';
import store from './store';
import LoginForm from './views/LoginForm.vue';
import OAuth from './views/OAuth.vue';
import config from '@/config';

export default class AuthModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.store.registerModule('auth', store);
    this.store.dispatch('auth/getUser').then((success: boolean) => {
      if (success) {
        if (this.router.currentRoute.value.meta.noAuth) {
          this.router.push({ name: 'home' });
        }
      }
    });
  }

  addRoutes(): void {
    this.router.addRoute({ path: '/auth/login', component: LoginForm, name: 'login', meta: { noAuth: true } });
    this.router.addRoute({ path: config.oauthRedirectUrl, component: OAuth, name: 'oauth' });
  }
}

import axios from 'axios';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Список логов запросов GTIN
       */
      gtinLogs: [],

      /**
       * Данные о постраничной навигации логов GTIN
       */
      gtinLogPagination: {
        total: 0,
        last_page: 1,
      },

      /**
       * Список пользователей из логов
       */
      gtinLogUsers: [],

      /**
       * Данные о постраничной навигации логов пользователей
       */
      gtinLogUserPagination: {
        total: 0,
        last_page: 1,
      },

      /**
       * Статистика запросов по GTIN
       */
      stats: {
        total: '',
        gtins: [],
        users: [],
        apiUsers: [],
      },

      /**
       * Статистика запросов к API
       */
      apiLogs: [],

      /**
       * Данные о постраничной навигации логов API
       */
      apiLogPagination: {
        total: 0,
        last_page: 1,
      },
    };
  },
  mutations: {
    /**
     * Устанавливает загруженные логи
     *
     * @param state
     * @param logs Список логов
     */
    setGtinLogs(state: Record<string, any>, logs: Array<Record<string, any>>): void {
      state.gtinLogs = logs;
    },

    /**
     * Данные о постраничной навигации логов GTIN
     * @param state
     * @param logs
     */
    setGtinLogPagination(state: Record<string, any>, data: Record<string, number>): void {
      state.gtinLogPagination.total = data.total;
      state.gtinLogPagination.last_page = data.last_page;
    },

    /**
     * Устанавливает массив пользователей
     *
     * @param state
     * @param logs Список пользователей
     */
    setGtinLogUsers(state: Record<string, any>, users: Array<Record<string, any>>): void {
      state.gtinLogUsers = users;
    },

    /**
     * Данные о постраничной навигации логов GTIN
     * @param state
     * @param logs
     */
    setGtinLogUserPagination(state: Record<string, any>, data: Record<string, number>): void {
      state.gtinLogUserPagination.total = data.total;
      state.gtinLogUserPagination.last_page = data.last_page;
    },

    /**
     * Устанавливает статистику по запросам
     *
     * @param state
     * @param data Статистика, type - вид статистики, stats - данные
     */
    setStats(state: Record<string, any>, data: Record<string, any>): void {
      state.stats[data.type] = data.stats;
    },

    /**
     * Устанавливает логи API
     *
     * @param state
     * @param logs Список логов
     */
    setApiLogs(state: Record<string, any>, logs: Array<Record<string, any>>): void {
      state.apiLogs = logs;
    },

    /**
     * Данные о постраничной навигации логов API
     * @param state
     * @param logs
     */
    setApiLogPagination(state: Record<string, any>, data: Record<string, number>): void {
      state.apiLogPagination.total = data.total;
      state.apiLogPagination.last_page = data.last_page;
    },
  },
  actions: {
    /**
     * Получение логов GTIN
     *
     * @param context
     */
    async getGtinLogs({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'gtin-logs', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('gtin-logs?' + query);
        commit('setGtinLogs', response.data);
      } catch (error) {
        commit('setGtinLogs', []);
      } finally {
        commit('setLoading', { flagName: 'gtin-logs', flagState: false }, { root: true });
      }
    },

    /**
     * Получение постраничной навигации логов GTIN
     *
     * @param context
     */
    async getGtinLogPagination({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('gtin-logs/pagination?' + query);
        commit('setGtinLogPagination', response.data);
      } catch (error) {
        commit('setGtinLogPagination', { total: 0, last_page: 1 });
      }
    },

    /**
     * Получение логов запросов к API
     *
     * @param context
     * @param gtinNumbers Список номеров GTIN в виде массива
     */
    async getGtinLogUsers({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'gtin-logs', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('gtin-logs/users?' + query);
        commit('setGtinLogUsers', response.data);
      } catch (error) {
        commit('setGtinLogUsers', []);
      } finally {
        commit('setLoading', { flagName: 'gtin-logs', flagState: false }, { root: true });
      }
    },

    /**
     * Получение постраничной навигации логов GTIN
     *
     * @param context
     */
    async getGtinLogUserPagination({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('gtin-logs/users/pagination?' + query);
        commit('setGtinLogUserPagination', response.data);
      } catch (error) {
        commit('setGtinLogUserPagination', { total: 0, last_page: 1 });
      }
    },

    /**
     * Получение логов запросов
     *
     * @param context
     * @param gtinNumbers Список номеров GTIN в виде массива
     */
    async getApiLogs({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'api-logs', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('system-logs?' + query);
        commit('setApiLogs', response.data);
      } catch (error) {
        commit('setApiLogs', []);
      } finally {
        commit('setLoading', { flagName: 'api-logs', flagState: false }, { root: true });
      }
    },

    /**
     * Получение постраничной навигации логов запросов
     *
     * @param context
     */
    async getApiLogPagination({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      try {
        const query = new URLSearchParams(data);
        const response = await axios.get('system-logs/pagination?' + query);
        commit('setApiLogPagination', response.data);
      } catch (error) {
        commit('setApiLogPagination', { total: 0, last_page: 1 });
      }
    },

    /**
     * Получение общего кол-ва запросов по GTIN
     */
    async getTotalStats({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'stats-total', flagState: true }, { root: true });

      try {
        const response = await axios.get('gtin-logs/stats/total?dateFrom=' + data.dateFrom + '&dateTo=' + data.dateTo);
        commit('setStats', { type: 'total', stats: response.data });
      } catch (error) {
        commit('setStats', { type: 'total', stats: '' });
      } finally {
        commit('setLoading', { flagName: 'stats-total', flagState: false }, { root: true });
      }
    },

    /**
     * Получение топ 5 пользователей по кол-ву запросов
     */
    async getUsersStats({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'stats-users', flagState: true }, { root: true });

      try {
        const response = await axios.get('gtin-logs/stats/users?dateFrom=' + data.dateFrom + '&dateTo=' + data.dateTo);
        commit('setStats', { type: 'users', stats: response.data });
      } catch (error) {
        commit('setStats', { type: 'users', stats: [] });
      } finally {
        commit('setLoading', { flagName: 'stats-users', flagState: false }, { root: true });
      }
    },

    /**
     * Получение топ 5 GTIN по кол-ву запросов
     */
    async getGtinStats({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'stats-gtins', flagState: true }, { root: true });

      try {
        const response = await axios.get('gtin-logs/stats/gtin?dateFrom=' + data.dateFrom + '&dateTo=' + data.dateTo);
        commit('setStats', { type: 'gtins', stats: response.data });
      } catch (error) {
        commit('setStats', { type: 'gtins', stats: [] });
      } finally {
        commit('setLoading', { flagName: 'stats-gtins', flagState: false }, { root: true });
      }
    },

    /**
     * Получение топ 5 пользователей по запросам к API
     */
    async getApiUsersStats({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'stats-api-users', flagState: true }, { root: true });

      try {
        const response = await axios.get('system-logs/stats/api-users?dateFrom=' + data.dateFrom + '&dateTo=' + data.dateTo);
        commit('setStats', { type: 'apiUsers', stats: response.data });
      } catch (error) {
        commit('setStats', { type: 'apiUsers', stats: [] });
      } finally {
        commit('setLoading', { flagName: 'stats-api-users', flagState: false }, { root: true });
      }
    },
  },
  modules: {},
};

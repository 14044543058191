<template>
  <div class="side-panel" :class="{ active }">
    <div class="side-panel-header d-flex justify-content-end">
      <a href="#" class="btn btn-xs btn-light" @click.prevent="toggle">
        Закрыть
      </a>
    </div>

    <div class="side-panel-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { Ref } from 'vue';

export default defineComponent({
  name: 'SidePanel',
  setup() {
    /**
     * Флаг активности панели
     */
    const active: Ref<boolean> = ref(false);

    /**
     * Переключение состояния панели
     */
    const toggle = () => {
      active.value = !active.value;
    };
    /**
     * Скрытие панели
     */
    const hide = () => {
      active.value = false;
    };
    /**
     * Показ панели
     */
    const show = () => {
      active.value = true;
    };

    return {
      active,
      toggle,
      hide,
      show,
    };
  },
});
</script>

<style lang="scss">
.side-panel {
  z-index: 100;
  position: fixed;
  top: calc(4.375rem + 1px);
  right: 0;
  bottom: 0;
  width: 900px;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 0px 5px rgb(0 0 0 / 25%);
  transform: translate(100%);
  transition: transform 0.3s;
  padding: 1rem;
  overflow: auto;
  &.active {
    transform: translate(0%);
  }

  .side-panel-content {
    padding: 1rem;
  }
}
</style>

<template>
  <ul class="admin-user-tabs nav nav-tabs">
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{ name: 'admin-settings' }"
        class="nav-link"
      >
        Пользователи
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{ name: 'admin-settings-mobile-api' }"
        class="nav-link"
      >
        Мобильное API
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{ name: 'admin-settings-bulk-search' }"
        class="nav-link"
      >
        Задачи поиска GTIN
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SettingsTabs',
});
</script>

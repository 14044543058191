<template>
  <div class="card" :class="classes">
    <div v-if="hasSlot('header')" class="card-header" @click="toggleBody">
      <slot name="header" />
    </div>

    <div class="card-body" :class="{ 'p-0': noPadding }">
      <slot />
    </div>

    <div v-if="loading" class="overlay">
      <i class="fas fa-2x fa-sync-alt fa-spin" />
    </div>

    <div v-if="hasSlot('footer')" class="card-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'Card',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    const minimized = ref<boolean>(true);
    const classes = computed<Record<string, boolean>>(() => {
      const classes: Record<string, boolean> = {};

      classes['card-outline'] = props.outline;
      classes['toggleable'] = props.toggleable;
      classes['minimized'] = minimized.value;
      classes['shadow-sm'] = !props.noShadow;
      classes['no-border'] = props.noBorder;
      classes['card-' + props.type] = true;

      return classes;
    });

    /**
     * Проверка на наличие слота
     *
     * @param name Код слота
     */
    const hasSlot = (name = 'default'): boolean => {
      return !!slots[name];
    };

    /**
     * Переключение состояния тела карточки
     */
    const toggleBody = (): void => {
      minimized.value = !minimized.value;
    };

    return {
      classes,
      minimized,
      hasSlot,
      toggleBody,
    };
  },
});
</script>

<style lang="scss">
.card {
  &.no-border {
    border: 0;
  }
}
.toggleable {
  .card-header {
    cursor: pointer;
  }
  .card-body {
    min-height: initial;
    max-height: auto;
    overflow: hidden;
  }
  &.minimized {
    .card-body {
      max-height: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
</style>

<template>
  <form
    v-if="user.loggedIn && user.membershipStatus"
    id="navbar-search"
    v-click-outside="forceMinimize"
    @submit.prevent="fullSearch"
  >
    <div class="search-input" :class="{ maximized: maximized }">
      <i class="icon-search-2" />

      <input
        ref="searchInput"
        v-model="searchString"
        type="text"
        placeholder="Поиск"
        @input="search"
        @focus="maximize"
        @blur="minimize"
      />
    </div>

    <div v-if="searchResults.length > 0" class="search-results">
      <div
        v-for="searchResult in searchResults"
        :key="searchResult.code"
        :class="{ 'has-results': searchResult.items.length > 0 }"
        class="module"
      >
        <div class="heading" @click="toggleResults(searchResult)">
          <i class="icon-check" />
          {{ searchResult.title }}
        </div>
        <div class="items" :class="{ active: searchResult.active }">
          <router-link
            v-for="(searchItem, key) in searchResult.items"
            :key="key"
            :to="searchItem.url"
            class="item"
            v-html="searchItem.title"
          />
        </div>
      </div>

      <router-link
        class="show-all"
        :to="{ name: 'search', query: { search: searchString } }"
      >
        Подробнее
      </router-link>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch, ref } from 'vue';
import type { ComputedRef, Ref, WritableComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import User from '@/modules/auth/models/User';

export default defineComponent({
  name: 'NavbarSearch',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const searchInput: Ref<InstanceType<any> | null> = ref(null);

    /**
     * Строка поиска
     */
    const searchString: WritableComputedRef<string> = computed({
      get: () => store.state.search.navbarSearchString,
      set: (value: string) =>
        store.commit('search/setNabvarSearchString', value),
    });

    /**
     * Увеличенный размер поля ввода запроса
     */
    const maximized: Ref<boolean> = ref(false);

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Массив результатов поиска
     */
    const searchResults: ComputedRef<Array<Record<string, any>>> = computed(
      () => {
        return store.state.search.quickSearchResults
          .filter((searchResult: Record<string, any>) => {
            return searchResult.items.length > 0;
          })
          .map((searchResult: Record<string, any>) => {
            searchResult.active = true;

            return searchResult;
          });
      }
    );

    /**
     * Поиск
     */
    const search = (): void => {
      store.dispatch('search/search', { search: searchString, quick: true });
    };

    /**
     * Увеличение размера поля поискового запроса
     */
    const maximize = (): void => {
      maximized.value = true;
    };

    /**
     * Уменьшение размера поля поискового запроса
     */
    const minimize = (): void => {
      if (searchString.value.length == 0) {
        maximized.value = false;
      }
    };

    /**
     * Уменьшение размера поля поискового запроса без проверки длины поискового запроса
     */
    const forceMinimize = (): void => {
      maximized.value = false;
    };

    /**
     * Раскрытие списка результатов модуля
     */
    const toggleResults = (result: Record<string, any>): void => {
      result.active = !result.active;
    };

    /**
     * Переадресация пользователя на страницу поиска
     */
    const fullSearch = (): void => {
      router.push({ name: 'search', query: { search: searchString.value } });
      searchInput.value.blur();
    };

    onMounted(() => store.dispatch('auth/getUser'));
    watch(
      () => route,
      () => (maximized.value = false)
    );

    return {
      searchInput,
      searchString,
      maximized,
      user,
      searchResults,
      search,
      maximize,
      minimize,
      forceMinimize,
      toggleResults,
      fullSearch,
    };
  },
});
</script>

<style lang="scss">
#navbar-search {
  margin-right: 39px;
  width: 214px;
  height: 40px;
  position: relative;
  .search-input {
    width: 214px;
    height: 40px;
    position: absolute;
    right: 0;
    transition: width 0.2s;
    &.maximized {
      width: 700px;
      input {
        width: 700px;
        border-color: #002c6c;
      }
      + .search-results {
        display: flex;
      }
    }
  }
  i {
    position: absolute;
    color: #b1b3b3;
    font-size: 16px;
    height: 16px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 12px;
    z-index: 1;
  }
  input {
    height: 40px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border: 1px solid #b1b3b3;
    padding-left: 40px;
    width: 214px;
    outline: none;
    transition: width 0.2s;
    position: absolute;
    right: 0;
  }
  .search-results {
    position: absolute;
    top: 40px;
    right: 0;
    padding: 30px 60px 15px;
    color: #b1b3b3;
    display: none;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 700px;
    .module {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      &.has-results {
        .heading i {
          color: #7ac143;
        }
      }
      .heading {
        position: relative;
        border-bottom: 1px solid;
        margin-bottom: 10px;
        padding-bottom: 4px;
        font-size: 14px;
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
          color: #a1a3a3;
        }
        i {
          position: absolute;
          color: #b1b3b3;
          left: -40px;
          top: -6px;
          font-size: 19px;
        }
      }
      .items {
        display: flex;
        flex-direction: column;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        overflow: hidden;
        &.active {
          max-height: 3000px !important;
          transition: max-height 1s ease-in-out;
        }
      }
      .item {
        color: #b1b3b3;
        .highlight {
          color: #002c6c;
        }
        &:hover {
          color: #a1a1a3;
        }
        + .item {
          margin-top: 20px;
        }
      }
      + .module {
        margin-top: 14px;
      }
    }
  }
  .show-all {
    align-self: center;
    color: #002c6c;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
  }
}
</style>

import { Store } from 'vuex';
import store from './store';
import UserProfile from './views/UserProfile.vue';
//import UserMobileSettings from './views/UserMobileSettings.vue';

export default class GtinSearchModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.store.registerModule('user', store);
  }

  addRoutes(): void {
    this.router.addRoute({ path: '/user/profile', component: UserProfile, name: 'user-profile', meta: { checkAccess: ['access-user-profile'] } });
    //this.router.addRoute({ path: '/user/settings', component: UserMobileSettings, name: 'user-settings', meta: { checkAccess: ['access-user-settings'] } });
  }
}
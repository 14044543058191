<template>
  <div id="company-search-form" :class="classes">
    <Form v-slot="{ validate }" class="search-form" :class="{ loading: loading.gtin }" as="div">
      <div class="info-search-form__errors">
        <GS1Modal v-for="(searchError, i) in searchErrors" :key="i" :active="true" initial @close="closeSearchError(i)">
          <template #default>
            <div class="info-search-form__errors__container">
              <span class="info-search-form__errors__container__header">{{ searchError.header }}</span>
              <span class="font-gray display-secondary info-search-form__errors__container__body">
                {{ searchError.message.replace(/3\sсек\.?$/, '') }}
                <b v-if="searchError.code === 'E002'" class="info-search-form__errors__container__cooldown">{{ limits.requestCooldown }}сек</b>
              </span>
              <span v-if="searchError.code === 'E001'" class="info-search-form__errors__container__footer">
                <span class="font-gray">{{ searchValueList.length }} /</span> {{ limits.maxSearchValues }}
              </span>
            </div>
          </template>
        </GS1Modal>
      </div>

      <GS1Curtain
        v-model:active="searchFormActive"
        class="info-search-form__left-container"
        :toggleable="searchFormTogglable"
        :width="260"
        toggle-tooltip="Ввод"
        big-toggle
      >
        <template #default>
          <div class="form-heading">
            <h1 class="info-search-form__left-container__header-text" v-html="formHeading"></h1>
            <GS1Select v-if="searchType == 'key'" v-model="searchKey" no-margin :error="searchKeyError">
              <GS1Option v-for="(key, i) in companyKeys" :key="i" :value="key.value">
                {{ key.value }}
                <span class="key-description">{{ key.description }}</span>
              </GS1Option>
            </GS1Select>
          </div>

          <GS1InputCheck v-if="searchType == 'inn'" v-model="showBranches" no-margin class="show-branches-checkbox">
            Включая подразделения
          </GS1InputCheck>

          <GS1Textarea v-model="searchValues" placeholder="Ввод списка" class="barcode-uploader__gtin-input" transparent>
            <template #actions>
              <GS1BtnIcon :icon="['info']" transparent small @click="toggleInfo"></GS1BtnIcon>
              <GS1BtnIcon v-if="searchValueList.length" :icon="['trash']" transparent small @click="resetSearchFields"></GS1BtnIcon>
              <GS1Modal ref="infoModal" v-model:active="activeInfo" absolute width="280px" class="barcode-uploader__info" @close="toggleInfo">
                <template #default>
                  <div class="barcode-uploader__info__heading">Тех. требования к запросу</div>
                  <div class="circle-progress-bar__container barcode-uploader__info__value">
                    <GS1ProgressBarCircle class="circle-progress-bar__circle-element" :value="limitPercent">
                      <div class="circle-progress-bar__text font-gray">
                        Лимит значений в одном запросе:
                        <span class="font-black">
                          {{
                            searchValueList.length >= parseInt(limits.maxSearchValues)
                              ? 'Лимит превышен'
                              : parseInt(limits.maxSearchValues) - searchValueList.length
                          }}
                        </span>
                      </div>
                    </GS1ProgressBarCircle>
                  </div>
                  <div class="font-gray barcode-uploader__info__value">
                    <GS1Icon icon="time" tiny></GS1Icon>
                    Частота запроса:&nbsp;<span class="font-black"> {{ limits.requestCooldown }} сек</span>
                  </div>
                </template>
              </GS1Modal>
            </template>
          </GS1Textarea>

          <div class="info-search-form__actions">
            <div class="actions__text-container">
              <div class="info-search-form__example">
                <span class="font-gray">
                  Пример запроса:
                  <a class="font-black display-bold info-search-form__example__gtin" href="#" @click.prevent="searchExample(searchExampleValue)">
                    {{ searchExampleValue }}
                  </a>
                </span>
              </div>
            </div>
            <GS1Btn
              style="width: 100%"
              no-margin
              primary
              :disabled="parseInt(limits.maxSearchValues) == 0 || searchValues.length == 0"
              @click="search(validate)"
            >
              Искать
            </GS1Btn>
          </div>
        </template>
        <template #toggle>
          <GS1Icon icon="keyboard"></GS1Icon>
          Ввести
        </template>
      </GS1Curtain>
      <CompanyResults
        ref="companyResults"
        :search-key="searchKeyValue"
        :template-description="modalDescription.template"
        :loader-description="modalDescription.loader"
        :table-title="modalDescription.title"
        :table-column-title="modalDescription.columnTitle"
      ></CompanyResults>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, onUnmounted, watch, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Form } from 'vee-validate';
import CompanyResults from '../components/CompanyResults.vue';
import User from '@/modules/auth/models/User';

export default defineComponent({
  name: 'CompanySearch',
  components: { Form, CompanyResults },
  emits: ['check'],
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    /**
     * Компонент с результатами поиска
     */
    const companyResults: Ref<any> = ref(null);

    /**
     * Окно с информацией
     */
    const infoModal: Ref<any> = ref(null);

    /**
     * Активность окна с информацией
     */
    const activeInfo: Ref<boolean> = ref(false);

    /**
     * Активность формы ввода значений поиска
     */
    const searchFormActive: Ref<boolean> = ref(true);

    /**
     * Возможность раскрыть форму ввода значений поиска
     */
    const searchFormTogglable: Ref<boolean> = ref(false);

    /**
     * Значения для поиска
     */
    const searchValues: Ref<string> = ref('');

    /**
     * Искать по подразделениям (для поиска по ИНН)
     */
    const showBranches: Ref<boolean> = ref(false);

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    const companyKeys: Ref<Record<string, string>[]> = ref([
      { value: 'GIAI', description: 'Global Individual Asset Identifier, Глобальный идентификатор узла, до 30 алфавитно-цифровых символов' },
      { value: 'SSCC', description: 'Serial Shipping Container Code, Серийный код транспортной упаковки,18 цифр' },
      { value: 'GSIN', description: 'Global Shipment Identification Number, глобальный идентификационный номер отправки груза, 17 цифр' },
      {
        value: 'GINC',
        description: 'Global Identification Number for Consignment, глобальный идентификатор номера товара, до 30 алфавитно-цифровых символов',
      },
      { value: 'GRAI', description: 'Global Returnable Asset Identifier, Глобальный номер возвратного актива, до 30 алфавитно-цифровых символов' },
      { value: 'GSRN', description: 'Global Service Relation Number, Глобальный номер услуг, 18 цифр' },
      { value: 'GDTI', description: 'Global Document Type Identifier, Глобальный Номер Документа, до 30 алфавитно-цифровых символов' },
      { value: 'GCN', description: 'Global Coupon Number, Глобальный Номер Купона, до 25 цифр' },
      { value: 'CPID', description: 'Component/Part Identifier, Номер Компонента/Детали, до 30 алфавитно-цифровых символов' },
      { value: 'GMN', description: 'Global Model Number, Глобальный Номер Модели до 25 алфавитно-цифровых символов' },
    ]);

    /**
     * Тип поиска компаний (по ключу, ИНН, GLN)
     */
    const searchType: ComputedRef<string> = computed(() => {
      switch (route.name) {
        case 'company-search-by-gln':
        case 'company-search-by-gln-result':
          return 'gln';
        case 'company-search-by-key':
        case 'company-search-by-key-result':
          return 'key';
        case 'company-search-by-inn':
        case 'company-search-by-inn-result':
          return 'inn';
        default:
          return '';
      }
    });

    const formHeading: ComputedRef<string> = computed(() => {
      switch (searchType.value) {
        case 'gln':
          return 'Поиск по GLN';
        case 'inn':
          return 'Поиск по ИНН';
        case 'key':
          return 'Поиск по ключу <span class="red">*</span>';
        default:
          return '';
      }
    });

    /**
     * Ключ, по которому выполняется поиск
     */
    const searchKey: Ref<string> = ref('');

    /**
     * Ошибка ключа
     */
    const searchKeyError: Ref<string> = ref('');

    const searchKeyValue: ComputedRef<string> = computed(() => {
      switch (searchType.value) {
        case 'key':
          return searchKey.value;
        default:
          return searchType.value;
      }
    });

    /**
     * Пример для поиска
     */
    const searchExampleValue: ComputedRef<string> = computed(() => {
      switch (searchKeyValue.value) {
        case 'inn':
          return '7736207543';
        case 'gln':
          return '4600007999992';
        default:
          return '546000070123456780';
      }
    });

    /**
     * Массив GTIN
     */
    const searchValueList: ComputedRef<Array<string>> = computed(() =>
      searchValues.value
        .replaceAll(/[.,;\s]/g, '\n')
        .split('\n')
        .filter((i: string) => !!i)
    );

    /**
     * Массив GTIN для поиска с уникальными значениями
     */
    const uniqueSearchValueList: ComputedRef<Array<string>> = computed(() => {
      const valueArray = searchValueList.value.filter((value: string) => value.length > 0);
      return [...new Set(valueArray)];
    });

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Лимиты запросов
     */
    const limits: ComputedRef<Record<string, string>> = computed(() => store.state.companySearch.limits);

    /**
     * Массив ошибок поиска
     */
    const searchErrors: Ref<Array<Record<string, any>>> = ref([]);

    /**
     * Настройки
     */
    const settings: ComputedRef<any> = computed(() => store.state.admin.settings);

    const limitPercent: ComputedRef<number> = computed(() =>
      Math.ceil((searchValueList.value.length * 100) / parseInt(limits.value.maxSearchValues))
    );

    onMounted(() => {
      store.dispatch('companySearch/getLimits');
      store.dispatch('auth/getUser');
      store.dispatch('admin/getSettings');
    });

    onUnmounted(() => {
      store.commit('companySearch/setCompanies', []);
    });

    /**
     * Экспорт списка компаний
     *
     * @param all Скачать все результаты, а не только выбранные
     */
    const exportCompanies = (): void => {
      store
        .dispatch('companySearch/export', {
          searchValues: searchValues.value,
        })
        .then(() => {
          store.commit('setOverlay', false);
        });
    };

    /**
     * Сброс указанных значений поиска
     */
    const resetSearchFields = (): void => {
      router.push({ name: 'company-search-by-' + searchType.value });
      searchValues.value = '';
    };

    const disabledTextarea = ref(searchValueList.value.length >= parseInt(limits.value.maxSearchValues));

    const modalDescription = computed(() => {
      switch (searchType.value) {
        case 'inn':
          return {
            template: 'Здесь будут результаты поиска по введенным ИНН',
            loader: 'Загружаем результаты поиска по введенным ИНН',
            title: 'Поиск по ИНН',
            columnTitle: 'ИНН',
          };
        case 'gln':
          return {
            template: 'Здесь будут результаты поиска по введенным GLN',
            loader: 'Загружаем результаты поиска по введенным GLN',
            title: 'Результаты поиска по GLN',
            columnTitle: 'GLN',
          };
        case 'key':
          return {
            template: 'Здесь будут результаты поиска по введенным ключам',
            loader: 'Загружаем результаты поиска по введенным ключам',
            title: 'Результаты поиска по ключам',
            columnTitle: 'Выбранный ключ',
          };
        default:
          return {
            template: 'Здесь будут результаты поиска по введенным ключам',
            loader: 'Загружаем результаты поиска по введенным ключам',
            title: 'Результаты поиска по ключам',
            columnTitle: 'Выбранный ключ',
          };
      }
    });

    /**
     * Преобразования значения списка
     */
    const transformGtinList = (): void => {
      searchValues.value = uniqueSearchValueList.value.join('\n');
    };

    /**
     * Пример поиска
     */
    const searchExample = (exampleGtin: string): void => {
      searchKey.value = 'SSCC';
      searchValues.value = exampleGtin;
    };

    /**
     * Поиск компаний
     */
    const search = (): void => {
      searchKeyError.value = '';
      if (searchValues.value.length == 0 || !searchKeyValue.value) {
        searchKeyError.value = 'Выберите ключ';
        return;
      }

      router.push({ name: 'company-search-by-' + searchType.value });

      transformGtinList();

      companyResults.value.reset();
      companyResults.value.showLoader();

      store
        .dispatch('companySearch/searchCompanies', {
          searchKey: searchKeyValue.value.toLowerCase(),
          searchValues: uniqueSearchValueList.value,
          showBranches: showBranches.value,
        })
        .then((result: Record<string, any>) => {
          if (!result.success) {
            searchErrors.value.push({
              header: result.message_header,
              code: result.error_code,
              message: result.message,
            });
          } else {
            searchFormTogglable.value = true;
            searchFormActive.value = false;
          }
        })
        .finally(() => {
          companyResults.value.hideLoader();
        });
    };

    // Открытие/закрытие окна с информацией
    const toggleInfo = (e: Event) => {
      activeInfo.value = !activeInfo.value;

      if (typeof e !== 'undefined') {
        let toggleInfoButton: HTMLElement | null = e.target as HTMLElement;
        if (!toggleInfoButton.classList.contains('gs1-btn-icon')) {
          toggleInfoButton = toggleInfoButton.closest('.gs1-btn-icon');
        }

        if (toggleInfoButton && infoModal.value) {
          const toggleButtonOffsets = toggleInfoButton.getBoundingClientRect();
          const topOffset = toggleButtonOffsets.top + 43;
          const leftOffset = toggleButtonOffsets.left - 111;
          const infoModalWindow = infoModal.value.$el.querySelector('.gs1-modal__window');
          if (infoModalWindow) {
            infoModalWindow.style.left = leftOffset + 'px';
            infoModalWindow.style.top = topOffset + 'px';
          }
        }
      }
    };

    // Закрытие ошибки поиска
    const closeSearchError = (index: number) => {
      searchErrors.value.splice(index, 1);
    };

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'info-search-form': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    watch(
      () => searchValues.value,
      () => {
        companyResults.value.reset();
      }
    );

    watch(
      () => searchType.value,
      () => {
        if (searchType.value.length == 0) return;

        companyResults.value.reset();
        resetSearchFields();
        searchFormActive.value = true;
        searchFormTogglable.value = false;
      }
    );

    return {
      searchValues,
      user,
      searchValueList,
      uniqueSearchValueList,
      loading,
      limits,
      limitPercent,
      settings,
      disabledTextarea,
      searchFormActive,
      searchFormTogglable,
      activeInfo,
      infoModal,
      searchErrors,
      classes,
      modalDescription,
      search,
      exportCompanies,
      resetSearchFields,
      transformGtinList,
      searchExample,
      searchExampleValue,
      toggleInfo,
      closeSearchError,
      companyKeys,
      searchKey,
      searchKeyError,
      companyResults,
      formHeading,
      route,
      searchKeyValue,
      searchType,
      showBranches,
    };
  },
});
</script>

<style lang="scss">
#company-search-form {
  .form-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: var(--margin-x3);
    h1 {
      width: 120px;
      flex: 0 0 120px;
      margin: 0;
      .red {
        color: var(--color-main-5);
      }
    }

    .gs1-select__items {
      max-width: 350px !important;
      .gs1-option {
        justify-content: space-between;
        font-weight: 600;
      }
      .key-description {
        flex: 0 0 calc(100% - 60px);
        font-weight: 400;
      }
    }
  }
  .gs1-element.gs1-select.has-error .gs1-select__input {
    border-color: var(--color-main-5);
  }
  .gs1-select__error {
    display: none;
  }
  .show-branches-checkbox {
    margin-bottom: var(--margin-x3);
  }
  &.mode-dark {
    .barcode-uploader__gtin-input {
      .gs1-element.gs1-icon .gs1-icon__icon {
        &:hover {
          background-color: var(--color-main-f);
        }
      }
    }
  }
}
</style>

<template>
  <div id="gtin-logs-dashboard">
    <Filter v-model:date-from="dateFrom" v-model:date-to="dateTo" show-date-filter hide-search hide-pagination @change-date="getStats" />

    <div class="row justify-content-center">
      <div class="col-lg-3">
        <div :class="{ loading: loading['stats-total'] }" class="small-box shadow-sm box-total">
          <div class="inner">
            <h5>Всего запросов</h5>
            <h3>{{ stats.total }}</h3>

            <a class="refresh" href="#" @click.prevent="refresh('total')">
              <i class="fas fa-redo" />
            </a>
          </div>

          <div class="overlay">
            <i class="fas fa-2x fa-sync-alt fa-spin" />
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <div :class="{ loading: loading['stats-users'] }" class="small-box shadow-sm box-users">
          <div class="inner">
            <h5>Активные пользователи</h5>
            <ul class="list-group list-group-flush">
              <li v-for="userStat in stats.users" :key="userStat.user_id" class="list-group-item d-flex justify-content-between align-items-center">
                {{ userStat.user ? userStat.user.login : 'Пользователь не найден' }}
                <div class="badge">
                  {{ userStat.total }}
                </div>
              </li>
            </ul>
            <router-link class="btn btn-primary" :to="{ name: 'statistics-gtin-users' }">
              Подробнее
              <i class="fas fa-arrow-circle-right" />
            </router-link>

            <a class="refresh" href="#" @click.prevent="refresh('users')">
              <i class="fas fa-redo" />
            </a>
          </div>

          <div class="overlay">
            <i class="fas fa-2x fa-sync-alt fa-spin" />
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <div :class="{ loading: loading['stats-gtins'] }" class="small-box shadow-sm box-popular">
          <div class="inner">
            <h5>Популярные GTIN</h5>
            <ul class="list-group list-group-flush">
              <li v-for="gtinStat in stats.gtins" :key="gtinStat.gtin" class="list-group-item d-flex justify-content-between align-items-center">
                {{ gtinStat.gtin.gtin }}
                <div class="badge">
                  {{ gtinStat.total }}
                </div>
              </li>
            </ul>
            <router-link class="btn btn-primary" :to="{ name: 'statistics-gtin' }">
              Подробнее
              <i class="fas fa-arrow-circle-right" />
            </router-link>

            <a class="refresh" href="#" @click.prevent="refresh('gtins')">
              <i class="fas fa-redo" />
            </a>
          </div>

          <div class="overlay">
            <i class="fas fa-2x fa-sync-alt fa-spin" />
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <div :class="{ loading: loading['stats-api-users'] }" class="small-box shadow-sm box-api">
          <div class="inner">
            <h5>Пользователи API</h5>
            <ul class="list-group list-group-flush">
              <li
                v-for="apiUserStat in stats.apiUsers"
                :key="apiUserStat.user_id"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                {{ apiUserStat.user ? apiUserStat.user.login : 'Пользователь не найден' }}
                <div class="badge">
                  {{ apiUserStat.total }}
                </div>
              </li>
            </ul>
            <router-link class="btn btn-primary" :to="{ name: 'statistics-api' }">
              Подробнее
              <i class="fas fa-arrow-circle-right" />
            </router-link>

            <a class="refresh" href="#" @click.prevent="refresh('apiUsers')">
              <i class="fas fa-redo" />
            </a>
          </div>

          <div class="overlay">
            <i class="fas fa-2x fa-sync-alt fa-spin" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import Filter from '@/components/Filter.vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'LogDashboard',
  components: {
    Filter,
  },
  setup() {
    const store = useStore();

    /**
     * Дата (от) для фильтра
     */
    const dateFrom: Ref<Date> = ref(dayjs().subtract(1, 'month').toDate());

    /**
     * Дата (до) для фильтра
     */
    const dateTo: Ref<any> = ref(new Date());

    /**
     * Список пользователей
     */
    const loading: ComputedRef<Record<string, boolean>> = computed(() => store.state.loading);

    /**
     * Объект со статистиками
     */
    const stats: ComputedRef<any> = computed(() => store.state.statistics.stats);

    const getStats = () => {
      const _dateFrom: string = dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00');
      const _dateTo: string = dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59');

      store.dispatch('statistics/getTotalStats', {
        dateFrom: _dateFrom,
        dateTo: _dateTo,
      });
      store.dispatch('statistics/getUsersStats', {
        dateFrom: _dateFrom,
        dateTo: _dateTo,
      });
      store.dispatch('statistics/getGtinStats', {
        dateFrom: _dateFrom,
        dateTo: _dateTo,
      });
      store.dispatch('statistics/getApiUsersStats', {
        dateFrom: _dateFrom,
        dateTo: _dateTo,
      });
    };

    /**
     * Обновление данных блока
     */
    const refresh = (type: string) => {
      const _dateFrom: any = dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00');
      const _dateTo = dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59');

      switch (type) {
        case 'total':
          store.dispatch('statistics/getTotalStats', {
            dateFrom: _dateFrom,
            dateTo: _dateTo,
          });
          break;
        case 'users':
          store.dispatch('statistics/getUsersStats', {
            dateFrom: _dateFrom,
            dateTo: _dateTo,
          });
          break;
        case 'gtins':
          store.dispatch('statistics/getGtinStats', {
            dateFrom: _dateFrom,
            dateTo: _dateTo,
          });
          break;
        case 'apiUsers':
          store.dispatch('statistics/getApiUsersStats', {
            dateFrom: _dateFrom,
            dateTo: _dateTo,
          });
          break;
      }
    };

    onMounted(() => {
      getStats();
      store.commit('setH1', 'Журнал запросов GTIN');
    });

    return {
      dateFrom,
      dateTo,
      loading,
      stats,
      refresh,
      getStats,
    };
  },
});
</script>

<style lang="scss" scoped>
.small-box {
  font-weight: normal;
  border: 1px solid #b1b3b3;
  border-top-width: 6px;
  min-height: 100px;
  &.loading {
    .overlay {
      display: flex;
    }
    .inner .refresh {
      display: none;
    }
  }
  .overlay {
    display: none;
  }
  h3,
  h5 {
    font-weight: normal;
    margin: 0;
  }
  h5 {
    padding-bottom: 8px;
    border-bottom: 1px solid #b1b3b3;
  }
  .inner {
    padding: 24px 17px;
    position: relative;
    .refresh {
      position: absolute;
      bottom: 24px;
      right: 17px;
      height: 12px;
      display: flex;
      &:hover {
        text-decoration: none;
      }
    }
    .list-group-item {
      padding: 0.75rem 0 0;
      color: #007fac;
      .badge {
        font-weight: inherit;
        font-size: inherit;
        color: #454545;
      }
    }
    .btn {
      margin-top: 20px;
    }
  }
}
.box-total {
  background: #002c6c;
  border: 0;
  border-top: 6px solid #ff8200;
  color: #fff;
  .inner {
    padding: 20px 25px;
  }
  h5 {
    font-size: 15px;
    margin-bottom: 10px;
    pading: 0;
    border: 0;
  }
  h3 {
    font-size: 25px;
  }
}
.box-users {
  border-top-color: #22bcb9;
}
.box-popular {
  border-top-color: #bf83b9;
}
.box-api {
  border-top-color: #c4b000;
}
.filter {
  margin-bottom: 10px;
  position: static;
}
</style>

<template>
  <ul class="admin-company-tabs nav nav-tabs">
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{
          name: 'admin-company-edit',
          params: { companyId: $route.params.companyId },
        }"
        class="nav-link"
      >
        Свойства
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{
          name: 'admin-company-settings',
          params: { companyId: $route.params.companyId },
        }"
        class="nav-link"
      >
        Настройки
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        exact-active-class="active"
        :to="{
          name: 'admin-company-users',
          params: { companyId: $route.params.companyId },
        }"
        class="nav-link"
      >
        Пользователи
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompanyTabs',
});
</script>

<template>
  <div id="admin-users" class="admin-users__container">
    <h1 class="display-medium">Пользователи</h1>
    <GS1TableFlat
      v-model:search="searchString"
      v-bind="{
        fields: tableFields,
        data: users.data,
        linkable: true,
        showRange: false,
        showHeader: true,
        showFooter: false,
        infinityScroll: true,
        displayedCount: displayedCount,
        totalCount: users.total,
        loading: lodaData || !!loading['users'],
      }"
      @search="onSearch"
      @search-reset="onSearchReset"
      @record-click="openUser"
      @next-page="onNextPage"
    >
      <template #header>
        <div class="page-header">
          <GS1TableFilter>
            <div class="history__table-filter">
              <p class="history__table-filter__type-title">
                <b>Статус доступа</b>
              </p>
              <GS1Select v-model="apiAccess">
                <GS1Option value="0">Не выбрано</GS1Option>
                <GS1Option value="1">Нет доступа</GS1Option>
                <GS1Option value="2">Есть запрос</GS1Option>
                <GS1Option value="3">Есть доступ</GS1Option>
                <GS1Option value="4">Отклонено</GS1Option>
              </GS1Select>
            </div>
            <template #actions>
              <GS1Btn link min no-margin no-padding @click="onReset">Сбросить</GS1Btn>
              <GS1Btn link min no-margin no-padding @click="getUsers">Применить</GS1Btn>
            </template>
          </GS1TableFilter>
        </div>
      </template>
      <template #after-header>
        <div class="history__under-header__container">
          <GS1TableFilterSelect v-if="apiAccess != 0" v-model="apiAccess" class="history__under-header__no-margin" no-margin label="Тип ошибки">
            <GS1Option value="0">Не выбрано</GS1Option>
            <GS1Option value="1">Нет доступа</GS1Option>
            <GS1Option value="2">Есть запрос</GS1Option>
            <GS1Option value="3">Есть доступ</GS1Option>
            <GS1Option value="4">Отклонено</GS1Option>
          </GS1TableFilterSelect>
        </div>
      </template>
      <template #company="{ item }">
        <span>{{ item?.company?.gln }}</span>
      </template>
      <template #companyNew="{ item }">
        <span>{{ item?.company?.name }}</span>
      </template>
      <template #inn="{ item }">
        <span>{{ item?.company?.inn }}</span>
      </template>
      <template #pro="{ item }">
        <GS1Icon v-if="item.pro" class="description__active-icon" title="Доступ разрешён" color="#7AC143" icon="success"></GS1Icon>
        <GS1Icon v-if="!item.pro" class="description__active-icon" title="Доступ запрещен" color="#ff3607" icon="close"></GS1Icon>
      </template>
    </GS1TableFlat>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { GS1TableField } from '@gs1ru/web-components';
import type { ComputedRef, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AdminUsers',
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);

    /**
     * Кол-во элементов на страницу
     */
    const perPage: Ref<number> = ref(10);

    /**
     * Поисковой запрос
     */
    const searchString: Ref<string> = ref('');

    /**
     * Фильтр по статусу доступа
     */
    const apiAccess: Ref<number> = ref(0);

    const lodaData: Ref<boolean> = ref(false);

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Список пользователей
     */
    const users: ComputedRef<any> = computed(() => {
      return store.state.admin?.users || { data: [], total: 0, per_page: 0, current_page: 0 };
    });

    /**
     * Поля таблицы
     */
    const tableFields: Array<GS1TableField> = [
      { label: 'Логин', field: 'login', sort: false, class: [] },
      { label: 'GLN', field: 'company', sort: false, class: [] },
      { label: 'Предприятие', field: 'companyNew', sort: false, class: [] },
      {
        label: 'ИНН',
        field: 'inn',
        sort: false,
        width: '200px',
        lineClamp: 1,
      },
      {
        label: 'PRO',
        field: 'pro',
        sort: false,
        width: '200px',
        lineClamp: 1,
      },
    ];

    /**
     * Подстчет отображаемых элементов на странице
     */
    const displayedCount: ComputedRef<number> = computed(() => {
      return users.value.total > 10 ? users.value.per_page * page.value : users.value.total;
    });

    /**
     * Получение списка пользователей
     */
    const getUsers = (): void => {
      if (lodaData.value || loading.value['users']) return;
      lodaData.value = true;
      (users.value.data = []),
        store
          .dispatch('admin/getUsers', {
            page: page.value,
            perPage: perPage.value,
            success: 0,
            search: searchString.value,
            apiAccess: apiAccess.value,
          })
          .finally(() => (lodaData.value = false));
    };

    /**
     * Поиск по строке
     */
    const onSearch = (searchValue: string) => {
      searchString.value = searchValue;
      getUsers();
    };

    /**
     * Сброс поиска по строке
     */
    const onSearchReset = () => {
      searchString.value = '';
      getUsers();
    };

    /**
     * Сброс доступа
     */
    const onReset = () => {
      apiAccess.value = 0;
      getUsers();
    };

    /**
     * Открытие карточки с пользователем
     */
    const openUser = (e: Event, user: any) => {
      if (!user?.id) return;
      router.push({ name: 'admin-user', params: { userId: user.id } });
    };

    /**
     * Подгрузка следующей страницы
     */
    const onNextPage = () => {
      if (lodaData.value || loading.value['users'] || displayedCount.value == users.value.total) return;
      page.value++;
      getUsers();
    };

    onMounted(() => {
      getUsers();
      store.commit('setH1', 'Пользователи');
      store.commit('setNoPadding', true);
    });

    return {
      loading,
      users,
      apiAccess,
      searchString,
      perPage,
      page,
      lodaData,
      displayedCount,
      tableFields,
      getUsers,
      onReset,
      openUser,
      onSearch,
      onNextPage,
      onSearchReset,
    };
  },
});
</script>

<style lang="scss">
#admin-users {
  .api {
    i {
      margin: 0 20px;
    }
  }
  .gs1-element.gs1-select .gs1-select__items {
    width: 250px;
  }
}
.admin-users__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .gs1-table-flat__table-tr {
    cursor: pointer;
  }
}
.history__under-header__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 calc(var(--gs1-input-horisontal-margin) * -1) var(--gs1-margin-x25) calc(var(--gs1-input-horisontal-margin) * -1);
}
</style>

<template>
  <div id="user-profile">
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <Card :loading="loading['user']">
          <template #default>
            <div class="heading">Общие данные</div>
            <div class="field">
              <div class="label">Статус членства</div>
              <div class="value">
                {{ user.membershipStatus ? 'Активное' : 'Неактивное' }}
              </div>
            </div>

            <div class="field">
              <div class="label">Дата окончания членства</div>
              <div class="value">
                {{ user.membershipActiveTill }}
              </div>
            </div>

            <div class="heading">Компания</div>
            <div class="field">
              <div class="label">Название</div>
              <div class="value">
                {{ user.companyName }}
              </div>
            </div>

            <div class="field">
              <div class="label">ИНН</div>
              <div class="value">
                {{ user.companyInn }}
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import User from '@/modules/auth/models/User';

export default defineComponent({
  name: 'UserProfile',
  components: {
    Card,
  },
  setup() {
    const store = useStore();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<Record<string, boolean>> = computed(
      () => store.state.loading
    );

    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    onMounted(() => store.dispatch('auth/getUser'));

    return {
      loading,
      user,
    };
  },
});
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  align-items: center;
  padding: 10px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  .label {
    font-weight: bold;
    padding-right: 10px;
    width: 150px;
  }

  + .field {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  + .heading {
    margin-top: 20px;
  }
}

.heading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
</style>

<template>
  <div id="gtin-logs">
    <Card :loading="loading['gtin-logs']" no-padding no-shadow no-border>
      <template #default>
        <Filter
          v-model:search-string="searchString"
          v-model:per-page="perPage"
          v-model:page="page"
          v-model:date-from="dateFrom"
          v-model:date-to="dateTo"
          :total="pagination.total"
          show-date-filter
          @search="getGtinLogs"
          @change-per-page="getGtinLogs"
          @change-page="getGtinLogs"
          @change-date="getGtinLogs"
        />

        <table class="table table-striped">
          <thead>
            <tr>
              <td>GTIN</td>
              <td>Кол-во запросов</td>
              <td>Успешно</td>
              <td>Неуспешно</td>
            </tr>
          </thead>

          <tbody>
            <tr v-for="log in logs" :key="log.gtin_id">
              <td v-expand>
                {{ log.gtin }}
              </td>
              <td>
                {{ log.total }}
              </td>
              <td>
                {{ log.success }}
              </td>
              <td>
                {{ log.no_success }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import Filter from '@/components/Filter.vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'GtinLogs',
  components: {
    Card,
    Filter,
  },
  setup() {
    const store = useStore();

    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);

    /**
     * Кол-во элементов на страницу
     */
    const perPage: Ref<number> = ref(10);

    /**
     * Поисковой запрос
     */
    const searchString: Ref<string> = ref('');

    /**
     * Дата (от) для фильтра
     */
    const dateFrom: Ref<Date> = ref(dayjs().subtract(1, 'month').toDate());

    /**
     * Дата (до) для фильтра
     */
    const dateTo: Ref<Date> = ref(new Date());

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<Record<string, boolean>> = computed(() => store.state.loading);

    /**
     * Список логов
     */
    const logs: ComputedRef<any> = computed(() => store.state.statistics.gtinLogs);

    /**
     * Данные о постраничной навигации логов
     */
    const pagination: ComputedRef<any> = computed(() => store.state.statistics.gtinLogPagination);

    /**
     * Получение списка GTIN
     */
    const getGtinLogs = (): void => {
      if (!loading.value['gtin-logs']) {
        store.dispatch('statistics/getGtinLogs', {
          page: page.value,
          perPage: perPage.value,
          search: searchString.value,
          dateFrom: dateFrom.value ? dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00') : dateFrom.value,
          dateTo: dateTo.value ? dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59') : dateTo.value,
        });
        store.dispatch('statistics/getGtinLogPagination', {
          perPage: perPage.value,
          search: searchString.value,
          dateFrom: dateFrom.value ? dayjs(dateFrom.value).format('YYYY-MM-DD 00:00:00') : dateFrom.value,
          dateTo: dateTo.value ? dayjs(dateTo.value).format('YYYY-MM-DD 23:59:59') : dateTo.value,
        });
      }
    };

    /**
     * Переключение страницы
     */
    const changePage = (newpage: number): void => {
      page.value = newpage;
      getGtinLogs();
    };

    onMounted(() => {
      getGtinLogs();
      store.commit('setH1', 'Журнал запросов GTIN | Популярные GTIN');
      store.commit('setNoPadding', true);
    });

    return {
      page,
      perPage,
      searchString,
      dateFrom,
      dateTo,
      loading,
      logs,
      getGtinLogs,
      changePage,
      pagination,
    };
  },
});
</script>

<template>
  <div id="admin-home" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdminHome',
});
</script>

import { Store } from 'vuex';
import store from './store';
import pusher from './pusher';
import Search from './views/Search.vue';
import MenuLink from '@/models/MenuLink';
import GtinResult from './views/GtinResult.vue';
import GtinResultHeader from './views/GtinResultHeader.vue';

export default class GtinSearchModule {
  router: Record<string, any>;
  store: Store<any>;
  name: string;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
    this.name = 'gtinSearch';
  }

  init() {
    this.addRoutes();
    this.addLinks();
    this.store.registerModule(this.name, store);
    pusher.subscribe();
  }

  addRoutes(): void {
    this.router.addRoute({
      path: '/gtin-search/search',
      component: Search,
      name: 'gtin-search-search',
      meta: { checkAccess: ['search-gtins'], activeMenuRoute: ['gtin-search-search'] },
    });
    this.router.addRoute({
      path: '/gtin-search/search/:gtin',
      components: { default: Search, rightPanel: GtinResult, rightPanelHeader: GtinResultHeader },
      name: 'gtin-search-result',
      meta: { checkAccess: ['search-gtins'], activeMenuRoute: ['gtin-search-search', 'gtin-search-result'] },
    });
    /* this.router.addRoute({
      path: '/gtin-search/search/bulk',
      component: BulkList,
      name: 'gtin-search-bulk',
      meta: { checkAccess: ['bulk-search-gtins'] },
    }); */
  }

  addLinks(): void {
    this.store
      .dispatch(
        'addLink',
        new MenuLink('Поиск по GTIN', { default: 'gtin-search', light: 'gtin-search-l-h', dark: 'gtin-search-d-h' }, 1, {
          name: 'gtin-search-search',
        })
      )
      .then(() => {
        this.store.dispatch('gtinSearch/getActiveBulkResults'); /* .then(() => {
          this.store.dispatch(
            'addLink',
            new MenuLink('Задачи на обработке', {}, 1, { name: 'gtin-search-bulk' }, null, this, BulkSearchMenuBadge, 'bulk')
          );
        }); */
      });
  }
}

import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "side-panel-header d-flex justify-content-end" }
const _hoisted_2 = { class: "side-panel-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["side-panel", { active: _ctx.active }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "#",
        class: "btn btn-xs btn-light",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent"]))
      }, " Закрыть ")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
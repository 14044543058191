<template>
  <div id="admin-settings" class="admin-settings__container">
    <h1 class="admin-settings__container-title display-medium">Настройки</h1>
    <GS1DropDownBlock>
      <template #title>Пользователи</template>
      <template #sub-title>Настройка ограничений</template>
      <CommonSettings />
    </GS1DropDownBlock>
    <GS1DropDownBlock>
      <template #title>Мобильное API</template>
      <template #sub-title>Параметры API мобильного приложения</template>
      <div class="admin-settings__mobile-api-container">
        <div class="input-container">
          <GS1Input
            v-model="settings.mobileApi.freeScans"
            class="admin-settings__api-margin-top"
            no-margin
            v-bind="{ label: 'Кол-во сканирований для неавторизованных пользователей', id: 'VbgMaxGtins', name: 'VbgMaxGtins' }"
          ></GS1Input>
        </div>
        <div class="admin-settings__save-button">
          <GS1Btn style="width: 200px" no-margin primary @click="save">Сохранить</GS1Btn>
        </div>
      </div>
    </GS1DropDownBlock>
    <GS1DropDownBlock>
      <template #title>Задачи поиска GTIN</template>
      <template #sub-title>Настройки фоновых задач поиска по GTIN</template>
      <GS1InputToggle v-model="settings.gtinSearch.bulkSearchActive">Активировать функционал фоновых задач</GS1InputToggle>
    </GS1DropDownBlock>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch, ref } from 'vue';
import type { Ref } from 'vue';
import Toast from '@/models/Toast';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import CommonSettings from '../components/CommonSettings.vue';

export default defineComponent({
  name: 'AdminSettings',
  components: { CommonSettings },
  setup() {
    const store = useStore();
    const route = useRoute();

    const settings: Ref<any> = ref(store.state.admin.settings);

    const save = () => {
      store
        .dispatch('admin/saveSettings', {
          settings: settings.value,
          params: route.params,
        })
        .then((success: boolean) => {
          if (success) {
            store.dispatch('showToast', new Toast('Настройки', 'Настройки успешно сохранены.', 'success'));
          }
        });
    };

    watch(
      () => settings.value.gtinSearch.bulkSearchActive,
      () => save()
    );

    onMounted(() => {
      store.commit('setH1', 'Общие настройки');
      store.dispatch('admin/getSettings', route.params).then(() => (settings.value = store.state.admin.settings));
    });
    return {
      settings,
      save,
    };
  },
});
</script>

<style lang="scss">
.admin-settings__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .admin-settings__container-title {
    margin-bottom: var(--margin-x4);
  }

  .admin-settings__users-container {
    display: flex;
    justify-content: space-between;

    .admin-settings__margin-top {
      margin-top: var(--margin-x35);
    }

    .admin-settings__gtin-search__block {
      width: calc((100% / 2) - 12px);

      .gtin-search__header-title {
        margin-bottom: var(--margin-x4);
      }
    }

    .admin-settings__api__block {
      width: calc((100% / 2) - 12px);

      .api-block__header-title {
        margin-bottom: var(--margin-x4);
      }
    }
  }

  .admin-settings__mobile-api-container {
    display: flex;
    flex-direction: column;

    .admin-settings__api-margin-top {
      margin-top: var(--margin-x2);
      margin-bottom: var(--margin-x4);
    }
  }

  .admin-settings__save-button {
    margin: auto auto 0 0;
  }

  .gs1-element.gs1-dropdown-block .gs1-dropdown-block__base-container {
    width: 100%;
  }
}
</style>

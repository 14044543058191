<template lang="html">
  <div class="page__admin-user">
    <h2 class="display-xl">Пользователь</h2>
    <div class="page__admin-user__header">
      <GS1Tabs>
        <GS1TabsItem :active="toggleMenu.properties" @click="onToggleMenu('properties')">Свойства</GS1TabsItem>
        <GS1TabsItem :active="toggleMenu.settings" @click="onToggleMenu('settings')">Настройки</GS1TabsItem>
        <GS1TabsItem :active="toggleMenu.api" @click="onToggleMenu('api')">Доступ к API</GS1TabsItem>
        <GS1TabsItem :active="toggleMenu.companies" @click="onToggleMenu('companies')">Предприятия</GS1TabsItem>
      </GS1Tabs>
    </div>

    <div v-if="toggleMenu.api" class="page__admin-user__tab-content">
      <UserApiAccess @update="getAminUser"></UserApiAccess>
    </div>

    <div v-if="toggleMenu.properties" class="page__admin-user__tab-content">
      <GS1InputCheck v-model="user.pro">Статус PRO</GS1InputCheck>
      <div class="page__admin-user__tab-content__footer">
        <GS1Btn style="width: 200px" no-margin primary @click="save">Сохранить</GS1Btn>
      </div>
    </div>

    <CommonSettings v-if="toggleMenu.settings" />

    <div v-if="toggleMenu.companies" class="page__admin-user__tab-content">
      <h3>Принадлежность к предприятиям</h3>
      <GS1TableFlat
        v-bind="{
          fields: tableFields,
          data: userCompanies,
          linkable: false,
          showRange: false,
          showHeader: false,
          showFooter: false,
          infinityScroll: false,
        }"
      ></GS1TableFlat>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, computed, ref, watch, onMounted, onUnmounted } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import type EventBus from '@/models/EventBus';
import Toast from '@/models/Toast';
import type { GS1TableField } from '@gs1ru/web-components';
import UserApiAccess from '../components/UserApiAccess.vue';
import dayjs from 'dayjs';
import CommonSettings from '../components/CommonSettings.vue';
import app from '@/main';
export default defineComponent({
  name: 'AdminUser',
  components: { UserApiAccess, CommonSettings },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toggleMenu: Ref<Record<string, boolean>> = ref({
      properties: true,
      settings: false,
      api: false,
      companies: false,
    });
    const onToggleMenu = (name: string) => {
      for (const i in toggleMenu.value) toggleMenu.value[i] = false;
      toggleMenu.value[name] = true;
    };
    const bus: EventBus | undefined = inject('bus');
    const user: ComputedRef<any> = computed(() => store.state.admin.user);
    const userCompanies = computed(() =>
      user.value && user.value?.company
        ? [
            {
              name: user.value?.company?.name || '',
              created_at: user.value?.company?.created_at ? dayjs(user.value?.company.created_at).format('DD.MM.YYYY HH:mm') : '',
            },
          ]
        : []
    );
    const logDate: ComputedRef<Date> = computed(() => store.getters['admin/getLogDate']);

    /**
     * Настройки
     */
    const settings = computed(() => store.state.admin.settings);

    const userQuery = computed(() => route.params?.id);

    /**
     * Поля таблицы
     */
    const tableFields: Array<GS1TableField> = [
      {
        label: 'Предприятие',
        field: 'name',
        sort: false,
        width: '500px',
        lineClamp: 1,
      },
      {
        label: 'Дата вступления',
        field: 'created_at',
        sort: false,
        width: '300px',
        lineClamp: 1,
      },
    ];

    const save = () => {
      store.dispatch('admin/saveUser', user.value).then((success: boolean) => {
        if (success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          app.addAlert({ text: 'Изменения успешно применены', type: 'success', time: 5000 });
          store.dispatch('showToast', new Toast('Пользователь', 'Пользователь успешно сохранен.', 'success'));
        }
      });
    };
    const saveSettings = () => {
      store
        .dispatch('admin/saveSettings', {
          settings: settings.value,
          params: route.params,
        })
        .then((success: boolean) => {
          if (success) {
            store.dispatch('showToast', new Toast('Настройки', 'Настройки успешно сохранены.', 'success'));
          }
        });
    };

    /**
     * Получение данных о пользователе
     */
    const getAminUser = () => store.dispatch('admin/getUser', route.params.userId);

    const onCloseSidePanel = () => {
      router.push({ name: 'admin-users' });
    };

    onMounted(() => {
      // Слушатель события клика на кнопке закрытия правой панели
      if (bus) bus.on('close-right-sidebar', onCloseSidePanel);
    });

    onUnmounted(() => {
      if (bus) bus.off('close-right-sidebar', onCloseSidePanel);
    });
    getAminUser();

    watch(userQuery, () => getAminUser());

    return { user, userCompanies, logDate, toggleMenu, settings, userQuery, tableFields, getAminUser, onToggleMenu, saveSettings, save };
  },
});
</script>

<style lang="scss" scoped>
.gtin-number__element {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e6eef8;

  .element__gtin {
    padding-bottom: var(--padding-x3);
  }
}
.page__admin-user__tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page__admin-user__tab-content__footer {
  margin: auto auto 0 0;
}
.page__admin-user {
  display: flex;
  flex-direction: column;
  height: 100%;
  .gs1-element.gs1-btn-icon-edit {
    margin: auto 0 auto auto;
  }
}
.element {
  padding: 16px 0;
  display: flex;
  border-bottom: 1px solid #e6eef8;

  .element__type {
    width: 190px;
    margin-right: var(--margin-x4);
  }
}
.gtin-size__element {
  padding: var(--padding-x3) 0;
  display: flex;
  border-bottom: 1px solid #e6eef8;

  .element__size {
    width: 190px;
    margin-right: var(--margin-x4);
  }
}
.settings__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .settings__container__side {
    display: flex;
    width: calc(50% - var(--margin-x25));
    flex-direction: column;
  }
}
.log__response {
  display: flex;
  flex-direction: column;
  .response__content-container {
    padding: var(--padding-x4);
    background-color: #fbfdff;
    text-align: left;
    .content-container__text {
      margin: 0;
    }
  }
}
.response__type {
  padding: var(--padding-x3) 0;
}
.page__admin-user__header {
  margin-bottom: var(--margin-x4);
}
</style>
<style lang="scss">
.component__sidebar-right {
  height: calc(100vh - 60px);
}
</style>

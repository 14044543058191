<template>
  <div class="page__admin-api-request">
    <h3 class="admin-api-request__big-margin-bottom">{{ apiRequest.name }}</h3>
    <span class="admin-api-request__medium-margin-bottom font-gray display-bold">Запрос</span>
    <span class="admin-api-request__big-margin-bottom display-bold">{{ apiRequest.request_url }}</span>
    <span class="admin-api-request__medium-margin-bottom font-gray display-bold">Статус</span>
    <div class="input-container">
      <GS1Select v-model="apiRequest.status" class="admin-api-request__big-margin-bottom">
        <GS1Option :value="1">Активный</GS1Option>
        <GS1Option :value="2">Устаревшний</GS1Option>
        <GS1Option :value="3">Неактивный</GS1Option>
      </GS1Select>
    </div>
    <span class="admin-api-request__medium-margin-bottom font-gray display-bold">Название</span>
    <div class="input-container"><GS1Input v-model="apiRequest.name" class="admin-api-request__big-margin-bottom"></GS1Input></div>
    <span class="admin-api-request__medium-margin-bottom font-gray display-bold">Описание</span>
    <div class="input-container"><GS1Input v-model="apiRequest.description" class="admin-api-request__big-margin-bottom"></GS1Input></div>
    <span class="admin-api-request__medium-margin-bottom font-gray display-bold">Тело запроса</span>
    <div class="input-container"><GS1Input v-model="apiRequest.body" class="admin-api-request__big-margin-bottom"></GS1Input></div>
    <span class="admin-api-request__medium-margin-bottom font-gray display-bold">Параметры запроса</span>
    <div class="input-container"><GS1Input v-model="apiRequest.parameters" class="admin-api-request__big-margin-bottom"></GS1Input></div>
    <span class="admin-api-request__medium-margin-bottom font-gray display-bold">Ошибки</span>
    <div class="input-container"><GS1Input v-model="apiRequest.errors" class="admin-api-request__big-margin-bottom"></GS1Input></div>
    <GS1AlertBlock v-if="apiRequest?.restrictions || apiRequest?.restrictions_formatted" class="admin-api-request__content__text-secondary" accent>
      <h3 class="display-medium">Ограничения</h3>
      <span class="admin-api-request__content__text-secondary ubuntu font-gray" v-html="apiRequest.restrictions_formatted"></span>
    </GS1AlertBlock>
    <div class="admin-api-request__save-button">
      <GS1Btn style="width: 200px" no-margin primary @click="save">Сохранить</GS1Btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import type { ComputedRef } from 'vue';
import app from '@/main';

export default defineComponent({
  name: 'AdminApiRequest',
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Объект запроса API
     */
    const apiRequest: ComputedRef<Record<string, string | number>> = computed(() => store.state.vbgApi.apiRequest);

    onMounted(() => {
      store.dispatch('vbgApi/getApiRequestById', route.params.requestId).then(() => {
        store.commit('setH1', apiRequest.value.name);
      });
    });
    const save = () => {
      store.dispatch('vbgApi/saveApiRequest', apiRequest.value).then((success: boolean) => {
        if (success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          app.addAlert({ text: 'Информация о API сохранена', type: 'success', time: 5000 });
        }
      });
    };

    return {
      loading,
      apiRequest,
      save,
    };
  },
});
</script>

<style lang="scss">
.page__admin-api-request {
  display: flex;
  flex-direction: column;
  height: 100%;
  .admin-api-request__content__text-secondary {
    margin-bottom: var(--margin-x4);
  }
  .admin-api-request__save-button {
    margin: auto auto 0 0;
  }
  .admin-api-request__big-margin-bottom {
    margin-bottom: var(--margin-x4);
  }
  .admin-api-request__medium-margin-bottom {
    margin-bottom: var(--margin-x3);
  }
}
</style>

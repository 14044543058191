<template>
  <div id="admin-settings">
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <SettingsTabs />

        <Card :loading="loading.settings">
          <template #default>
            <div class="form-group row mb-2 align-items-center">
              <label class="col-sm-4">Активировать функционал фоновых задач</label>
              <div class="col-sm-8">
                <input id="bulk-search-active" v-model="settings.gtinSearch.bulkSearchActive" class="checkbox" type="checkbox" />
                <label for="bulk-search-active" />
              </div>
            </div>
          </template>

          <template #footer>
            <button class="btn btn-primary" @click="save">Сохранить</button>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Card from '@/components/Card.vue';
import SettingsTabs from '../components/SettingsTabs.vue';
import Toast from '@/models/Toast';

import { defineComponent, computed, onMounted, watch } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AdminSettingsBulkSearch',
  components: {
    Card,
    SettingsTabs,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Настройки
     */
    const settings = computed(() => store.state.admin.settings);

    /**
     * Список пользователей
     */
    const users: ComputedRef<any> = computed(() => store.state.admin.users);

    const save = () => {
      store.dispatch('admin/saveSettings', { settings: settings }).then((success: boolean) => {
        if (success) {
          store.dispatch('showToast', new Toast('Настройки', 'Настройки успешно сохранены.', 'success'));
        }
      });
    };

    onMounted(() => {
      store.dispatch('admin/getSettings');
    });

    watch(
      () => route.name,
      () => store.dispatch('admin/getSettings')
    );

    return {
      settings,
      loading,
      users,
      save,
    };
  },
});
</script>

<style lang="scss"></style>

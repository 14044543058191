<template>
  <div id="admin-settings">
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <UserTabs />

        <CommonSettings />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import UserTabs from '../components/UserTabs.vue';
import CommonSettings from '../components/CommonSettings.vue';

export default defineComponent({
  name: 'AdminUserSettings',
  components: {
    UserTabs,
    CommonSettings,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Список пользователей
     */
    const user: ComputedRef<any> = computed(() => store.state.admin.user);

    onMounted(() => {
      store.dispatch('admin/getUser', route.params.userId).then(() => {
        store.commit('setH1', 'Пользователь ' + user.value.login);
      });
    });

    watch(
      () => route.name,
      () => store.dispatch('admin/getSettings')
    );
    return {
      user,
    };
  },
});
</script>

<style lang="scss"></style>

import { Store } from 'vuex';
import store from './store';
import MenuLink from '@/models/MenuLink';
import LogDashboard from './views/LogDashboard.vue';
import GtinLogs from './views/GtinLogs.vue';
import GtinLogUsers from './views/GtinLogUsers.vue';
import ApiLogs from './views/ApiLogs.vue';

export default class StatisticsModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.addLinks();
    this.store.registerModule('statistics', store);
  }

  addRoutes(): void {
    this.router.addRoute({
      path: '/statistics',
      component: LogDashboard,
      name: 'statistics-dashboard',
      meta: { checkAccess: ['read-gtin-logs'], activeMenuRoute: ['statistics-dashboard'] },
    });
    this.router.addRoute({
      path: '/statistics/gtin-logs',
      component: GtinLogs,
      name: 'statistics-gtin',
      meta: { checkAccess: ['read-gtin-logs'], activeMenuRoute: ['statistics-dashboard', 'statistics-gtin'] },
    });
    this.router.addRoute({
      path: '/statistics/gtin-logs-users',
      component: GtinLogUsers,
      name: 'statistics-gtin-users',
      meta: { checkAccess: ['read-gtin-logs'], activeMenuRoute: ['statistics-dashboard', 'statistics-gtin-users'] },
    });
    this.router.addRoute({
      path: '/statistics/api-logs',
      component: ApiLogs,
      name: 'statistics-api',
      meta: { checkAccess: ['read-system-logs'], activeMenuRoute: ['statistics-dashboard', 'statistics-api'] },
    });
  }

  addLinks(): void {
    this.store.dispatch(
      'addLink',
      new MenuLink('Статистика', { default: 'statistics', light: 'statistics-l-h', dark: 'statistics-d-h' }, 15, {
        name: 'statistics-dashboard',
      }),
    );
  }
}

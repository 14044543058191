<template>
  <div id="admin-settings">
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <CompanyTabs />

        <CommonSettings />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import CompanyTabs from '../components/CompanyTabs.vue';
import CommonSettings from '../components/CommonSettings.vue';

export default defineComponent({
  name: 'AdminCompanySettings',
  components: {
    CompanyTabs,
    CommonSettings,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Объект предприятия
     */
    const company: ComputedRef<Record<string, string | number>> = computed(
      () => store.state.admin.company
    );

    onMounted(() => {
      store.commit('setH1', 'Предприятие');
      store.dispatch('admin/getCompany', route.params.companyId).then(() => {
        if (company.value.name)
          store.commit('setH1', 'Предприятие ' + company.value.name);
      });
    });

    return {
      company,
    };
  },
});
</script>

<style lang="scss"></style>

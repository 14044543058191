<template>
  <div id="admin-company-form">
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <CompanyTabs />

        <Card :loading="loading['companies']" no-padding>
          <template #default>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Логин</th>
                  <th>PRO</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                <tr v-for="user in users.data" :key="user.id">
                  <td>
                    {{ user.login }}
                    <i
                      v-if="user.hasApiRequest"
                      class="fas fa-exclamation-circle text-primary"
                      title="Имеется запрос на доступ к API"
                    />
                  </td>

                  <td>
                    <i v-if="user.pro == 1" class="fas fa-check text-success" />

                    <i v-if="user.pro == 0" class="fas fa-times text-danger" />
                  </td>

                  <td class="text-right">
                    <router-link
                      class="ml-2"
                      :to="{
                        name: 'admin-user-edit',
                        params: { userId: user.id },
                      }"
                      title="Редактировать"
                    >
                      <i class="fas fa-edit" />
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import CompanyTabs from '../components/CompanyTabs.vue';
import Toast from '@/models/Toast';

export default defineComponent({
  name: 'AdminCompanyUsers',
  components: {
    Card,
    CompanyTabs,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<boolean> = computed(() => store.state.loading);

    /**
     * Объект предприятия
     */
    const company: ComputedRef<Record<string, string | number>> = computed(
      () => store.state.admin.company
    );

    /**
     * Список пользователей
     */
    const users: ComputedRef<any> = computed(() => store.state.admin.users);

    const save = () => {
      store.dispatch('admin/saveCompany', company).then((success: boolean) => {
        if (success) {
          store.dispatch(
            'showToast',
            new Toast(
              'Предприятие',
              'Предприятие успешно сохранено.',
              'success'
            )
          );
        }
      });
    };

    onMounted(() => {
      store.commit('setH1', 'Пользователи предприятия');
      store.dispatch('admin/getCompany', route.params.companyId).then(() => {
        if (company.value.name)
          store.commit(
            'setH1',
            'Пользователи предприятия ' + company.value.name
          );

        store.dispatch('admin/getUsers', {
          companyId: company.value.id,
        });
      });
    });

    return {
      company,
      loading,
      users,
      save,
    };
  },
});
</script>

<style lang="scss"></style>

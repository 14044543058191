<template>
  <div id="admin-user-form">
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <UserTabs />

        <Card :loading="loading['users']" no-padding>
          <template #default>
            <table class="table">
              <thead>
                <tr>
                  <th>API</th>
                  <th>Статус</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr v-for="api in apiList" :key="api.code" class="api-vbg api">
                  <td>
                    {{ api.name }}
                  </td>

                  <td>
                    <i v-if="user.gs1ApiAccess(api.code) == 1" class="text-primary" title="Без доступа"> - </i>

                    <i v-if="user.gs1ApiAccess(api.code) == 2" class="fas fa-exclamation text-warning" title="Требуется действие" />

                    <i v-if="user.gs1ApiAccess(api.code) == 3" class="fas fa-check text-success" title="Доступ разрешён" />

                    <i v-if="user.gs1ApiAccess(api.code) == 4" class="fas fa-ban text-danger" title="Доступ отклонён" />

                    <i v-if="user.gs1ApiAccess(api.code) == 5" class="fas fa-ban text-danger" title="Доступ закрыт" />

                    <template v-if="user.gs1ApiAccess(api.code) == 4 || user.gs1ApiAccess(api.code) == 5">
                      Причина отказа:
                      {{ user.declineReasons[api.code].comment }}
                    </template>
                  </td>

                  <td class="text-right">
                    <template v-if="user.gs1ApiAccess(api.code) != 1">
                      <router-link
                        v-if="user.gs1ApiAccess(api.code) == 2 || user.gs1ApiAccess(api.code) == 4"
                        :to="{
                          name: 'gs1-api-approve',
                          params: { id: user.id, api: api.code },
                          query: { hash: user.tokens[api.code].link_hash },
                        }"
                        class="btn btn-success btn-xs"
                      >
                        Открыть доступ
                      </router-link>

                      <router-link
                        v-if="user.gs1ApiAccess(api.code) == 2"
                        :to="{
                          name: 'gs1-api-decline',
                          params: { id: user.id, api: api.code },
                          query: { hash: user.tokens[api.code].link_hash },
                        }"
                        class="btn btn-danger btn-xs ml-2"
                      >
                        Отказать
                      </router-link>

                      <router-link
                        v-if="user.gs1ApiAccess(api.code) == 3"
                        :to="{
                          name: 'gs1-api-decline',
                          params: { id: user.id, api: api.code },
                          query: { hash: user.tokens[api.code].link_hash },
                        }"
                        class="btn btn-danger btn-xs ml-2"
                      >
                        Закрыть доступ
                      </router-link>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Card from '@/components/Card.vue';
import UserTabs from '../components/UserTabs.vue';

export default defineComponent({
  name: 'AdminUserForm',
  components: {
    Card,
    UserTabs,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Список пользователей
     */
    const user: ComputedRef<any> = computed(() => store.state.admin.user);

    /**
     * Список API
     */
    const apiList: ComputedRef<Array<Record<string, any>>> = computed(() => store.state.vbgApi.apiList);

    onMounted(() => {
      store.dispatch('admin/getUser', route.params.userId).then(() => {
        store.commit('setH1', 'Пользователь ' + user.value.login);
        store.dispatch('vbgApi/getApiList');
      });
    });

    return {
      loading,
      user,
      apiList,
    };
  },
});
</script>

<style lang="scss"></style>

<template>
  <nav class="main-header navbar navbar-expand navbar-light">
    <div class="left">
      <div v-if="menu.length > 0" id="main-menu" v-click-outside="hideMainMenu">
        <a v-if="user.loggedIn" href="#" class="main-menu-toggle navbar-button" @click.prevent="toggleMainMenu">
          <i class="icon-menu" />
        </a>

        <div v-if="showMainMenu" class="menu">
          <ul>
            <li v-for="(link, index) in menu" :key="index" :class="link.class">
              <router-link :to="link.route" :class="{ active: checkActiveChildren(link.route) }" active-class="active">
                <i :class="'icon-' + link.icon" />
                {{ link.label }}
              </router-link>
              <ul v-if="typeof link.nested !== 'undefined' && link.nested.length > 0">
                <li v-for="(nestedLink, nestedIndex) in link.nested" :key="nestedIndex" :class="nestedLink.class">
                  <router-link :to="nestedLink.route" :class="{ active: checkActiveChildren(nestedLink.route) }" active-class="active">
                    <component :is="nestedLink.prefix" v-if="nestedLink.prefix" />
                    {{ nestedLink.label }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/images/logo.svg" />

        <div class="right">
          <div class="top">Дополнительные сервисы</div>
          <div class="bottom">Предоставляет ГС1 РУС</div>
        </div>
      </router-link>

      <h1 v-if="h1" class="h5">
        {{ h1 }}
      </h1>
    </div>

    <div class="right">
      <NavbarSearch />

      <Notifications />

      <NavbarUserMenu @login="login" />
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { Ref } from 'vue';
import { useStore } from 'vuex';
import Notifications from '@/modules/notifications/components/Notifications.vue';
import NavbarSearch from '@/modules/search/components/NavbarSearch.vue';
import NavbarUserMenu from '@/modules/user/components/NavbarUserMenu.vue';
import MenuLink from '@/models/MenuLink.js';

export default defineComponent({
  name: 'Navbar',
  components: {
    Notifications,
    NavbarSearch,
    NavbarUserMenu,
  },
  emits: ['login-click'],
  setup(props, { emit }) {
    const store = useStore();
    const showCalendar = ref<boolean>(false);
    const route = useRoute();
    const router = useRouter();
    const showMainMenu: Ref<boolean> = ref(false);
    const h1 = computed(() => store.state.h1);
    const user = computed(() => store.state.auth.user);
    const linkConditions = computed(() => {
      return {
        default: true,
        bulk: store.state.gtinSearch.activeBulkResults > 0,
      } as Record<string, boolean>;
    });
    const menu = computed(() => {
      let menu = store.state.menu;

      menu.map((link: MenuLink): MenuLink => {
        if (typeof link.nested !== 'undefined' && link.nested.length > 0) {
          link.nested = link.nested.sort((firstChild: MenuLink, secondChild: MenuLink): number => {
            return firstChild.sort - secondChild.sort;
          });

          link.nested = link.nested.map((link: MenuLink): MenuLink => {
            link.class = { hide: !linkConditions.value[link.condition] };
            link.class['link-' + link.route.name] = true;

            return link;
          });
        }

        return link;
      });

      menu = menu.map((link: MenuLink): MenuLink => {
        link.class = { hide: !linkConditions.value[link.condition] };
        link.class['link-' + link.route.name] = true;

        return link;
      });

      return menu.sort((firstLink: MenuLink, secondLink: MenuLink): number => {
        return firstLink.sort - secondLink.sort;
      });
    });

    /**
     * Клик по кнопке "Войти"
     */
    const login = (): void => {
      emit('login-click');
    };

    /**
     * Переключение состояния главного меню
     */
    const toggleMainMenu = (): void => {
      showMainMenu.value = !showMainMenu.value;
    };

    /**
     * Скрытие главного меню
     */
    const hideMainMenu = (): void => {
      showMainMenu.value = false;
    };

    /**
     * Проверка активности дочерних роутов
     */
    const checkActiveChildren = (routeToCheck: Record<string, any>) => {
      return route.path.includes(router.resolve(routeToCheck).path);
    };
    watch(
      () => route.name,
      () => (showCalendar.value = false)
    );

    return {
      showMainMenu,
      user,
      h1,
      linkConditions,
      menu,
      toggleMainMenu,
      hideMainMenu,
      login,
      checkActiveChildren,
    };
  },
});
</script>

<style lang="scss">
.main-header {
  display: flex;
  justify-content: space-between !important;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.25);
  padding: 0.75rem 16px !important;
  .left {
    display: flex;
    align-items: center;
    position: relative;
    #main-menu {
      position: relative;
      .main-menu-toggle {
        margin-right: 100px;
      }
      .menu {
        position: absolute;
        left: 0;
        top: calc(72px - 0.75rem);
        min-width: 360px;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        padding: 18px;
        ul,
        li {
          display: block;
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            &.hide {
              display: none;
            }
            ul {
              margin-top: 16px;
              margin-left: 53px;
              li {
                padding-left: 10px;
                + li {
                  margin-top: 10px;
                }
              }
            }
            a {
              font-size: 14px;
              font-weight: 400;
              display: flex;
              align-items: center;
              color: #454545;
              transition: color 0.3s;
              i {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 25px;
                background: #d8d8d8;
                border-radius: 3px;
                font-size: 16px;
                color: #002c6c;
                transition: color 0.3s;
              }
              .badge {
                padding: 0 6px;
                height: 18px;
                background: #d8d8d8;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                color: #888b8d;
                &.active {
                  color: #fff;
                  background: #002d6c;
                }
                &.error {
                  color: #fff;
                  background: #f26334;
                }
              }
              &.active {
                font-weight: 700;
                color: #f26334;
                i {
                  color: #f26334;
                }
              }
              &:hover {
                font-weight: 700;
                color: #002c6c;
                i {
                  color: #002c6c;
                }
              }
            }
            + li {
              margin-top: 16px;
            }
          }
        }
        .link-gtin-search-search i {
          font-size: 28px;
        }
      }
    }
  }
  > .right {
    display: flex;
    align-items: center;
  }
  h1 {
    margin: 0.5rem 0 0.5rem 3rem;
    display: flex;
    align-items: center;

    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .logo {
    height: 2.875rem;
    display: flex;
    text-decoration: none;
    align-items: center;
    transition: all 0.3s;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
    img {
      max-height: 100%;
    }
    .right {
      margin-left: 6px;
      .top {
        color: #002c6c;
        font-size: 0.8125rem;
      }
      .bottom {
        font-size: 0.625rem;
        color: #454545;
      }
    }
  }

  .user {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s;
    &:hover {
      color: #f26334;
    }
  }

  .navbar-button {
    width: 38px;
    height: 38px;
    border-radius: 4px;
    background: #d8d8d8;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    color: #454545;
    transition: color 0.3s;
    position: relative;
    &:hover {
      color: #002c6c;
    }
  }
}
</style>

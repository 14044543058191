<template>
  <div :class="{ 'api-users__filter': currentRoute === 'statistics-api' }">
    <div :class="classes">
      <div class="left">
        <slot />

        <div v-if="showDateFilter" class="dates">
          <a href="#" class="btn d-flex justify-content-center btn-default" @click="setDate('today')"> Сегодня </a>

          <a href="#" class="btn d-flex justify-content-center btn-default" @click="setDate('yesterday')"> Вчера </a>

          <a href="#" class="btn d-flex justify-content-center btn-default" @click="setDate('week')"> Неделя </a>

          <a href="#" class="btn d-flex justify-content-center btn-default" @click="setDate('month')"> Месяц </a>

          <div class="date date-from">
            <datepicker v-model="dateFromValue" placeholder="Дата (от)" :locale="dateLocale" input-format="dd.MM.yyyy" :upper-limit="new Date()" />

            <a v-if="dateFromValue" href="#" class="reset-btn" @click.prevent="dateFromValue = new Date()">
              <i class="fa fa-times" />
            </a>
          </div>

          <div class="date date-to">
            <datepicker
              v-model="dateToValue"
              placeholder="Дата (до)"
              :locale="dateLocale"
              input-format="dd.MM.yyyy"
              :lower-limit="dateFromValue"
              :upper-limit="new Date()"
            />

            <a v-if="dateToValue" href="#" class="reset-btn" @click.prevent="dateToValue = new Date()">
              <i class="fa fa-times" />
            </a>
          </div>
        </div>

        <slot name="left" />
        <div class="center">
          <div v-if="!hideSearch" class="search">
            <i class="fas fa-search" />

            <input v-model="searchStringValue" placeholder="Поиск" type="text" @input="search" />
          </div>
        </div>
      </div>

      <div class="right">
        <div v-if="!hidePagination" class="pagination">
          Показывать:

          <SelectInput
            v-model="perPageValue"
            :options="[
              { value: '10', label: '10' },
              { value: '20', label: '20' },
              { value: '30', label: '30' },
              { value: '40', label: '40' },
              { value: '50', label: '50' },
            ]"
          />

          <Pagination v-if="!hidePagination" ref="pagination" :total-pages="lastPage" :total-results="total" @change="setPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Datepicker from 'vue3-datepicker';
import { ru } from 'date-fns/locale';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
import SelectInput from '@/components/SelectInput.vue';
import Pagination from '@/components/Pagination.vue';

import { defineComponent, ref, computed } from 'vue';
import type { Ref, ComputedRef } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'Filter',
  components: {
    Datepicker,
    SelectInput,
    Pagination,
  },
  props: {
    showCheckAll: {
      type: Boolean,
      default: false,
    },
    searchString: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    dateFrom: {
      type: Date,
      default: () => Date,
    },
    dateTo: {
      type: Date,
      default: () => Date,
    },
    showDateFilter: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'search',
    'change-per-page',
    'change-page',
    'check-all',
    'change-date',
    'update:search-string',
    'update:per-page',
    'update:page',
    'update:date-from',
    'update:date-to',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    /**
     * Локализация календаря
     */
    const dateLocale: Ref<any> = ref(ru);
    /**
     * Состояние чекбокса "Выбрать все"
     */
    const checkAllChecked: Ref<boolean> = ref(false);
    /**
     * Флаг для вызова $emit только один раз при выборе промежутка дат
     */
    const intervalChanged: Ref<boolean> = ref(false);
    const currentRoute: ComputedRef<any> = computed(() => router.currentRoute.value.name);
    /**
     * Строка поиска
     */
    const searchStringValue: Ref<string> = ref('');
    const pagination: Ref<InstanceType<typeof Pagination> | null> = ref(null);
    const perPageValue = computed({
      get: () => props.perPage,
      set: (value: any) => {
        emit('update:per-page', value);
        emit('change-per-page');
        if (pagination.value) pagination.value.setPage(1);
      },
    });
    const pageValue = computed({
      get: () => props.perPage,
      set: (value: any) => emit('update:page', value),
    });
    const rangeStart = computed(() => {
      return (pageValue.value - 1) * perPageValue.value + 1;
    });
    const rangeEnd = computed(() => {
      let rangeEnd = pageValue.value * perPageValue.value;
      if (rangeEnd > props.total) rangeEnd = props.total;
      return rangeEnd;
    });
    const lastPage = computed(() => Math.ceil(props.total / perPageValue.value));
    const pageNumbers = computed(() => {
      const _pageNumbers = [];
      for (let i = -5; i < 5; i++) {
        const pageNumber = props.page + i;
        if (pageNumber > 1 && pageNumber < lastPage.value) _pageNumbers.push(pageNumber);
      }

      return _pageNumbers;
    });

    const dateFromValue = computed({
      get: () => props.dateFrom,
      set: (value: any) => {
        emit('update:date-from', value);
        if (!intervalChanged.value) {
          emit('change-date');
        }
      },
    });
    const dateToValue = computed({
      get: () => props.dateTo,
      set: (value: any) => {
        emit('update:date-to', value);
        emit('change-date');
      },
    });

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        filter: true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    /**
     * Поиск
     */
    const search = (): void => {
      emit('update:search-string', searchStringValue.value);
      emit('search');

      pageValue.value = 1;
      checkAllChecked.value = false;
    };
    /**
     * Изменение страницы
     */
    const setPage = (newPage: number): void => {
      if (newPage >= 1 && newPage <= lastPage.value) {
        pageValue.value = newPage;

        emit('change-page');
      }
    };
    /**
     * Выбор всех элементов
     */
    const checkAll = (e: Record<string, any>): void => {
      emit('check-all', e.target.checked);
    };
    /**
     * Устанавлиает промежуток для фильтра по дате
     */
    const setDate = (range: string): void => {
      intervalChanged.value = true;
      switch (range) {
        case 'month':
          dateFromValue.value = dayjs().subtract(1, 'month').toDate();
          intervalChanged.value = false;
          dateToValue.value = new Date();
          break;
        case 'week':
          dateFromValue.value = dayjs().subtract(1, 'week').toDate();
          intervalChanged.value = false;
          dateToValue.value = new Date();
          break;
        case 'yesterday':
          dateFromValue.value = dayjs().subtract(1, 'day').toDate();
          intervalChanged.value = false;
          dateToValue.value = dayjs().subtract(1, 'day').toDate();
          break;
        case 'today':
        default:
          dateFromValue.value = new Date();
          intervalChanged.value = false;
          dateToValue.value = new Date();
          break;
      }
    };

    return {
      dateLocale,
      checkAllChecked,
      intervalChanged,
      searchStringValue,
      pagination,
      rangeEnd,
      rangeStart,
      lastPage,
      pageNumbers,
      perPageValue,
      dateFromValue,
      dateToValue,
      currentRoute,
      classes,
      search,
      setPage,
      checkAll,
      setDate,
    };
  },
});
</script>

<style lang="scss">
.filter {
  background: transparent;
  border: 1px solid #d8d8d8;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  top: 4.45rem;
  z-index: 10;
  font-size: 16px;

  .left,
  .center,
  .right {
    display: flex;
    align-items: stretch;
    > div {
      display: flex;
      align-items: center;
    }
  }

  .left {
    margin-right: 20px;
    display: flex;
    align-items: center;
    select {
      font-size: 0.8rem;
      border: 1px solid #b1b3b3;
      &:first-child {
        margin-left: 30px;
      }
    }
  }

  .check-all {
    border-right: 1px solid #b3b5b5;
    padding: 0 20px 0 10px;
    font-size: 0.8rem;
  }

  input[type='text'],
  select,
  button {
    height: 30px;
    border: 1px solid #b1b3b3;
    outline: none;
    padding: 0 6px;
  }

  .select-input {
    margin: 0 16px;
    .multiselect-single-label,
    .multiselect-multiple-label,
    .multiselect-placeholder {
      height: 26px;
      font-size: 15px;
      color: #454545;
    }
  }

  .search {
    margin: 0 10px;
    position: relative;
    input {
      border: 1px solid #b1b3b3;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      height: 38px;
      padding-left: 34px;
    }
    i {
      position: absolute;
      top: 0;
      bottom: 0;
      font-size: 16px;
      height: 16px;
      left: 12px;
      margin: auto;
      color: #b1b3b3;
    }
  }

  .pagination {
    select {
      margin: 0 20px;
    }
    .pages {
      display: flex;
      align-items: center;
      margin: 0 24px 0 10px;
    }
    a {
      color: #0680ad;
      &.active {
        font-weight: 500;
      }
      &.disabled {
        color: #b1b3b3;
        cursor: default;
        text-decoration: none;
      }
      + a {
        margin-left: 8px;
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    background: #002c6c;
    color: #fff;
    padding: 0 20px;
    transition: all 0.3s;
    + .action {
      margin-left: 0;
    }
    &:hover {
      text-decoration: none;
      background: #003684;
    }
    i {
      margin-right: 10px;
    }
  }

  .dates {
    margin-left: 30px;
    .btn {
      background: transparent;
      border: 1px solid #b1b3b3;
      border-right: 0;
      height: 30px;
      line-height: normal;
      padding: 0 0.375rem;
      display: inline-flex;
      align-items: center;
    }
    .date {
      position: relative;
      input {
        width: 120px;
        padding-right: 20px;
        text-align: center;
        font-size: 0.8rem;
        border: 1px solid #b1b3b3;
        cursor: pointer;
      }
      a {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
        display: flex;
        align-items: center;
        i {
          height: 10px;
        }
      }

      &.date-to {
        input {
          border-left: 0;
        }
      }
    }
  }

  &.mode-dark {
    .dates {
      .btn {
        color: var(--color-main-f);
      }
      .date {
        input {
          background-color: transparent;
          color: var(--color-main-f);
        }
      }
    }
  }
}
.api-users__filter {
  .filter {
    .dates {
      @media (max-width: 1600px) {
        margin-left: 0;
      }
    }
    @media (max-width: 1600px) {
      flex-direction: column;
      height: 120px;
      justify-content: space-between;
      padding: var(--padding-x3);
    }
  }
}
</style>
